<template>
    <div class="flex flex-col gap-1">
        <div class="flex flex-row gap-1">
            <label :id="labelId" class="block text-sm font-medium text-gray-700">{{ label }}</label>
            <HelpButton v-if="helpButtonText" :text="helpButtonText" />
        </div>

        <div
            role="radiogroup"
            aria-orientation="horizontal"
            :aria-labelledby="labelId"
            class="flex flex-row gap-1 bg-gray-200 text-gray-700 p-0.5 rounded-md has-focus-visible:ring-2 has-focus-visible:ring-gray-700 has-focus-visible:ring-offset-2"
        >
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid';
import {
    computed,
    Fragment,
    provide,
    ref,
    toRef,
    useSlots,
    VNodeArrayChildren,
    VNodeNormalizedChildren,
    watchEffect,
} from 'vue';
import HelpButton from '../HelpButton/HelpButton.vue';
import { optionSwitchKey } from './injectionKeys';

const id = nanoid();
const labelId = `label-${id}`;

type OptionSwitchProps = {
    activeIndex: number;
    name: string;
    label: string;
    helpButtonText?: string;
};

type OptionSwitchEmits = {
    (e: 'change-option', index: number): void;
};

const props = defineProps<OptionSwitchProps>();
const emit = defineEmits<OptionSwitchEmits>();

const activeIndexRef = ref();
watchEffect(() => {
    activeIndexRef.value = props.activeIndex;
});
const nameRef = toRef(props, 'name');

const slots = useSlots();
function isVNodeArray(children: VNodeNormalizedChildren): children is VNodeArrayChildren {
    return Array.isArray(children);
}
const childCount = computed(() => {
    const slotContent = slots.default ? slots.default() : [];

    return slotContent.reduce((count, vnode) => {
        if (vnode.type === Fragment && vnode.children) {
            if (isVNodeArray(vnode.children)) {
                return count + vnode.children.length;
            }
        }
        return count + 1;
    }, 0);
});

provide(optionSwitchKey, {
    activeIndex: activeIndexRef,
    name: nameRef,
    onChange: (index) => {
        emit('change-option', index);
    },
    onMoveFocusLeft() {
        const newIndex = Math.max(activeIndexRef.value - 1, 0);

        if (newIndex !== activeIndexRef.value) {
            emit('change-option', newIndex);
        }

        activeIndexRef.value = newIndex;
    },
    onMoveFocusRight() {
        const newIndex = Math.min(activeIndexRef.value + 1, childCount.value - 1);

        if (newIndex !== activeIndexRef.value) {
            emit('change-option', newIndex);
        }

        activeIndexRef.value = newIndex;
    },
});
</script>
