<template>
    <div>
        <BaseForm>
            <template #fields>
                <TextField
                    :value="event.nameShort"
                    :label="$t('name-short')"
                    :error-messages="errors.nameShort"
                    @input="handleFormField(($event.target as HTMLInputElement).value, 'nameShort')"
                />
                <TextField
                    :value="event.nameLong"
                    :label="$t('name-long')"
                    :error-messages="errors.nameLong"
                    @input="handleFormField(($event.target as HTMLInputElement).value, 'nameLong')"
                />
                <NumberField
                    :value="event.executionYear ?? ''"
                    :label="$t('execution-year')"
                    :error-messages="errors.executionYear"
                    @input="handleFormField(($event.target as HTMLInputElement).value, 'executionYear')"
                />
                <ComboboxField
                    :value="selectedStatus"
                    :label="$t('status')"
                    :error-messages="errors.status"
                    :items="getEventStatusComboboxItems()"
                    @change="handleFormField($event ? $event.id : '', 'status')"
                />
            </template>
        </BaseForm>
        <div class="p-3 flex justify-end gap-3">
            <Button size="Base" variant="Info-Light" @click="$router.back">
                <template #icon>
                    <UndoIcon />
                </template>
                {{ $t('card-abort') }}
            </Button>
            <Button
                size="Base"
                variant="Success-Light"
                :disabled-explanation="saveButtonDisabledExplanation"
                @click="onSave"
            >
                <template #icon>
                    <AllDoneIcon />
                </template>
                {{ $t('card-save') }}
            </Button>
        </div>
    </div>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import Button from '@/components/Button/Button.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import ComboboxField from '@/components/Form/ComboboxField.vue';
import NumberField from '@/components/Form/NumberField.vue';
import TextField from '@/components/Form/TextField.vue';
import AllDoneIcon from '@/components/Icon/AllDoneIcon.vue';
import UndoIcon from '@/components/Icon/UndoIcon.vue';
import { CreateEventDocument, GetEventByNameDocument } from '@/generated/graphql';
import { $t } from '@/plugins/fluent';
import { useMyUser } from '@/user/composables/useMyUser';
import { useApolloClient, useMutation } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed, reactive } from 'vue';
import { getEventStatusComboboxItems, goToEvent } from '../event';
import { EventCreateErrors, EventCreateSchemaType, EventCreateType, EventStatus } from '../types';
import { EventCreateSchema } from './schemas';

const props = defineProps<{ seriesId: string }>();
const fluent = useFluent();
const client = useApolloClient().client;

const isAdmin = auth.isAdmin();
const { currentUser } = useMyUser();
const createEvent = useMutation(CreateEventDocument);

const event = reactive<EventCreateType>({
    nameShort: '',
    nameLong: '',
    executionYear: undefined,
    status: 'active-option',
});

const errors = reactive<EventCreateErrors>({
    nameShort: [],
    nameLong: [],
    executionYear: [],
    status: [],
});

function toStatusItem(status: EventStatus) {
    if (!status) return null;
    return { id: status, name: $t(`event-status-${status}`) };
}

const selectedStatus = computed(() => toStatusItem(event.status as EventStatus));

async function checkName(name: string) {
    // check if another event with this unique name already exists
    const otherEvent = await client.query({ query: GetEventByNameDocument, variables: { name } });
    if (otherEvent.data.eventByNameShort?.id) {
        errors.nameShort.push($t('nameshort-already-taken'));
        return;
    } else {
        errors.nameShort = errors.nameShort.filter((i) => i !== $t('nameshort-already-taken'));
    }
}

async function handleFormField(value: string | number, fieldName: string) {
    const parse = EventCreateSchema.shape[fieldName as keyof EventCreateSchemaType].safeParse(value);
    if (!parse.success) {
        errors[fieldName as keyof EventCreateErrors] = parse.error.format()._errors;
        event[fieldName] = value;
    } else {
        errors[fieldName as keyof EventCreateErrors] = [];
        event[fieldName] = parse.data;
    }
    if (fieldName === 'nameShort' && typeof value === 'string') {
        await checkName(value);
    }
}

async function onSave() {
    const parse = EventCreateSchema.safeParse(event);

    if (!parse.success) {
        const flattenedErrors = parse.error.flatten().fieldErrors;
        Object.assign(errors, flattenedErrors);
        return;
    }
    const data = parse.data as EventCreateType;

    await checkName(data.nameShort);

    // trim all strings
    for (const key of Object.keys(data)) {
        const value = data[key];
        if (value && typeof value === 'string') {
            data[key] = value.trim();
        }
    }
    const res = await createEvent.mutate({
        seriesId: props.seriesId,
        ...data,
        createdById: currentUser.value.id,
        createdByName: currentUser.value.name,
    });
    const { id, nameShort } = res?.data?.createEvent?.event ?? {};
    if (id && nameShort) {
        goToEvent(id);
    }
}

const saveButtonDisabledExplanation = computed(() => {
    return isAdmin ? undefined : fluent.$t('no-permission-to-edit');
});
</script>
