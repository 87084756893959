<template>
    <form
        ref="wrapperRef"
        class="inline-flex flex-col rounded-md shadow-[0_0_2px_rgba(0,0,0,0.25)] mb-px"
        :class="{ 'mt-6': !showWarnMessage }"
        @submit="onSubmit"
    >
        <div
            v-if="showMessage"
            class="flex flex-row items-center border-b rounded-t-md h-6"
            :class="messageColorClasses"
        >
            <Tooltip v-if="showWarnMessage" class="self-stretch">
                <template #trigger="tooltipProps">
                    <button
                        v-if="showSkipToPreviousAllocationButton"
                        v-bind="tooltipProps"
                        class="rounded-tl-[0.3125rem] px-1.5 focus-visible:outline focus-visible:outline-offset-1 focus-visible:outline-2 focus-visible:z-10"
                        :class="[
                            isSkipToPreviousAllocationButtonDisabled
                                ? 'bg-gray-200 text-gray-500 focus-visible:outline-gray-600 cursor-not-allowed'
                                : 'bg-yellow-200 text-yellow-700 hover:bg-yellow-600 hover:text-yellow-100 active:bg-yellow-800 active:text-yellow-50 focus-visible:outline-yellow-700 cursor-pointer',
                        ]"
                        type="button"
                        :aria-disabled="isSkipToPreviousAllocationButtonDisabled"
                        @click="emit('jump-to-previous-allocations')"
                    >
                        <SkipIcon class="rotate-180 size-5" />
                    </button>
                </template>

                <template #text>
                    <template v-if="isSkipToNextAllocationButtonDisabled">
                        {{ $t('timeline-interval-changer-skip-to-previous-allocation-disabled') }}
                    </template>
                    <template v-else>{{ $t('timeline-interval-changer-skip-to-previous-allocation') }}</template>
                </template>
            </Tooltip>

            <div :id="yearMessageId" class="w-full flex flex-row items-center gap-1 justify-center px-1">
                <WarningIcon class="size-4" />

                <template v-if="Boolean(yearError)">{{ yearError }}</template>

                <Tooltip v-if="showWarnMessage" :class="widthWarnMessage">
                    <template #trigger="tooltipProps">
                        <span
                            class="text-ellipsis overflow-hidden whitespace-nowrap focus-visible:outline focus-visible:outline-offset-1 focus-visible:outline-2 focus-visible:outline-yellow-700 focus-visible:z-10"
                            tabindex="0"
                            v-bind="tooltipProps"
                        >
                            {{ $t('timeline-interval-changer-no-allocations') }}
                        </span>
                    </template>

                    <template #text>{{ $t('timeline-interval-changer-no-allocations') }}</template>
                </Tooltip>
            </div>

            <Tooltip v-if="showWarnMessage" class="self-stretch">
                <template #trigger="tooltipProps">
                    <button
                        v-if="showSkipToNextAllocationButton"
                        v-bind="tooltipProps"
                        class="self-stretch rounded-tr-[0.3125rem] px-1.5 focus-visible:outline focus-visible:outline-offset-1 focus-visible:outline-2 focus-visible:z-10"
                        :class="[
                            isSkipToNextAllocationButtonDisabled
                                ? 'bg-gray-200 text-gray-500 focus-visible:outline-gray-600 cursor-not-allowed'
                                : 'bg-yellow-200 text-yellow-700 hover:bg-yellow-600 hover:text-yellow-100 active:bg-yellow-800 active:text-yellow-50 focus-visible:outline-yellow-700 cursor-pointer',
                        ]"
                        type="button"
                        :aria-disabled="isSkipToNextAllocationButtonDisabled"
                        @click="emit('jump-to-next-allocations')"
                    >
                        <SkipIcon class="size-5" />
                    </button>
                </template>

                <template #text>
                    <template v-if="isSkipToNextAllocationButtonDisabled">
                        {{ $t('timeline-interval-changer-skip-to-next-allocation-disabled') }}
                    </template>
                    <template v-else>{{ $t('timeline-interval-changer-skip-to-next-allocation') }}</template>
                </template>
            </Tooltip>
        </div>

        <div class="flex flex-row items-center h-12">
            <Tooltip class="self-stretch">
                <template #trigger="tooltipProps">
                    <button
                        class="p-1 rounded-bl-[0.3125rem] focus-visible:outline focus-visible:outline-offset-1 focus-visible:outline-2 focus-visible:z-10"
                        :class="[
                            isPreviousQuarterButtonDisabled
                                ? 'bg-gray-300 text-gray-400 cursor-not-allowed focus-visible:outline-gray-500'
                                : 'bg-gray-100 text-gray-600 hover:bg-gray-600 hover:text-gray-50 active:bg-gray-800 active:text-gray-50 focus-visible:outline-sky-700 cursor-pointer',
                            { 'rounded-tl-[0.3125rem]': !showMessage },
                        ]"
                        type="button"
                        v-bind="tooltipProps"
                        :aria-disabled="isPreviousQuarterButtonDisabled"
                        @click="isPreviousQuarterButtonDisabled ? undefined : $emit('click-previous-quarter')"
                    >
                        <ChevronRightIcon class="rotate-180 size-6" />
                    </button>
                </template>

                <template #text>
                    <template v-if="isPreviousQuarterButtonDisabled">
                        {{ $t('timeline-interval-changer-previous-quarter-disabled') }}
                    </template>

                    <template v-else>
                        {{ $t('timeline-interval-changer-previous-quarter') }}
                    </template>
                </template>
            </Tooltip>

            <div class="self-stretch flex flex-row items-center bg-gray-50 text-gray-700 font-semibold text-xl">
                <label :id="labelId" :for="selectButtonId" class="sr-only">
                    {{ $t('timeline-interval-changer-quarter-label') }}
                </label>

                <Tooltip class="self-stretch" :is-disabled="!props.isTimeSpanModified">
                    <template #trigger="tooltipProps">
                        <button
                            :id="selectButtonId"
                            ref="currentValueRef"
                            class="inline-flex items-center pr-0 pl-2 hover:bg-sky-700 hover:text-gray-50 active:bg-sky-900 active:text-sky-50 h-full focus-visible:outline focus-visible:outline-offset-1 focus-visible:outline-2 focus-visible:outline-sky-700 focus-visible:rounded-sm focus-visible:z-10 outline-hidden cursor-pointer group"
                            :aria-controls="listboxId"
                            :aria-expanded="isQuarterDropDownOpen"
                            :aria-labelledby="labelId"
                            :aria-describedby="tooltipProps['aria-labelledby']"
                            :popovertarget="tooltipProps.popovertarget"
                            aria-haspopup="listbox"
                            type="button"
                            @mouseover="isHovered = true"
                            @mouseout="isHovered = false"
                            @focus="isFocused = true"
                            @focusout="isFocused = false"
                            @keydown="keydownTrigger"
                            @click="isQuarterDropDownOpen = !isQuarterDropDownOpen"
                        >
                            <div
                                class="pr-2 mr-1 border-r leading-[1.625rem] tabular-nums w-[calc(2.5ch+0.5rem+0.25rem)] flex flex-row justify-center"
                            >
                                {{ $t('timeline-interval-changer-quarter', { quarter: props.currentQuarter }) }}
                                <div
                                    v-if="props.isTimeSpanModified"
                                    class="text-sky-700 group-hover:text-sky-50 group-active:text-sky-50"
                                    tabindex="0"
                                >
                                    *
                                </div>
                            </div>

                            <TriangleIcon
                                class="size-5 transition-transform"
                                :class="[isQuarterDropDownOpen ? '-rotate-90' : 'rotate-90']"
                            />
                        </button>
                    </template>

                    <template #text>{{ $t('timeline-interval-changer-time-span-changed') }}</template>
                </Tooltip>
                <div
                    :id="listboxId"
                    ref="valuesPanelRef"
                    class="bg-gray-50 space-y-0.5 shadow-sm rounded-sm z-30"
                    :style="floatingStyles"
                    :aria-labelledby="labelId"
                    :aria-activedescendant="idFocusedOption"
                    role="listbox"
                    tabindex="-1"
                >
                    <div
                        v-for="(quarter, index) in quarters"
                        v-if="isQuarterDropDownOpen"
                        :id="quarter.id"
                        :key="quarter.id"
                        class="flex flex-row items-center gap-1.5 pl-1.5 pr-2 py-1.5 focus-visible:outline focus-visible:outline-offset-1 focus-visible:outline-2 focus-visible:outline-sky-700 focus-visible:rounded-sm outline-hidden tabular-nums"
                        :class="[
                            {
                                'bg-sky-700 cursor-pointer': quarter.isFocused,
                                'rounded-t': index === 0,
                                'rounded-b': index === quarters.length - 1,
                            },
                            quarter.isFocused ? 'text-gray-50' : 'text-gray-700',
                        ]"
                        role="option"
                        :aria-selected="quarter.isSelected"
                        @mouseover="focusedIndex = index"
                        @mouseout="focusedIndex = -1"
                        @focus="focusedIndex = index"
                        @focusout="focusedIndex = -1"
                        @click="handleOptionClick(quarter.value)"
                    >
                        <CheckIcon v-if="quarter.isSelected" class="size-5 shrink-0" />
                        <div v-else class="size-5 shrink-0" />

                        {{ quarter.label }}
                    </div>
                </div>
            </div>

            <div class="self-stretch flex flex-row">
                <label class="sr-only" :for="yearInputId">{{ $t('timeline-interval-changer-year-label') }}</label>
                <input
                    :id="yearInputId"
                    v-model="selectedYear"
                    :aria-invalid="Boolean(yearError)"
                    :aria-errormessage="Boolean(yearError) ? yearMessageId : undefined"
                    :aria-describedby="Boolean(props.warningNoAllocations) ? yearMessageId : undefined"
                    class="border-none text-center py-1 p-0 focus:ring-0 focus-visible:outline focus-visible:outline-offset-1 focus-visible:outline-2 focus-visible:rounded-sm focus-visible:z-10 font-semibold bg-gray-50 text-xl w-[calc(0.5rem+4ch+0.5rem)]"
                    :class="
                        Boolean(yearError)
                            ? 'focus-visible:outline-red-700 text-red-700 bg-red-100'
                            : 'focus-visible:outline-sky-700 text-gray-700'
                    "
                    type="text"
                    size="4"
                    required
                    minlength="4"
                    :min="props.minimalYear"
                    :max="props.maximalYear"
                    inputmode="numeric"
                    name="year"
                    @input="onYearInput"
                />
            </div>

            <Tooltip class="self-stretch">
                <template #trigger="tooltipProps">
                    <button
                        class="p-1 rounded-br-[0.3125rem] focus-visible:outline focus-visible:outline-offset-1 focus-visible:outline-2 focus-visible:z-10"
                        :class="[
                            isNextQuarterButtonDisabled
                                ? 'bg-gray-300 text-gray-400 cursor-not-allowed focus-visible:outline-gray-500'
                                : 'bg-gray-100 text-gray-600 hover:bg-gray-600 hover:text-gray-50 active:bg-gray-800 active:text-gray-50 focus-visible:outline-sky-700 cursor-pointer',
                            ,
                            { 'rounded-tr-[0.3125rem]': !showMessage },
                        ]"
                        type="button"
                        v-bind="tooltipProps"
                        :aria-disabled="isNextQuarterButtonDisabled"
                        @click="isNextQuarterButtonDisabled ? undefined : $emit('click-next-quarter')"
                    >
                        <ChevronRightIcon class="size-6" />
                    </button>
                </template>

                <template #text>
                    <template v-if="isNextQuarterButtonDisabled">
                        {{ $t('timeline-interval-changer-next-quarter-disabled') }}
                    </template>

                    <template v-else>
                        {{ $t('timeline-interval-changer-next-quarter') }}
                    </template>
                </template>
            </Tooltip>
        </div>
    </form>
</template>

<script setup lang="ts">
import { $t } from '@/plugins/fluent';
import { autoUpdate, flip, offset, shift, size, useFloating } from '@floating-ui/vue';
import { onClickOutside } from '@vueuse/core';
import { QuarterNumbers } from 'luxon';
import { nanoid } from 'nanoid';
import { computed, ref, watch, watchEffect } from 'vue';
import CheckIcon from '../Icon/CheckIcon.vue';
import ChevronRightIcon from '../Icon/ChevronRightIcon.vue';
import SkipIcon from '../Icon/SkipIcon.vue';
import TriangleIcon from '../Icon/TriangleIcon.vue';
import WarningIcon from '../Icon/WarningIcon.vue';
import Tooltip from '../Tooltip/Tooltip.vue';

const id = nanoid();
const yearInputId = `year-input-${id}`;
const yearMessageId = `year-message-${id}`;
const labelId = `label-${id}`;
const selectButtonId = `select-button-${id}`;
const listboxId = `list-box-${id}`;

type TimelineIntervalChangerProps = {
    minimalYear: number;
    maximalYear: number;
    currentQuarter: QuarterNumbers;
    currentYear: number;
    warningNoAllocations: {
        buttonJumpToPreviousAllocations: 'Hidden' | 'Disabled' | 'Visible';
        buttonJumpToNextAllocations: 'Hidden' | 'Disabled' | 'Visible';
    } | null;
    isTimeSpanModified: boolean;
};

type TimelineIntervalChangerEmits = {
    (e: 'click-previous-quarter'): void;
    (e: 'click-next-quarter'): void;
    (e: 'change-quarter', quarter: QuarterNumbers): void;
    (e: 'change-year', year: number): void;
    (e: 'jump-to-previous-allocations'): void;
    (e: 'jump-to-next-allocations'): void;
};

const props = defineProps<TimelineIntervalChangerProps>();
const emit = defineEmits<TimelineIntervalChangerEmits>();

const showMessage = computed(() => Boolean(yearError.value) || Boolean(props.warningNoAllocations));
const showSkipToPreviousAllocationButton = computed(
    () =>
        (props.warningNoAllocations?.buttonJumpToPreviousAllocations === 'Visible' ||
            props.warningNoAllocations?.buttonJumpToPreviousAllocations === 'Disabled') &&
        !yearError.value, // If there is an error, it is more important than the warning
);
const isSkipToPreviousAllocationButtonDisabled = computed(
    () => props.warningNoAllocations?.buttonJumpToPreviousAllocations === 'Disabled',
);
const showSkipToNextAllocationButton = computed(
    () =>
        (props.warningNoAllocations?.buttonJumpToNextAllocations === 'Visible' ||
            props.warningNoAllocations?.buttonJumpToNextAllocations === 'Disabled') &&
        !yearError.value, // If there is an error, it is more important than the warning
);
const isSkipToNextAllocationButtonDisabled = computed(
    () => props.warningNoAllocations?.buttonJumpToNextAllocations === 'Disabled',
);
const showWarnMessage = computed(() => props.warningNoAllocations && !yearError.value); // If there is an error, it is more important than the warning

const selectedQuarter = ref();
const selectedYear = ref(props.currentYear);
const quarters = ref([
    {
        id: 'id-1',
        value: '1',
        label: $t('quarter-1'),
        isFocused: false,
        isSelected: false,
    },
    {
        id: 'id-2',
        value: '2',
        label: $t('quarter-2'),
        isFocused: false,
        isSelected: false,
    },
    {
        id: 'id-3',
        value: '3',
        label: $t('quarter-3'),
        isFocused: false,
        isSelected: false,
    },
    {
        id: 'id-4',
        value: '4',
        label: $t('quarter-4'),
        isFocused: false,
        isSelected: false,
    },
]);

watch(
    () => props.currentQuarter,
    () => {
        quarters.value = quarters.value.map((quarter) => {
            return {
                ...quarter,
                isSelected: Number(quarter.value) === props.currentQuarter,
            };
        });
    },
    { immediate: true },
);

const isQuarterDropDownOpen = ref(false);
const isHovered = ref(false);
const isFocused = ref(false);

watchEffect(() => {
    selectedQuarter.value = props.currentQuarter;
});

watchEffect(() => {
    selectedYear.value = props.currentYear;
});

function onSubmit(event: Event) {
    event.preventDefault();

    if (!yearError.value) {
        emit('change-year', Number((event.target as HTMLFormElement)[yearInputId].value));
    }
}

const yearError = ref<string | null>(null);

function isNumber(value: unknown): value is number {
    return !isNaN(Number(value));
}

function onYearInput(event: Event) {
    const target = event.target as HTMLInputElement;
    const value = target.value;

    if (value === '') {
        yearError.value = $t('timeline-interval-changer-error-year-empty');
        return;
    }

    if (!isNumber(value)) {
        yearError.value = $t('timeline-interval-changer-error-no-number');
        return;
    }

    if (!Number.isInteger(Number(value))) {
        yearError.value = $t('timeline-interval-changer-error-no-integer');
        return;
    }

    if (value < props.minimalYear) {
        yearError.value = $t('timeline-interval-changer-error-year-lower-than', {
            yearString: String(props.minimalYear),
        });
        return;
    }

    if (value > props.maximalYear) {
        yearError.value = $t('timeline-interval-changer-error-year-greater-than', {
            yearString: String(props.maximalYear),
        });
        return;
    }

    yearError.value = null;
    selectedYear.value = value;
}

const messageColorClasses = computed(() => {
    if (yearError.value) {
        return 'bg-red-100 text-red-700 border-b-red-300';
    }

    if (props.warningNoAllocations) {
        return 'bg-yellow-100 text-yellow-700 border-b-yellow-300';
    }

    return 'border-gray-100';
});

const currentValueRef = ref(null);
const valuesPanelRef = ref(null);
const wrapperRef = ref(null);
const { floatingStyles } = useFloating(currentValueRef, valuesPanelRef, {
    open: isQuarterDropDownOpen.value,
    placement: 'bottom-end',
    whileElementsMounted: autoUpdate,
    middleware: [
        offset(4),
        flip(),
        shift({ padding: 4 }),
        size({
            apply({ elements, rects }) {
                Object.assign(elements.floating.style, {
                    width: `${rects.reference.width}px`,
                });
            },
        }),
    ],
});

onClickOutside(wrapperRef, () => (isQuarterDropDownOpen.value = false));

function keydownTrigger(event: KeyboardEvent) {
    // When collapsed
    if (!isQuarterDropDownOpen.value) {
        switch (event.key) {
            case 'Enter':
            case ' ':
            case 'ArrowDown':
                isQuarterDropDownOpen.value = true;
                focusFirstOption();
                event.preventDefault();
                return;

            case 'ArrowUp':
                isQuarterDropDownOpen.value = true;
                focusLastOption();
                event.preventDefault();
                return;
        }
    }

    // When expanded
    if (isQuarterDropDownOpen.value) {
        switch (event.key) {
            case 'Enter':
            case ' ':
                emit('change-quarter', Number(quarters.value[focusedIndex.value].value) as QuarterNumbers);
                isQuarterDropDownOpen.value = false;
                event.preventDefault();
                return;

            case 'ArrowUp':
                focusPreviousOption();
                event.preventDefault();
                return;

            case 'ArrowDown':
                focusNextOption();
                event.preventDefault();
                return;

            case 'PageUp':
                focusFirstOption();
                event.preventDefault();
                return;

            case 'PageDown':
                focusLastOption();
                event.preventDefault();
                return;

            case 'Escape':
                focusOption(-1);
                isQuarterDropDownOpen.value = false;
                event.preventDefault();
                return;

            case 'Tab':
                isQuarterDropDownOpen.value = false;
                return;
        }

        return;
    }
}

const focusedIndex = ref(-1);
watchEffect(() => {
    focusOption(focusedIndex.value);
});
const idFocusedOption = computed(() => {
    const index = quarters.value.findIndex((quarter) => quarter.isFocused);

    if (index === -1) {
        return undefined;
    }

    return quarters.value[index].id;
});

function focusOption(focusIndex: number) {
    quarters.value = quarters.value.map((quarter, quarterIndex) => {
        return {
            ...quarter,
            isFocused: focusIndex === quarterIndex,
        };
    });
}

function focusFirstOption() {
    focusedIndex.value = 0;
}

function focusLastOption() {
    focusedIndex.value = quarters.value.length - 1;
}

function focusPreviousOption() {
    focusedIndex.value = Math.max(focusedIndex.value - 1, 0);
}

function focusNextOption() {
    focusedIndex.value = Math.min(focusedIndex.value + 1, quarters.value.length - 1);
}

function handleOptionClick(value: string) {
    emit('change-quarter', Number(value) as QuarterNumbers);
    isQuarterDropDownOpen.value = false;
}

const isPreviousQuarterButtonDisabled = computed(
    () => props.currentQuarter === 1 && props.currentYear === props.minimalYear,
);

const isNextQuarterButtonDisabled = computed(
    () => props.currentQuarter === 4 && props.currentYear === props.maximalYear,
);

const widthWarnMessage = computed(() => {
    // 0 buttons
    if (!showSkipToPreviousAllocationButton.value && !showSkipToNextAllocationButton.value) {
        return 'max-w-[calc(0.5rem+2.5ch+0.5rem+0.0625rem+0.25rem+1.25rem+0.25rem+4ch+0.25rem+2rem+2rem)]';
    }

    // One button
    if (
        (showSkipToPreviousAllocationButton.value && !showSkipToNextAllocationButton.value) ||
        (!showSkipToPreviousAllocationButton.value && showSkipToNextAllocationButton.value)
    ) {
        return 'max-w-[calc(0.5rem+2.5ch+0.5rem+0.0625rem+0.25rem+1.25rem+0.25rem+4ch+0.25rem+2rem)]';
    }

    // Both buttons
    return 'max-w-[calc(0.5rem+2.5ch+0.5rem+0.0625rem+0.25rem+1.25rem+0.25rem+4ch+0.25rem)]';
});
</script>
