<template>
    <Combobox
        v-slot="{ open }"
        :model-value="value"
        as="div"
        by="id"
        nullable
        @update:model-value="(value: ComboboxItem | null) => $emit('change', value)"
    >
        <Label
            :label="props.label"
            :input-id="inputId"
            :help-button-text="props.helpButtonText"
            :is-required="props.required"
        />
        <div class="relative mt-1">
            <ComboboxInput
                :id="inputId"
                ref="comboboxInputRef"
                :class="styling"
                :display-value="(item: any) => item?.name ?? ''"
                :autocomplete="autocomplete"
                :disabled="disabled"
                :required="required"
                :aria-invalid="errorMessages && errorMessages.length ? true : undefined"
                :aria-describedby="description ? `${inputId}-info` : undefined"
                :aria-errormessage="errorMessages && errorMessages.length ? `${inputId}-error` : undefined"
                @click="onInputClick"
                @change="query = $event.target.value"
            />
            <ComboboxButton
                v-show="!disabled"
                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-3 focus:ring-primary-600"
            >
                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>
            <div v-if="!props.hideClearButton" v-show="!disabled && value" class="absolute top-1 bottom-1 right-12">
                <Button size="Small" variant="Neutral-Strong" @click="$emit('change', null)">
                    <template #icon><DeleteForeverIcon /></template>
                    {{ $t('delete-entry') }}
                </Button>
            </div>
            <transition
                v-show="open"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ComboboxOptions
                    v-if="filteredItems.length > 0"
                    static
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-hidden sm:text-sm"
                >
                    <ComboboxOption
                        v-for="item in filteredItems"
                        :key="item.id"
                        :value="item"
                        :disabled="item.disabled"
                        class="py-2 pl-3 pr-2 ui-active:bg-indigo-600 ui-active:text-white ui-not-active:bg-white ui-not-active:text-gray-900 flex justify-between"
                        :class="{ 'opacity-50': item.disabled }"
                    >
                        {{ item.name }}
                        <CheckIcon class="h-5 w-5 hidden ui-selected:block" aria-hidden="true" />
                    </ComboboxOption>
                </ComboboxOptions>
            </transition>
        </div>
        <p v-if="description" :id="`${inputId}-info`" class="mt-2 text-sm text-gray-500">{{ description }}</p>
        <div
            v-if="errorMessages && errorMessages.length"
            :id="`${inputId}-error`"
            role="alert"
            class="mt-2 text-sm text-red-600"
        >
            <div v-for="message in errorMessages" :key="message">{{ message }}</div>
        </div>
    </Combobox>
</template>

<script setup lang="ts">
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/vue';
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid';
import { nanoid } from 'nanoid';
import { computed, ref } from 'vue';
import Button from '../Button/Button.vue';
import DeleteForeverIcon from '../Icon/DeleteForeverIcon.vue';
import Label from './Label.vue';

export type ComboboxItem = {
    id: string;
    name: string;
    disabled?: boolean;
};

type ComboboxFieldProps = {
    label: string;
    items: ComboboxItem[] | readonly ComboboxItem[];
    value: ComboboxItem | null;
    autocomplete?: string;
    description?: string;
    helpButtonText?: string;
    required?: boolean;
    errorMessages?: string[];
    disabled?: boolean;
    status?: 'overridden';
    hideClearButton?: boolean;
};

const props = defineProps<ComboboxFieldProps>();
defineEmits<{ (e: 'change', v: ComboboxItem | null): void }>();
const inputId = nanoid();
const query = ref('');

const filteredItems = computed(() =>
    query.value === ''
        ? props.items.filter((item) => item.name !== '')
        : props.items.filter((item) => {
              return item.name.toLowerCase().includes(query.value.toLowerCase()) && item.name !== '';
          }),
);

const styling = computed(() => {
    return [
        'w-full',
        'bg-white',
        'py-[0.5625rem]',
        'pl-3',
        'pr-10',
        'rounded-md',
        'shadow-xs',
        'sm:text-sm',
        'disabled:cursor-not-allowed',
        'disabled:border-gray-200',
        'disabled:bg-gray-50',
        'disabled:text-gray-500',
        props.errorMessages && props.errorMessages.length
            ? 'focus:outline-hidden border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
            : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
        {
            'focus:outline-hidden border-blue-500 text-blue-600 focus:ring-blue-600 focus:border-blue-600':
                props.status === 'overridden',
        },
    ];
});

function onInputClick(e: Event) {
    // click ComboboxButton to emulate opening and closing the Options
    // by clicking inside the input field
    const target = e.target as HTMLInputElement;
    const button = target.nextSibling as HTMLButtonElement;
    button.click();
}

const comboboxInputRef = ref();
function focus() {
    comboboxInputRef.value.el.focus();
}

defineExpose({
    focus,
});
</script>
