import auth from '@/auth/auth';
import {
    CreateCreatedAtEntryDocument,
    CreateNewMainVariantEntryDocument,
    CreateStatusChangeEntryDocument,
    CreateUserTextEntryDocument,
    DeleteFeedEntryDocument,
    GetFeedDocument,
    UpdateUserTextEntryDocument,
} from '@/generated/graphql';
import { useMyUser } from '@/user/composables/useMyUser';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';
import { computed, ref, Ref, toValue } from 'vue';
import { DELETED_STATES, EditableFeedEntry, FeedEntryType, UnsavedChanges } from '../types';

function isEntryEditable(type: FeedEntryType, createdById: string, userId: string, isAdmin: boolean) {
    // only user created entries are editable
    if (type === 'user-text' && isAdmin && userId === createdById) {
        return true;
    }
    return false;
}

export function useFeed(feedId: Ref<string>) {
    const { currentUser } = useMyUser();
    const isAdmin = auth.isAdmin();
    const feedEntries: Ref<EditableFeedEntry[]> = ref([]);

    const getFeed = useQuery(
        GetFeedDocument,
        computed(() => ({ id: toValue(feedId) })),
        { enabled: !!feedId },
    );

    getFeed.onResult((result) => {
        if (!result.loading && result.data && result.data.feed?.feedEntries.nodes) {
            feedEntries.value = result.data.feed.feedEntries.nodes.map((n) => ({
                ...n,
                hasUnsavedChanges: 'None',
                isEditable: isEntryEditable(
                    n.type as FeedEntryType,
                    n.createdBy?.id ?? '',
                    currentUser.value.id,
                    isAdmin,
                ),
            }));
        }
    });

    function addFeedEntry(feedEntry: EditableFeedEntry | undefined) {
        if (feedEntry) {
            feedEntries.value = [feedEntry, ...feedEntries.value];
        }
    }

    const createUserTextEntryMutation = useMutation(CreateUserTextEntryDocument);

    async function createUserTextEntry(text: string, hasUnsavedChanges?: UnsavedChanges) {
        const response = await createUserTextEntryMutation.mutate({
            feedId: toValue(feedId),
            createdById: toValue(currentUser).id,
            createdByName: toValue(currentUser).name,
            type: 'user-text',
            text,
        });
        if (response?.errors) {
            throw new Error(`Could not create Feed Entry: ${response?.errors}`);
        }
        if (!response?.data?.createFeedEntry?.feedEntry) {
            throw new Error('Create feed entry did not return data.');
        }
        const unsavedChanges = hasUnsavedChanges ?? ('None' as const);
        const entry = {
            ...response.data.createFeedEntry.feedEntry,
            hasUnsavedChanges: unsavedChanges,
            isEditable: isAdmin,
        };
        addFeedEntry(entry);
    }

    const createCreatedAtEntryMutation = useMutation(CreateCreatedAtEntryDocument);

    async function createCreatedAtEntry(createdAt: string) {
        const response = await createCreatedAtEntryMutation.mutate({
            feedId: toValue(feedId),
            createdById: toValue(currentUser).id,
            createdByName: toValue(currentUser).name,
            type: 'created-at',
            objectCreatedAt: createdAt,
        });
        if (response?.errors) {
            throw new Error(`Could not create Feed Entry: ${response?.errors}`);
        }
        if (!response?.data?.createFeedEntry?.feedEntry) {
            throw new Error('Create feed entry did not return data.');
        }
        const entry = {
            ...response.data.createFeedEntry.feedEntry,
            hasUnsavedChanges: 'None' as const,
            isEditable: false,
        };
        addFeedEntry(entry);
    }

    const createStatusChangeEntryMutation = useMutation(CreateStatusChangeEntryDocument);

    async function createStatusChangeEntry(oldStatus: string, newStatus: string) {
        const response = await createStatusChangeEntryMutation.mutate({
            feedId: toValue(feedId),
            createdById: toValue(currentUser).id,
            createdByName: toValue(currentUser).name,
            type: 'new-status',
            oldStatus,
            newStatus,
        });
        if (response?.errors) {
            throw new Error(`Could not create Feed Entry: ${response.errors}`);
        }
        if (!response?.data?.createFeedEntry?.feedEntry) {
            throw new Error('Create feed entry did not return data.');
        }
        const entry = {
            ...response.data.createFeedEntry.feedEntry,
            hasUnsavedChanges: 'None' as const,
            isEditable: false,
        };
        addFeedEntry(entry);
    }

    const createNewMainVariantEntryMutation = useMutation(CreateNewMainVariantEntryDocument);

    async function createNewMainVariantEntry(
        oldVariantId: string,
        oldVariantIndex: number,
        newVariantId: string,
        newVariantIndex: number,
    ) {
        const response = await createNewMainVariantEntryMutation.mutate({
            feedId: toValue(feedId),
            createdById: toValue(currentUser).id,
            createdByName: toValue(currentUser).name,
            type: 'new-main-variant',
            oldMainVariantId: oldVariantId,
            oldMainVariantIndex: oldVariantIndex,
            newMainVariantId: newVariantId,
            newMainVariantIndex: newVariantIndex,
        });
        if (response?.errors) {
            throw new Error(`Could not create Feed Entry: ${response.errors}`);
        }
        if (!response?.data?.createFeedEntry?.feedEntry) {
            throw new Error('Create feed entry did not return data.');
        }
        const entry = {
            ...response.data.createFeedEntry.feedEntry,
            hasUnsavedChanges: 'None' as const,
            isEditable: false,
        };
        addFeedEntry(entry);
    }

    function setUnsavedChanges(entryId: string, status: UnsavedChanges) {
        feedEntries.value = feedEntries.value.map((entry) =>
            entry.id === entryId
                ? {
                      ...entry,
                      hasUnsavedChanges: status,
                  }
                : entry,
        );
    }

    function setText(entryId: string, text: string) {
        feedEntries.value = feedEntries.value.map((entry) => {
            if (entry.id === entryId) {
                const hasUnsavedChanges = entry.hasUnsavedChanges === 'New' ? 'New' : 'Edited';
                if (entry.oldText) {
                    return {
                        ...entry,
                        text,
                        hasUnsavedChanges,
                    };
                }
                // only set oldText once, this is the original text
                return {
                    ...entry,
                    text,
                    oldText: entry.text,
                    hasUnsavedChanges,
                };
            }
            return entry;
        });
    }

    function createUnsavedFeedEntry(text: string) {
        const { id, icon, name } = toValue(currentUser);
        const entry = {
            id: `new-${nanoid()}`,
            createdAt: DateTime.now().toISO(),
            createdById: id,
            createdByName: name,
            createdBy: {
                id,
                icon,
            },
            type: 'user-text',
            text,
            markdownText: '',
            oldStatus: '',
            newStatus: '',
            hasUnsavedChanges: 'New' as const,
            isEditable: true,
        };
        addFeedEntry(entry);
    }

    function onDelete(id: string, hasUnsavedChanges: UnsavedChanges) {
        switch (hasUnsavedChanges) {
            case 'New':
                setUnsavedChanges(id, 'NewAndDeleted');
                break;
            case 'Edited':
                setUnsavedChanges(id, 'EditedAndDeleted');
                break;
            default:
                setUnsavedChanges(id, 'Deleted');
        }
    }

    function undoDelete(id: string, hasUnsavedChanges: UnsavedChanges) {
        switch (hasUnsavedChanges) {
            case 'EditedAndDeleted':
                setUnsavedChanges(id, 'Edited');
                break;
            case 'NewAndDeleted':
                setUnsavedChanges(id, 'New');
                break;
            default:
                setUnsavedChanges(id, 'None');
        }
    }

    const updateUserTextEntry = useMutation(UpdateUserTextEntryDocument);
    const deleteFeedEntry = useMutation(DeleteFeedEntryDocument);

    function resetUnsavedChanges() {
        feedEntries.value = feedEntries.value
            .filter((entry) => entry.hasUnsavedChanges !== 'New')
            .map((entry) => {
                const hasUnsavedChanges = 'None';
                // reset edited text
                if (entry.oldText) {
                    return { ...entry, text: entry.oldText, hasUnsavedChanges };
                }
                return { ...entry, hasUnsavedChanges };
            });
    }

    const hasUnsavedChanges = computed(
        () => feedEntries.value.filter((entry) => entry.hasUnsavedChanges !== 'None').length > 0,
    );

    async function onSaveFeed() {
        const entriesToCreate = feedEntries.value.filter((entry) => entry.hasUnsavedChanges === 'New');
        const entriesToUpdate = feedEntries.value.filter((entry) => entry.hasUnsavedChanges === 'Edited');
        const entriesToDelete = feedEntries.value.filter((entry) =>
            ['Deleted', 'EditedAndDeleted'].includes(entry.hasUnsavedChanges),
        );

        const currentUserId = toValue(currentUser).id;
        const currentUserName = toValue(currentUser).name;

        for (const entry of entriesToCreate) {
            const { id, ...data } = entry; // remove temp id
            const createResponse = await createUserTextEntryMutation.mutate({
                ...data,
                feedId: toValue(feedId),
                createdById: currentUserId,
                createdByName: currentUserName,
            });
            const resultEntry = createResponse?.data?.createFeedEntry?.feedEntry;
            if (!resultEntry) throw new Error(`Could not create new feed entry: ${createResponse?.errors}`);
            feedEntries.value = feedEntries.value.map((e) =>
                e.id === id
                    ? {
                          ...resultEntry,
                          hasUnsavedChanges: 'None',
                          isEditable: isEntryEditable(
                              e.type as FeedEntryType,
                              e.createdBy?.id ?? '',
                              currentUser.value.id,
                              isAdmin,
                          ),
                      }
                    : e,
            );
        }

        for (const entry of entriesToUpdate) {
            const { oldText, ...data } = entry;
            await updateUserTextEntry.mutate({
                ...data,
                updatedById: currentUserId,
                updatedByName: currentUserName,
            });
        }

        for (const entry of entriesToDelete) {
            await deleteFeedEntry.mutate({ id: entry.id });
        }

        feedEntries.value = feedEntries.value
            .filter((entry) => !DELETED_STATES.includes(entry.hasUnsavedChanges))
            .map((entry) => ({ ...entry, hasUnsavedChanges: 'None' }));
    }

    function onDiscardFeedChanges() {
        resetUnsavedChanges();
    }

    return {
        feedEntries,
        hasUnsavedChanges,
        addFeedEntry,
        createUserTextEntry,
        createCreatedAtEntry,
        createStatusChangeEntry,
        createNewMainVariantEntry,
        setUnsavedChanges,
        setText,
        createUnsavedFeedEntry,
        onSaveFeed,
        onDelete,
        undoDelete,
        onDiscardFeedChanges,
        isEntryEditable,
    };
}
