<template>
    <div class="flex flex-col py-3">
        <div class="grid grid-cols-[1fr_min-content] items-center gap-6">
            <div class="flex flex-col">
                <div class="flex flex-row justify-between flex-wrap gap-x-3 gap-y-1">
                    <div class="flex flex-row items-center gap-x-3">
                        <AllocationVariantIndex :index="props.variantIndex" />

                        <LinkButton
                            class="text-gray-700"
                            size="Small"
                            variant="Transparent-Light"
                            :icon-description="$t('open-allocation-variant-detail-page')"
                            :open-in-new-tab="true"
                            :to="props.linkTarget"
                        >
                            <template #icon>
                                <ExternalLinkIcon class="w-5 h-5" />
                            </template>
                        </LinkButton>
                    </div>

                    <div class="grow flex flex-row items-center gap-x-3">
                        <Badge
                            size="Small"
                            :variant="props.statusVariant"
                            :tooltip-text="$t('change-allocation-variant-in-tab')"
                        >
                            {{ props.statusLabel }}

                            <StarFilledIcon v-if="props.isMainVariant" class="ml-1.5" />
                        </Badge>
                    </div>

                    <ActivatedToggle
                        :is-active="props.isVisible"
                        :disabled-explanation="
                            isHighlighted ? 'Hervorgehobene Varianten können nicht versteckt werden' : undefined
                        "
                        :tooltip-text="
                            props.isVisible ? $t('click-to-hide-in-timeline') : $t('click-to-show-in-timeline')
                        "
                        @toggle="emit('toggleVisibility')"
                    >
                        {{ $t('allocation-variant-visible') }}
                    </ActivatedToggle>
                </div>

                <div class="flex flex-row items-center gap-2 mt-0.5 font-light">
                    <p class="text-gray-700">{{ props.variantName }}</p>
                    <div
                        v-if="!props.hasAllocations"
                        class="text-yellow-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-700 rounded-sm flex flex-row gap-1 items-center text-sm ring-1 bg-yellow-100 ring-yellow-300 px-1.5"
                    >
                        <WarningIcon class="size-4" />

                        {{ $t('has-no-allocations') }}
                    </div>
                </div>
            </div>

            <Button
                size="Small"
                variant="Neutral-Strong"
                :icon-description="
                    props.copyingAllocationsStatus === 'Hidden'
                        ? $t('copy-allocations-mode-trigger')
                        : $t('leave-copy-allocations-mode-trigger')
                "
                :disabled-explanation="props.copyAllocationsButtonDisabledExplanation"
                @click="
                    props.copyingAllocationsStatus === 'Hidden'
                        ? emit('startCopyingAllocations')
                        : emit('abortCopyingAllocations')
                "
            >
                <template #icon>
                    <CopyAllIcon v-if="props.copyingAllocationsStatus === 'Hidden'" class="w-5 h-5" />
                    <UndoIcon v-if="props.copyingAllocationsStatus !== 'Hidden'" class="w-5 h-5" />
                </template>
            </Button>
        </div>

        <div
            v-if="props.copyingAllocationsStatus !== 'Hidden'"
            class="flex flex-row flex-wrap items-end gap-2 bg-red-100 p-3 rounded-md mt-2"
        >
            <ComboboxField
                ref="copyAllocationsFromRef"
                class="grow"
                :items="
                    allVariants.map((variant) => ({
                        ...variant,
                        id: String(variant.id),
                        disabled: variantId === variant.id,
                    }))
                "
                :label="$t('copy-allocations-from-label')"
                :value="currentItem"
                :hide-clear-button="true"
                @change="(selectedItem) => (currentItem = selectedItem)"
            />

            <Button
                size="Base"
                variant="Danger-Strong"
                :disabled-explanation="currentItem ? undefined : $t('copy-allocations-selecting-source-required')"
                @click="() => onCopyAllocations(props.hasAllocations)"
            >
                <template #icon>
                    <CopyAllIcon
                        v-if="
                            props.copyingAllocationsStatus === 'Visible' || props.copyingAllocationsStatus === 'Loading'
                        "
                        class="w-5 h-5"
                    />
                    <RefreshIcon v-if="props.copyingAllocationsStatus === 'Error'" class="w-5 h-5" />
                </template>

                <template v-if="props.copyingAllocationsStatus === 'Visible' && !props.hasAllocations">
                    {{ $t('copy-allocations-execute-copying') }}
                </template>
                <template v-if="props.copyingAllocationsStatus === 'Visible' && props.hasAllocations">
                    {{ $t('copy-allocations-execute-overwrite') }}
                </template>
                <template v-if="props.copyingAllocationsStatus === 'Loading'">
                    {{ $t('copy-allocations-execute-copying-pending') }}
                </template>
                <template v-if="props.copyingAllocationsStatus === 'Error'">
                    {{ $t('copy-allocations-execute-copying-failed') }}
                </template>
            </Button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { $t } from '@/plugins/fluent';
import { ref, watchEffect } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import ActivatedToggle from '../ActivatedToggle/ActivatedToggle.vue';
import AllocationVariantIndex from '../AllocationVariantIndex/AllocationVariantIndex.vue';
import Badge from '../Badge/Badge.vue';
import Button from '../Button/Button.vue';
import LinkButton from '../Button/LinkButton.vue';
import ComboboxField, { ComboboxItem } from '../Form/ComboboxField.vue';
import CopyAllIcon from '../Icon/CopyAllIcon.vue';
import ExternalLinkIcon from '../Icon/ExternalLinkIcon.vue';
import RefreshIcon from '../Icon/RefreshIcon.vue';
import StarFilledIcon from '../Icon/StarFilledIcon.vue';
import UndoIcon from '../Icon/UndoIcon.vue';
import WarningIcon from '../Icon/WarningIcon.vue';

type Variant = {
    id: string;
    name: string;
};

type AllocationVariantEntryProps = {
    isVisible: boolean;
    isHighlighted: boolean;
    copyAllocationsButtonDisabledExplanation?: string;
    statusLabel: string;
    statusVariant: 'Green' | 'Yellow' | 'Red';
    isMainVariant: boolean;
    variantId: string;
    variantIndex: number;
    variantName: string;
    preselectedVariant: Variant | null;
    allVariants: Variant[];
    copyingAllocationsStatus: 'Hidden' | 'Visible' | 'Loading' | 'Error';
    linkTarget: RouteLocationRaw;
    hasAllocations: boolean;
};

type AllocationVariantEntryEmits = {
    (e: 'toggleVisibility'): void;
    (e: 'copyAllocations', copyFrom: string): void;
    (e: 'startCopyingAllocations'): void;
    (e: 'abortCopyingAllocations'): void;
};

const props = defineProps<AllocationVariantEntryProps>();
const emit = defineEmits<AllocationVariantEntryEmits>();

const currentItem = ref<ComboboxItem | null>(null);

watchEffect(() => {
    if (props.preselectedVariant) {
        currentItem.value = {
            id: String(props.preselectedVariant.id),
            name: props.preselectedVariant.name,
        };
    } else {
        currentItem.value = null;
    }
});

function onCopyAllocations(isConfirmationRequired: boolean) {
    if (!currentItem.value) {
        throw new Error('Missing allocation variant source to copy allocations from');
    }

    if (isConfirmationRequired) {
        if (confirm($t('copy-allocations-overwrite-is-definitive'))) {
            emit('copyAllocations', currentItem.value.id);
        }
    } else {
        emit('copyAllocations', currentItem.value.id);
    }
}

const copyAllocationsFromRef = ref();
watchEffect(async () => {
    if (props.copyingAllocationsStatus === 'Visible' && copyAllocationsFromRef.value) {
        copyAllocationsFromRef.value.focus();
    }
});
</script>
