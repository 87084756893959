import { DateTime, QuarterNumbers } from 'luxon';
import { Ref, toValue } from 'vue';

export function cleanYear(year: string, defaultValue?: number) {
    if (year !== '') {
        const date = DateTime.fromFormat(year, 'yyyy');
        if (date.isValid && date.year === parseInt(year, 10)) {
            return date.year;
        }
    }
    return defaultValue ?? DateTime.now().year;
}

export function cleanQuarter(quarter: string, defaultValue?: QuarterNumbers) {
    if (quarter !== '') {
        const date = DateTime.fromFormat(quarter, 'q');
        if (date.isValid && date.quarter === parseInt(quarter, 10)) {
            return date.quarter;
        }
    }
    return defaultValue ?? DateTime.now().quarter;
}

export function cleanVisible(visible: string, defaultValue?: number[]) {
    if (visible !== '') {
        if (visible.includes(',')) {
            // multiple values
            return visible.split(',').map((v) => Number(v));
        }
        // single value
        return [Number(visible)];
    }
    return defaultValue ?? [];
}

export function cleanHighlight(highlight: string, defaultValue?: number) {
    if (highlight !== '') {
        return Number(highlight);
    }
    return defaultValue ?? 0;
}

export function getDateTimeFromYearAndQuarter(year: Ref<string>, quarter: Ref<string>) {
    const yearParam = toValue(year);
    const quarterParam = toValue(quarter);
    if (yearParam === '' && quarterParam === '') {
        return undefined;
    }
    const month = DateTime.fromFormat(quarterParam, 'q').month;
    return DateTime.fromObject({
        year: parseInt(yearParam),
        month,
    });
}
