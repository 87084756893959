import { TokenRefreshDocument } from '@/generated/graphql';
import apolloClient from '@/plugins/apollo';
import { $t } from '@/plugins/fluent';
import router from '@/plugins/router';
import { useTimer } from '@/utils/hooks/timer';
import { useMutation } from '@vue/apollo-composable';

export interface LoginCredentials {
    email: string;
    password: string;
}

export type Session = {
    jwt: string;
    user: {
        name: string;
        firstName: string;
        lastName: string;
        email: string;
        isAdmin: boolean;
        avatarUrl: string;
    };
};

export default {
    storeSession(session: Session) {
        localStorage.token = session.jwt;
        localStorage.user = JSON.stringify(session.user);
    },
    getToken: () => localStorage.token,
    isAdmin: () => (JSON.parse(localStorage.user)?.isAdmin ?? false) as boolean,
    isLoggedIn: () => !!localStorage.token,
    logout() {
        delete localStorage.token;
        delete localStorage.user;
        apolloClient.clearStore();

        router.push('/login');
    },
    useRegularTokenRefresh() {
        const updateJwt = useMutation(TokenRefreshDocument, {
            update: (_cache, { data }) => {
                if (data?.refreshJwt?.__typename === 'Session') {
                    this.storeSession(data.refreshJwt);
                }
            },
        });
        useTimer(1 * 60 * 1000, updateJwt.mutate);
    },
    getUser() {
        try {
            return JSON.parse(localStorage.user) as Session['user'];
        } catch (error) {
            throw new Error(`Parsing the current user (in LocalStorage) failed: ${error}`);
        }
    },
    logoutAndRedirectToLogin() {
        console.warn('Login expired. Redirecting to login');
        this.logout();
        router.replace({
            path: '/login',
            query: {
                redirect: router.currentRoute.value.fullPath,
                error: $t('login-expired-please-login-again'),
            },
        });
    },
};
