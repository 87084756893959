import { BadgeDropDownStatus, BadgeDropDownValue } from '@/components/BadgeDropDown/BadgeDropDown.vue';
import { eventVariantStatusToBadgeVariant, getEventVariantRoute } from '@/event/event';
import { eventVariantStates, EventVariantStatus } from '@/event/types';
import { UpdateEventVariantDocument, UpdateProjectVariantDocument } from '@/generated/graphql';
import { getProjectVariantRoute, projectVariantStatusToBadgeVariant } from '@/project/project';
import { projectVariantStates, ProjectVariantStatus } from '@/project/types';
import { useMyUser } from '@/user/composables/useMyUser';
import { useMutation } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { ref, Ref, toValue } from 'vue';
import { VariantCardVariant, VariantStatus, VariantType } from '../types';

export function useVariantStatusDropdown(
    variantTypeRef: Ref<VariantType>,
    variantRef: Ref<VariantCardVariant | undefined>,
    variantsRef: Ref<VariantCardVariant[]>,
    setVariantsRef: (variants: VariantCardVariant[]) => void,
    createStatusChangeEntry: (oldStatus: string, newStatus: string) => void,
) {
    const fluent = useFluent();
    const { currentUser } = useMyUser();
    const dropDownStatus: Ref<BadgeDropDownStatus> = ref('Default');
    const mutations = {
        Event: useMutation(UpdateEventVariantDocument),
        Project: useMutation(UpdateProjectVariantDocument),
    };

    function getVariantStatusLabel(status: string) {
        switch (toValue(variantTypeRef)) {
            case 'Event':
                return fluent.$t(`event-variant-status-${status}`);
            case 'Project':
                return fluent.$t(`project-variant-status-${status}`);
        }
    }

    function variantStatusToBadgeVariant(status: EventVariantStatus | ProjectVariantStatus) {
        switch (toValue(variantTypeRef)) {
            case 'Event':
                return eventVariantStatusToBadgeVariant(status);
            case 'Project':
                return projectVariantStatusToBadgeVariant(status);
        }
    }

    function getVariantStatusItem(status: EventVariantStatus | ProjectVariantStatus): BadgeDropDownValue {
        return {
            id: status,
            label: getVariantStatusLabel(status),
            variant: variantStatusToBadgeVariant(status),
        };
    }

    function getVariantStatusOptionItems(
        status: EventVariantStatus | ProjectVariantStatus,
    ): (BadgeDropDownValue & { isSelected: boolean })[] {
        switch (toValue(variantTypeRef)) {
            case 'Event':
                return eventVariantStates.map((s) => ({
                    id: s,
                    label: getVariantStatusLabel(s),
                    variant: variantStatusToBadgeVariant(s),
                    isSelected: status === s,
                }));
            case 'Project':
                return projectVariantStates.map((s) => ({
                    id: s,
                    label: getVariantStatusLabel(s),
                    variant: variantStatusToBadgeVariant(s),
                    isSelected: status === s,
                }));
        }
    }

    async function handleBadgeDropdown(status: string, variantId: string) {
        const variant = toValue(variantRef);
        if (variant) {
            const oldStatus = variant.status;
            if (oldStatus === status) return;

            dropDownStatus.value = 'Loading';
            const user = toValue(currentUser);
            const feedId = variant.changesId;

            if (user && feedId) {
                const updateMutation = mutations[toValue(variantTypeRef)];
                const result = await updateMutation.mutate({
                    id: variantId,
                    feedId,
                    status,
                });
                if (result?.errors) {
                    dropDownStatus.value = 'Retry';
                }
                const variantResult = result?.data?.update?.variant;
                if (variantResult) {
                    createStatusChangeEntry(oldStatus, status);
                    dropDownStatus.value = 'Default';
                    setVariantsRef(
                        toValue(variantsRef).map((v) =>
                            v.id === variantId
                                ? {
                                      ...v,
                                      status: variantResult.status as VariantStatus,
                                  }
                                : v,
                        ),
                    );
                }
            }
        }
    }

    function getVariantRoute(objectId: string, index: number) {
        switch (toValue(variantTypeRef)) {
            case 'Event':
                return getEventVariantRoute(objectId, index);
            case 'Project':
                return getProjectVariantRoute(objectId, index);
        }
    }

    return {
        dropDownStatus,
        getVariantStatusItem,
        getVariantStatusLabel,
        getVariantStatusOptionItems,
        handleBadgeDropdown,
        getVariantRoute,
    };
}
