import auth from '@/auth/auth';
import { getUnstyledAllocationVariantLabel } from '@/components/AllocationVariantIndex/getFullAllocationVariantIndex';
import { ActiveCard } from '@/components/Card/types';
import { useFluent } from 'fluent-vue';
import { Ref, toValue } from 'vue';

export function useVariantCard(
    variantId: Ref<string>,
    activeCard: Ref<ActiveCard>,
    formChanged: Ref<boolean>,
    expandedCards: Ref<string[]>,
    setActiveCard: (value: ActiveCard) => void,
    setExpandedCards: (value: string[]) => void,
) {
    const fluent = useFluent();
    const isAdmin = auth.isAdmin();

    function editButtonDisabledExplanation() {
        if (!isAdmin) return fluent.$t('no-permission-to-edit');
        if (toValue(activeCard).id !== '' && toValue(activeCard).id !== toValue(variantId))
            return fluent.$t('other-card-is-editing');
        return '';
    }
    const expandableButtonDisabledMessage = function () {
        if (toValue(activeCard).id === toValue(variantId)) {
            return fluent.$t('variant-card-expansion-disabled-explanation');
        }
        return '';
    };

    function getExpandStatus() {
        return toValue(expandedCards).includes(toValue(variantId)) ? 'Expanded' : 'Collapsed';
    }

    function onChangeExpand(id: string) {
        if (getExpandStatus() === 'Expanded') {
            setExpandedCards(expandedCards.value.filter((c) => c !== id));
        } else {
            setExpandedCards([...expandedCards.value, id]);
        }
    }

    function onEnterEditMode(variant: { id: string; index: number; name: string; status: string }) {
        const label = getUnstyledAllocationVariantLabel(variant.index, variant.name);
        setActiveCard({ id: variant.id, label, status: 'Edit-Untouched' });
        if (!expandedCards.value.includes(variant.id)) setExpandedCards([...expandedCards.value, variant.id]);
    }

    function onLeaveEditMode() {
        setActiveCard({ id: '', label: '', status: 'View' });
    }

    function isCommentSectionVisible(commentsLength: number) {
        return activeCard.value.status !== 'View' || commentsLength > 0;
    }

    async function onDiscardChanges() {
        if (toValue(formChanged) === true) {
            if (!confirm(fluent.$t('leave-page-warning'))) return false;
        }
        setActiveCard({ id: '', label: '', status: 'View' });
    }

    return {
        getExpandStatus,
        editButtonDisabledExplanation,
        expandableButtonDisabledMessage,
        isCommentSectionVisible,
        onChangeExpand,
        onEnterEditMode,
        onLeaveEditMode,
        onDiscardChanges,
    };
}
