<template>
    <HeaderCardsLayout variant="No-Logo">
        <template #headline>{{ $t('profile') }}</template>
        <template #buttons>
            <Button :size="'Base'" :variant="'Neutral-Strong'" @click="auth.logout()">
                <template #icon>
                    <LogoutIcon />
                </template>
                {{ $t('logout') }}
            </Button>
        </template>
        <template #body>
            <div class="bg-gray-50 h-full -mx-4 p-4">
                <h2 class="text-gray-700 text-xl font-semibold pb-6">{{ $t('change-password') }}</h2>
                <BaseForm @submit.prevent>
                    <template #fields>
                        <PasswordField
                            :value="currentPassword"
                            :label="$t('current-password')"
                            :required="true"
                            :error-messages="currentPasswordErrors"
                            autocomplete="current-password"
                            @input="(event: Event) => handleCurrentPasswordInput(event)"
                        ></PasswordField>
                        <PasswordField
                            :value="newPassword"
                            :label="$t('new-password')"
                            :required="true"
                            :error-messages="newPasswordErrors"
                            autocomplete="new-password"
                            @input="(event: Event) => handleNewPasswordInput(event)"
                        ></PasswordField>
                    </template>
                    <template #buttons>
                        <Button
                            variant="Info-Strong"
                            size="Base"
                            type="submit"
                            :disabled-explanation="saveButtonDisabledExplanation"
                            @click="onSave"
                            >{{ $t('change-password') }}</Button
                        >
                    </template>
                </BaseForm>
            </div>
        </template>
    </HeaderCardsLayout>
</template>
<script setup lang="ts">
import auth from '@/auth/auth';
import Button from '@/components/Button/Button.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import PasswordField from '@/components/Form/PasswordField.vue';
import LogoutIcon from '@/components/Icon/LogoutIcon.vue';
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import { UpdatePasswordDocument } from '@/generated/graphql';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';
import { useMutation } from '@vue/apollo-composable';
import { computed, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useMyUser } from '../composables/useMyUser';

const { currentUser } = useMyUser();
const router = useRouter();

const currentPassword = ref('');
const newPassword = ref('');

const currentPasswordErrors: Ref<string[]> = ref([]);
const newPasswordErrors: Ref<string[]> = ref([]);

const formHasErrors = computed(() => currentPasswordErrors.value.length > 0 || newPasswordErrors.value.length > 0);

const updatePassword = useMutation(UpdatePasswordDocument);

const saveButtonDisabledExplanation = computed(() => {
    if (formHasErrors.value) {
        return $t('unresolved-form-errors');
    }
    if (currentPassword.value === '' || newPassword.value === '') {
        return $t('missing-form-fields');
    }
});

function handleCurrentPasswordInput(event: Event) {
    currentPasswordErrors.value = [];
    const target = event.target as HTMLInputElement;
    currentPassword.value = target.value;
}

function handleNewPasswordInput(event: Event) {
    newPasswordErrors.value = [];
    const target = event.target as HTMLInputElement;
    newPassword.value = target.value;
}

async function onSave() {
    const result = await updatePassword.mutate({
        userId: currentUser.value.id,
        currentPassword: currentPassword.value,
        newPassword: newPassword.value,
    });
    switch (result?.data?.updatePassword.__typename) {
        case 'Success':
            auth.logout();
            router.replace({
                path: '/login',
                query: {
                    redirect: router.currentRoute.value.fullPath,
                    message: $t('password-changed-please-login-again'),
                },
            });
            break;
        case 'InvalidCredentials':
            currentPasswordErrors.value = [$t('wrong-current-password')];
            break;
    }
}

useHeadSafe({ title: () => `${auth.getUser().name} - ${$t('users')}` });
</script>
