<template>
    <i18n path="scenario-index-styled" class="flex flex-row items-center text-gray-400 font-light" tag="div">
        <template #scenarioYearAbbreviation>
            <Tooltip>
                <template #trigger="tooltipProps">
                    <span
                        class="underline decoration-dashed focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 rounded-sm tabular-nums"
                        v-bind="tooltipProps"
                        tabindex="0"
                    >
                        <span>
                            {{ $t('scenario-abbreviated') }}
                        </span>
                        <span class="font-medium text-gray-500">{{ String(props.year) }}</span>
                    </span>
                </template>

                <template #text>
                    {{
                        $t('scenario-full-text', {
                            scenarioYearString: String(props.year),
                        })
                    }}
                </template>
            </Tooltip>
        </template>

        <template #scenarioIndex>
            <span class="tabular-nums">
                <span class="font-light text-gray-400">
                    {{ '0'.repeat(props.maxDigits - indexDigitsCount) }}
                </span>
                <span class="font-medium text-gray-700">{{ String(props.index) }}</span>
            </span>
        </template>
    </i18n>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import Tooltip from '../Tooltip/Tooltip.vue';

type ScenarioIndexProps = {
    index: number;
    maxDigits: number;
    year: number;
};

const props = defineProps<ScenarioIndexProps>();

const indexDigitsCount = computed(() => String(props.index).length);
</script>
