import { getUnstyledAllocationVariantLabel } from '@/components/AllocationVariantIndex/getFullAllocationVariantIndex';
import { TimelineVariant } from '@/components/Timeline/allocation/types';
import { AllocationType } from '@/components/Timeline/Timeline.types';
import { EventVariantStatus } from '@/event/types';
import { ProjectVariantStatus } from '@/project/types';
import { isArrayEqual } from '@/utils/compare';
import { computed, ref, Ref, toValue } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import {
    ACTIVE_STATES,
    DEFAULT_HIGHLIGHT_VALUE,
    ExistingAllocationVariantEntry,
    SelectionPanelEntries,
} from '../types';

function getLinkTarget(allocationType: AllocationType, objectId: string, index: number): RouteLocationRaw {
    if (allocationType === 'Event') {
        return { name: 'event-variants-tab', params: { eventId: objectId }, hash: `#v${index}` };
    }
    if (allocationType === 'Project') {
        return { name: 'project-variants-tab', params: { projectId: objectId }, hash: `#v${index}` };
    }
    // TODO what route do we use for allocations of type None?
    return '';
}

function getVisibleIdsFromIndexes(indexes: number[], variants: TimelineVariant[]) {
    return variants.filter((v) => indexes.includes(v.index)).map((v) => v.id);
}

function getVisibleIndexesFromIds(ids: string[], variants: TimelineVariant[]) {
    return variants.filter((v) => ids.includes(v.id)).map((v) => v.index);
}

function getVariantById(id: string, variants: TimelineVariant[]) {
    return variants.find((v) => v.id === id);
}

function getVariantByIndex(index: number, variants: TimelineVariant[]) {
    return variants.find((v) => v.index === index);
}

export function useVariantSelectionPanel(
    variantsRef: Ref<TimelineVariant[]>,
    allocationType: AllocationType,
    objectId: Ref<string>,
    visible: Ref<string>,
    highlight: Ref<string>,
) {
    const visibleVariants: Ref<string[]> = ref([]);
    const highlightedVariantId = ref(DEFAULT_HIGHLIGHT_VALUE);
    const numVariants = computed(() => toValue(variantsRef).length);
    const numVisibleVariants = computed(() => toValue(visibleVariants).length);
    const defaultActiveVariants = computed(() => toValue(variantsRef).filter((v) => ACTIVE_STATES.includes(v.status)));

    const dropDownStatus = computed(() => {
        if (toValue(numVariants) === 1) {
            if (toValue(numVisibleVariants) === 0) {
                return { status: 'Error' as const, display: 'all-hidden' };
            }

            if (visibleVariantsWithAllocations.value.length === 0) {
                return {
                    status: 'Warning' as const,
                    display: 'warning',
                };
            }

            return { status: 'Default' as const, display: 'all-visible' };
        }

        if (toValue(numVisibleVariants) <= 0) {
            return { status: 'Error' as const, display: 'all-hidden' };
        } else {
            if (visibleVariantsWithAllocations.value.length === 0) {
                return {
                    status: 'Warning' as const,
                    display: 'warning',
                };
            }

            const visibleVariantsAreDefault = isArrayEqual(
                [...visibleVariants.value],
                defaultActiveVariants.value.map((v) => v.id),
            );

            if (highlightedVariant.value || !visibleVariantsAreDefault) {
                return { status: 'Active' as const, display: 'highlighted' };
            }
        }

        return { status: 'Default' as const, display: 'all-visible' };
    });

    const currentVariant = computed(() => {
        const variants = toValue(variantsRef);
        if (variants.length <= 0) return;
        const current = variants.find((v) => v.isCurrent === true);
        if (!current) throw new Error('There must be exactly one main variant!');
        return current;
    });

    const mappedCurrentVariant = computed(() => {
        const variant = currentVariant.value;
        if (variant) {
            return { id: variant.id, name: variant.name || '-', index: variant.index };
        }
    });

    const highlightedVariant = computed(() => {
        return toValue(variantsRef).find((v) => v.id === toValue(highlightedVariantId));
    });

    // Editing in the timeline changes the highlighted variant.
    // If no variant is highlighted it changes the currentVariant.
    const editableVariant = computed(() => highlightedVariant.value ?? currentVariant.value);

    const editableVariantLabel = computed(() => {
        if (!editableVariant.value) {
            return null;
        }

        return getUnstyledAllocationVariantLabel(editableVariant.value.index, editableVariant.value.name);
    });

    const selectionPanelVariants = computed(() => {
        let elements: SelectionPanelEntries = [];
        if (variantsRef.value) {
            elements.push({ type: 'No-Highlight' as const });
            const mappedResult = variantsRef.value.map((v) => ({
                type: 'Existing-Allocation-Variant' as const,
                id: v.id,
                index: v.index,
                variantName: v.name || '-',
                isVisible: visibleVariants.value.includes(v.id),
                isMainVariant: v.isCurrent,
                status: v.status as EventVariantStatus | ProjectVariantStatus,
                linkTarget: getLinkTarget(allocationType, toValue(objectId), v.index),
                hasAllocations: v.rooms.nodes.length > 0 || v.areas.nodes.length > 0,
            }));
            // splice mainVariant out and put it back in to be at the first place
            const mainVariantIndex = mappedResult.findIndex((v) => v.isMainVariant);
            const mainVariant = mappedResult.splice(mainVariantIndex, 1);
            elements.unshift(mainVariant[0]);
            elements = elements.concat(mappedResult);
        }
        return elements;
    });

    const visibleVariantsWithAllocations = computed(() =>
        selectionPanelVariants.value
            .filter(
                (variant): variant is ExistingAllocationVariantEntry => variant.type === 'Existing-Allocation-Variant',
            )
            .filter((variant) => variant.isVisible)
            .filter((variant) => variant.hasAllocations),
    );

    function setVisibleRouteQueryParam(indexes: number[]) {
        const defaultVisibles = isArrayEqual(
            indexes.map((i) => i.toString()),
            defaultActiveVariants.value.map((v) => v.index.toString()),
        );
        if (indexes.length > 0 && !defaultVisibles) {
            visible.value = indexes.join(',');
        } else {
            visible.value = '';
        }
    }

    function setVisibleVariantsByIds(value: string[]) {
        visibleVariants.value = value;
        const indexes = getVisibleIndexesFromIds(value, toValue(variantsRef));
        setVisibleRouteQueryParam(indexes);
    }

    function setVisibleVariantsByIndexes(indexes: number[]) {
        const ids = getVisibleIdsFromIndexes(indexes, toValue(variantsRef));
        visibleVariants.value = ids;
        setVisibleRouteQueryParam(indexes);
    }

    function updateVisibility(params: { allocationVariantId: string; isVisible: boolean }) {
        const { allocationVariantId, isVisible } = params;
        const visibles = toValue(visibleVariants);
        if (isVisible === false && visibles.includes(allocationVariantId)) {
            const index = visibles.findIndex((v) => v === allocationVariantId);
            visibles.splice(index, 1);
        }
        if (isVisible === true && !visibles.includes(allocationVariantId)) {
            visibles.push(allocationVariantId);
        }
        setVisibleVariantsByIds(visibles);
    }

    function setHighlightedVariantIdById(id: string | undefined) {
        // we have to handle null here, because the component can return undefined as id
        if (id) {
            highlightedVariantId.value = id;
            const variant = getVariantById(id, variantsRef.value);
            if (variant) {
                highlight.value = variant.index.toString();
            }
        } else {
            highlightedVariantId.value = DEFAULT_HIGHLIGHT_VALUE;
            highlight.value = DEFAULT_HIGHLIGHT_VALUE;
        }
    }

    function setHighlightedVariantIdByIndex(index: number) {
        if (index > 0) {
            const variant = getVariantByIndex(index, variantsRef.value);
            if (variant) {
                highlightedVariantId.value = variant.id;
                highlight.value = variant.index.toString();
            }
        }
    }

    function onDeleteFilter() {
        highlightedVariantId.value = DEFAULT_HIGHLIGHT_VALUE;
        visibleVariants.value =
            variantsRef.value.filter((v) => ACTIVE_STATES.includes(v.status)).map((v) => v.id) ?? [];
        visible.value = '';
        highlight.value = DEFAULT_HIGHLIGHT_VALUE;
    }

    return {
        dropDownStatus,
        visibleVariants,
        defaultActiveVariants,
        editableVariant,
        editableVariantLabel,
        highlightedVariantId,
        highlightedVariant,
        mappedCurrentVariant,
        selectionPanelVariants,
        setVisibleVariantsByIds,
        setVisibleVariantsByIndexes,
        setHighlightedVariantIdByIndex,
        setHighlightedVariantIdById,
        onDeleteFilter,
        updateVisibility,
    };
}
