<template>
    <div class="flex flex-col outline-hidden focus-visible:border-sky-700" :aria-labelledby="radioButtonGroupLabelId">
        <h2 :id="radioButtonGroupLabelId" class="sr-only">{{ props.label }}</h2>
        <slot />
    </div>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid';
import { provide, ref, toRef } from 'vue';
import { triggerHideTooltipKey } from '../Tooltip/injectionKeys';
import { radioButtonGroupKey } from './RadioButtonGroup.injectionKeys';

type RadioButtonGroupProps = {
    activeId: string | undefined;
    name: string;
    label: string;
};

type RadioButtonGroupEmits = {
    (e: 'change', value: string | undefined): void;
};

const props = defineProps<RadioButtonGroupProps>();
const emit = defineEmits<RadioButtonGroupEmits>();

const activeIdRef = toRef(props, 'activeId');
const nameRef = toRef(props, 'name');

provide(radioButtonGroupKey, {
    activeId: activeIdRef,
    name: nameRef,
    onChange: (activeId) => {
        emit('change', activeId);
        triggerHideTooltip();
    },
});

const radioButtonGroupLabelId = nanoid();

// This is used to force close the Tooltip after clicking the button
const triggerHideTooltipKeyCounter = ref(0);
provide(triggerHideTooltipKey, triggerHideTooltipKeyCounter);

function triggerHideTooltip() {
    triggerHideTooltipKeyCounter.value = triggerHideTooltipKeyCounter.value + 1;
}
</script>
