import AreaIcon from '@/components/Icon/AreaIcon.vue';
import NatureIcon from '@/components/Icon/NatureIcon.vue';
import ParkingIcon from '@/components/Icon/ParkingIcon.vue';
import PlotIcon from '@/components/Icon/PlotIcon.vue';
import RampIcon from '@/components/Icon/RampIcon.vue';
import TruckIcon from '@/components/Icon/TruckIcon.vue';
import ZoneIcon from '@/components/Icon/ZoneIcon.vue';
import { LocationType, LocationTypeCategory } from '@/components/Timeline/Timeline.types';
import { Component, markRaw } from 'vue';
import { $t } from '../../../plugins/fluent';
import BoulevardIcon from '../../Icon/BoulevardIcon.vue';
import BuildingIcon from '../../Icon/BuildingIcon.vue';
import ConferenceIcon from '../../Icon/ConferenceIcon.vue';
import EntryIcon from '../../Icon/EntryIcon.vue';
import ExhibitionAreaIcon from '../../Icon/ExhibitionAreaIcon.vue';
import LayersIcon from '../../Icon/LayersIcon.vue';
import OfficeIcon from '../../Icon/OfficeIcon.vue';
import PartitionIcon from '../../Icon/PartitionIcon.vue';
import PassageIconVue from '../../Icon/PassageIcon.vue';
import RoomIcon from '../../Icon/RoomIcon.vue';
import ServiceCenterIcon from '../../Icon/ServiceCenterIcon.vue';
import StorageRoomIcon from '../../Icon/StorageRoomIcon.vue';

export function getIcon(typeCategory: LocationTypeCategory, type?: LocationType): Component {
    if (type) {
        switch (type) {
            case 'Boulevardsektion':
                return markRaw(BoulevardIcon);

            case 'Konferenzraum':
                return markRaw(ConferenceIcon);

            case 'Eingang/Zugang':
                return markRaw(EntryIcon);

            case 'Hallenausstellungsfläche':
                return markRaw(ExhibitionAreaIcon);

            case 'Büro/Besprecher (ZBV intern)':
            case 'Büro/Besprecher (ZBV extern)':
                return markRaw(OfficeIcon);

            case 'Passage':
            case 'Passage inkl. Durchfahrtstor':
                return markRaw(PassageIconVue);

            case 'Service-Center':
                return markRaw(ServiceCenterIcon);

            case 'Lager (ZBV intern)':
            case 'Lager (ZBV extern)':
                return markRaw(StorageRoomIcon);

            case 'Parkfläche (intern)':
            case 'Parkfläche (extern)':
                return markRaw(ParkingIcon);

            case 'Außenfläche (intern)':
            case 'Außenfläche (extern)':
                return markRaw(NatureIcon);

            case 'Geländezufahrt/-zugang':
                return markRaw(RampIcon);

            case 'Fahrstraße (intern)':
            case 'Fahrstraße (extern)':
                return markRaw(TruckIcon);
        }
    }

    switch (typeCategory) {
        case 'Building':
            return markRaw(BuildingIcon);

        case 'Floor':
            return markRaw(LayersIcon);

        case 'Room':
            return markRaw(RoomIcon);

        case 'Partition':
            return markRaw(PartitionIcon);

        case 'Area':
            return markRaw(AreaIcon);

        case 'Plot':
            return markRaw(PlotIcon);

        case 'Zone':
            return markRaw(ZoneIcon);

        default:
            throw new Error(`getLocationIcon() failed for unknown type category: ${typeCategory}, type: ${type}`);
    }
}

export function getIconTitle(typeCategory: LocationTypeCategory, type?: LocationType): string {
    if (type) {
        switch (type) {
            case 'Boulevardsektion':
                return $t('boulevard');

            case 'Konferenzraum':
                return $t('conference-room');

            case 'Eingang/Zugang':
                return $t('entry');

            case 'Hallenausstellungsfläche':
                return $t('exhibition-area');

            case 'Büro/Besprecher (ZBV intern)':
            case 'Büro/Besprecher (ZBV extern)':
                return $t('office');

            case 'Passage':
            case 'Passage inkl. Durchfahrtstor':
                return $t('passage');

            case 'Service-Center':
                return $t('service-center');

            case 'Lager (ZBV intern)':
            case 'Lager (ZBV extern)':
                return $t('storage-room');

            case 'Parkfläche (intern)':
            case 'Parkfläche (extern)':
                return $t('parking');

            case 'Außenfläche (intern)':
            case 'Außenfläche (extern)':
                return $t('outdoor');

            case 'Geländezufahrt/-zugang':
                return $t('entrance');
        }
    }

    switch (typeCategory) {
        case 'Building':
            return $t('building');

        case 'Floor':
            return $t('floor');

        case 'Room':
            return $t('room');

        case 'Partition':
            return $t('partition');

        case 'Area':
            return $t('area');

        case 'Plot':
            return $t('plot');

        case 'Zone':
            return $t('zone');

        default:
            throw new Error(`getLocationIconTitle() failed for unknown type category: ${typeCategory}, type: ${type}`);
    }
}
