<template>
    <BaseForm>
        <template #fields>
            <FieldGroup :title="$t('identifiers')">
                <TextField
                    :value="project.nameShort"
                    :label="$t('name-short')"
                    :error-messages="errors.nameShort"
                    @input="handleFormField(($event.target as HTMLInputElement).value, 'nameShort')"
                />
                <TextField
                    :value="project.nameLong"
                    :label="$t('name-long')"
                    :error-messages="errors.nameLong"
                    @input="handleFormField(($event.target as HTMLInputElement).value, 'nameLong')"
                />
                <NumberField
                    :value="project.executionYear ?? ''"
                    :label="$t('execution-year')"
                    :error-messages="errors.executionYear"
                    @input="handleFormField(($event.target as HTMLInputElement).value, 'executionYear')"
                />
                <ComboboxField
                    :value="selectedStatus"
                    :label="$t('status')"
                    :error-messages="errors.status"
                    :items="getProjectStatusComboboxItems()"
                    @change="handleFormField($event ? $event.id : '', 'status')"
                />
            </FieldGroup>
        </template>
    </BaseForm>
    <div class="p-3 flex justify-end gap-3">
        <Button size="Base" variant="Info-Light" @click="$router.back">
            <template #icon>
                <UndoIcon />
            </template>
            {{ $t('card-abort') }}
        </Button>
        <Button
            size="Base"
            variant="Success-Light"
            :disabled-explanation="saveButtonDisabledExplanation"
            @click="onSave"
        >
            <template #icon>
                <AllDoneIcon />
            </template>
            {{ $t('card-save') }}
        </Button>
    </div>
</template>
<script setup lang="ts">
import auth from '@/auth/auth';
import Button from '@/components/Button/Button.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import ComboboxField from '@/components/Form/ComboboxField.vue';
import FieldGroup from '@/components/Form/FieldGroup.vue';
import NumberField from '@/components/Form/NumberField.vue';
import TextField from '@/components/Form/TextField.vue';
import AllDoneIcon from '@/components/Icon/AllDoneIcon.vue';
import UndoIcon from '@/components/Icon/UndoIcon.vue';
import { CreateProjectDocument } from '@/generated/graphql';
import { DEFAULT_PROJECT_STATUS, getProjectStatusComboboxItems, goToProject } from '@/project/project';
import { ProjectCreateErrors, ProjectCreateSchema, ProjectCreateSchemaType } from '@/project/schemas';
import { ProjectCreate, ProjectStatus } from '@/project/types';
import { useMyUser } from '@/user/composables/useMyUser';
import { useMutation } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed, reactive } from 'vue';
import { useToast } from 'vue-toastification';

const props = defineProps<{ seriesId: string }>();

const fluent = useFluent();
const toast = useToast();
const createProject = useMutation(CreateProjectDocument);
const isAdmin = auth.isAdmin();
const { currentUser } = useMyUser();

const seriesId = computed(() => props.seriesId);

const project = reactive<ProjectCreate>({
    nameShort: '',
    nameLong: '',
    status: DEFAULT_PROJECT_STATUS,
    executionYear: undefined,
});

function toStatusItem(status: ProjectStatus) {
    if (!status) return null;
    return { id: status, name: fluent.$t(`project-status-${status}`) };
}

const selectedStatus = computed(() => toStatusItem(project.status as ProjectStatus));

const errors = reactive<ProjectCreateErrors>({
    nameShort: [],
    nameLong: [],
    status: [],
    executionYear: [],
});

function handleFormField(value: string, fieldName: string) {
    const parse = ProjectCreateSchema.shape[fieldName as keyof ProjectCreateSchemaType].safeParse(value);
    if (parse.success) {
        errors[fieldName as keyof ProjectCreateErrors] = [];
        project[fieldName] = parse.data;
    } else {
        errors[fieldName as keyof ProjectCreateErrors] = parse.error.format()._errors;
        project[fieldName] = value;
    }
}

async function onSave() {
    const parse = ProjectCreateSchema.safeParse(project);
    if (!parse.success) {
        const flattenedErrors = parse.error.flatten().fieldErrors;
        Object.assign(errors, flattenedErrors);
        return;
    }
    const params = {
        seriesId: seriesId.value,
        ...parse.data,
        createdById: currentUser.value.id,
        createdByName: currentUser.value.name,
    };
    const res = await createProject.mutate(params);
    const { id, nameShort } = res?.data?.createProject?.project ?? {};
    if (id && nameShort) {
        toast.success(fluent.$t('create-success-notification', { type: 'project', name: nameShort }));
        goToProject(id);
    }
}

const saveButtonDisabledExplanation = computed(() => {
    return isAdmin ? undefined : fluent.$t('no-permission-to-edit');
});
</script>
