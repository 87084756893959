<template>
    <div>
        <LabelComponent
            :label="props.label"
            :input-id="inputId"
            :help-button-text="props.helpButtonText"
            :is-required="props.required"
            :as-div="true"
        />
        <div class="mt-1 flex gap-x-2 h-36">
            <div
                :class="[
                    'flex grow justify-center rounded-md border-2 border-dashed p-6 ',
                    disabled
                        ? 'cursor-not-allowed bg-gray-50 text-gray-500 border-gray-200'
                        : 'bg-white border-gray-300',
                ]"
            >
                <ProgressSpinner v-if="loading" />
                <div v-else class="space-y-1 flex flex-col justify-center">
                    <svg
                        class="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                    >
                        <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <div class="flex items-center text-sm text-gray-600">
                        <label
                            :for="inputId"
                            :class="[
                                disabled
                                    ? 'cursor-not-allowed text-gray-500'
                                    : 'cursor-pointer text-primary-600 hover:text-sky-800 active:text-sky-950',
                            ]"
                            class="
                                relative
                                cursor-pointer
                                rounded-sm
                                font-medium
                                focus-within:outline-hidden
                                focus-within:ring-2
                                focus-within:ring-sky-700
                                focus-within:ring-offset-2
                                text-center
                            "
                            @click="$emit('change', $event)"
                        >
                            <span>{{ computedUploadText }}</span>
                            <input
                                :id="inputId"
                                :disabled="disabled"
                                type="file"
                                class="sr-only"
                                :accept="accept"
                                :multiple="multiple"
                                :required="props.required"
                                :aria-invalid="errorMessages && errorMessages.length ? true : undefined"
                                :aria-describedby="description ? `${inputId}-info` : undefined"
                                :aria-errormessage="errorMessages && errorMessages.length ? `${inputId}-error` : undefined"
                                @change="$emit('change', $event)"
                            />
                        </label>
                        <!-- TODO enable drag n drop ? -->
                        <!-- <p class="pl-1">or drag and drop</p> -->
                    </div>
                    <p v-if="description" :id="`${inputId}-info`" class="text-xs text-gray-500">{{ description }}</p>
                </div>
            </div>
            <div v-if="url && !loading" class="flex flex-col gap-2 justify-between rounded-md border p-3 bg-white border-gray-300">
                <div class="h-full bg-no-repeat bg-contain bg-center rounded-md" :style="{backgroundImage: `url('${url}')`}"></div>
                <Button size="Small" variant="Danger-Light" :disabled-explanation="clearDisabledExplanation" @click.prevent="$emit('clear')">
                    <template #icon>
                        <DeleteForeverIcon  class="min-h-[1.25rem] min-w-[1.25rem]"/>
                    </template>
                    {{ clearText }}
                </Button>
            </div>
        </div>
        <p v-if="errorMessages && errorMessages.length" :id="`${inputId}-error`" role="alert" class="mt-2 text-sm text-red-600">
            <div v-for="message in errorMessages" :key="message">{{ message }}</div>
        </p>
    </div>
</template>

<script setup lang="ts">
import { useFluent } from 'fluent-vue';
import { nanoid } from 'nanoid';
import { computed } from 'vue';
import Button from '../Button/Button.vue';
import DeleteForeverIcon from '../Icon/DeleteForeverIcon.vue';
import ProgressSpinner from '../ProgressSpinner.vue';
import LabelComponent from './Label.vue';

type FileUploadProps = {
    label: string;
    url?: string;
    altText?: string;
    description?: string;
    loading?: boolean;
    errorMessages?: string[];
    accept?: string;
    multiple?: boolean;
    uploadText?: string;
    helpButtonText?: string;
    required?: boolean;
    disabled?: boolean;
    clearDisabledExplanation?: string;
    clearButtonText?: string
};
const props = withDefaults(defineProps<FileUploadProps>(), { multiple: false });

type FileUploadEmits = {
    (e: 'change', v: Event): void; 
    (e: 'clear'): void
}
defineEmits<FileUploadEmits>();

const inputId = nanoid();
// FIXME translate uploadText, problems with storybook when using fluent here
// FIXME add nicer error styling
const fluent = useFluent()
const clearText = computed(() => props.clearButtonText ?? fluent.$t('delete-icon'))
const computedUploadText = computed(() => props.uploadText ?? fluent.$t('upload-file'))
</script>
