<template>
    <base-form id="login-form" ref="form" @submit.prevent="onLogin">
        <div class="flex flex-col">
            <EmailField :value="login.email" :label="$t('email')" :error-messages="emailErrors" @change="emailChange" />
            <PasswordField
                :value="login.password"
                :label="$t('password')"
                :error-messages="passwordErrors"
                @input="passwordChange"
            />
        </div>
        <div>
            <div class="my-4">
                <slot name="messages"></slot>
            </div>
        </div>
        <button-row>
            <base-button variant="text"> {{ $t('reset_password') }} </base-button>
            <spacer />

            <Button size="Base" variant="Info-Strong" type="submit">
                <template #icon>
                    <LoginIcon />
                </template>

                {{ $t('login') }}
            </Button>
        </button-row>
    </base-form>
</template>

<script setup lang="ts">
import BaseButton from '@/components/BaseButton.vue';
import BaseForm from '@/components/BaseForm.vue';
import Button from '@/components/Button/Button.vue';
import ButtonRow from '@/components/ButtonRow.vue';
import EmailField from '@/components/Form/EmailField.vue';
import PasswordField from '@/components/Form/PasswordField.vue';
import LoginIcon from '@/components/Icon/LoginIcon.vue';
import Spacer from '@/components/Spacer.vue';
import { useFluent } from 'fluent-vue';
import { ref } from 'vue';
import { z } from 'zod';
import { LoginCredentials } from '../auth';

// TODO what happens in loading state?
withDefaults(
    defineProps<{
        loading: boolean;
    }>(),
    { loading: true },
);

// TODO handle reset password
const emit = defineEmits<{
    (e: 'login', credentials: LoginCredentials): void;
    (e: 'reset-password'): void;
}>();

const form = ref();
const fluent = useFluent();
const login = ref({ email: '', password: '' });

const emailErrors = ref<string[]>();
const passwordErrors = ref<string[]>();

const LoginSchema = z.object({
    email: z.string().email(fluent.$t('email-invalid')),
    password: z.string(),
});

const emailChange = (e: Event) => {
    emailErrors.value = undefined;
    const target = e.target as HTMLInputElement;
    const value = target.value;
    login.value.email = value;
    const parse = LoginSchema.shape.email.safeParse(value);
    if (!parse.success) {
        emailErrors.value = parse.error.format()._errors;
    }
};

const passwordChange = (e: Event) => {
    passwordErrors.value = undefined;
    const target = e.target as HTMLInputElement;
    const value = target.value;
    login.value.password = value;
    const parse = LoginSchema.shape.password.safeParse(value);
    if (!parse.success) {
        passwordErrors.value = parse.error.format()._errors;
    }
};

function onLogin(): void {
    const parse = LoginSchema.safeParse(login.value);
    if (!parse.success) {
        const flatErrors = parse.error.flatten().fieldErrors;
        emailErrors.value = flatErrors.email;
        passwordErrors.value = flatErrors.password;
        return;
    }
    emit('login', login.value);
}
</script>
