import { onMounted, onUnmounted, ref } from 'vue';

export function useTimer(intervallMillis: number, callback: () => object, callNow = true): void {
    const handle = ref();
    onMounted(() => {
        handle.value = setInterval(callback, intervallMillis);
        if (callNow) callback();
    });

    onUnmounted(() => clearInterval(handle.value));
}
