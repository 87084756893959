<template>
    <div @keydown="keydownWrapper">
        <label :for="inputId" class="flex flex-row items-center gap-1 text-sm font-medium text-gray-700">
            {{ props.label }}
            <HelpButton v-if="props.helpButtonText" :text="props.helpButtonText" />
        </label>

        <div ref="triggerRef" class="flex flex-row mt-1" :class="[undoBackgroundStyles]">
            <button
                :id="inputId"
                ref="displayRef"
                aria-haspopup="dialog"
                class="grid grid-cols-[minmax(0,1fr)_min-content] items-center gap-2 border rounded-md h-[2.5rem] focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-offset-1 px-2 w-full"
                :class="[statusStyles, focusStatusStyles]"
                :aria-controls="paneId"
                :aria-expanded="isExpanded"
                type="button"
                @click="toggleReveal"
                @keydown="keydownTriggerButton"
            >
                <div class="flex flex-col text-left font-light whitespace-nowrap overflow-hidden">
                    <slot name="display" />
                </div>

                <TriangleIcon class="transition-transform" :class="[isExpanded ? '-rotate-90' : 'rotate-90']" />
            </button>

            <Tooltip>
                <template #trigger="tooltipProps">
                    <button
                        v-if="hasUndoButton"
                        v-bind="tooltipProps"
                        class="flex flex-row items-center gap-1 w-max text-base p-[0.5625rem] rounded-md"
                        :class="[undoButtonStyles, undoButtonFocusStyles]"
                        type="button"
                        @click="(event: MouseEvent) => emit('click', event)"
                    >
                        <UndoIcon class="w-4 h-4" />
                    </button>
                </template>

                <template #text>
                    <div class="w-max">{{ $t('delete-filter') }}</div>
                </template>
            </Tooltip>

            <div
                v-if="isExpanded"
                :id="paneId"
                ref="contentRef"
                class="grid grid-cols-[1fr] w-full rounded-md bg-gray-50 text-gray-800 ring-1 ring-gray-300 z-30 overflow-y-auto"
                :style="floatingStyles"
                role="dialog"
            >
                <slot name="panel" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { autoUpdate, flip, offset, size, useFloating } from '@floating-ui/vue';
import { onClickOutside } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { nanoid } from 'nanoid';
import { computed, nextTick, ref } from 'vue';
import HelpButton from '../HelpButton/HelpButton.vue';
import TriangleIcon from '../Icon/TriangleIcon.vue';
import UndoIcon from '../Icon/UndoIcon.vue';
import Tooltip from '../Tooltip/Tooltip.vue';
import { DropDownStatus } from './types';

type TwoLineDropDownProps = {
    status: DropDownStatus;
    label: string;
    helpButtonText?: string;
};

type ButtonEmits = {
    (e: 'click', ev: MouseEvent): void;
};

const props = defineProps<TwoLineDropDownProps>();
const emit = defineEmits<ButtonEmits>();

const inputId = nanoid();
const paneId = nanoid();

const isExpanded = ref(false);

const triggerRef = ref(null);
const contentRef = ref(null);
const displayRef = ref(null);
const { floatingStyles } = useFloating(triggerRef, contentRef, {
    open: isExpanded.value,
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware: [
        offset(4),
        flip(),
        size({
            apply({ elements, rects, availableHeight, availableWidth }) {
                Object.assign(elements.floating.style, {
                    width: `${availableWidth - 16}px`,
                    minWidth: `${rects.reference.width}px`,
                    maxWidth: '40rem',
                    maxHeight: `${availableHeight - 16}px`,
                });
            },
        }),
    ],
});

const hasUndoButton = computed(
    () => props.status === 'Active' || props.status === 'Warning' || props.status === 'Error',
);

const undoButtonStyles = computed(() => {
    switch (props.status) {
        case 'Default':
            return undefined;

        case 'Active':
            return `
                border border-sky-700 bg-sky-700 text-sky-50
                hover:border-sky-800 hover:bg-sky-800 hover:text-sky-50
                active:border-sky-900 active:bg-sky-900 active:text-sky-50
            `;

        case 'Warning':
            return `
                border border-yellow-700 bg-yellow-700 text-yellow-50
                hover:border-yellow-800 hover:bg-yellow-800 hover:text-yellow-50
                active:border-yellow-900 active:bg-yellow-900 active:text-yellow-50
            `;

        case 'Error':
            return `
                border border-red-700 bg-red-700 text-red-50
                hover:border-red-800 hover:bg-red-800 hover:text-red-50
                active:border-red-900 active:bg-red-900 active:text-red-50
            `;
    }
});

const undoButtonFocusStyles = computed(() => {
    switch (props.status) {
        case 'Default':
            return undefined;

        case 'Active':
            return 'focus-visible:outline-hidden focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-offset-1 focus-visible:ring-sky-100 focus-visible:ring-offset-transparent';

        case 'Warning':
            return 'focus-visible:outline-hidden focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-offset-1 focus-visible:ring-yellow-100 focus-visible:ring-offset-transparent';

        case 'Error':
            return 'focus-visible:outline-hidden focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-offset-1 focus-visible:ring-red-100 focus-visible:ring-offset-transparent';
    }
});

const statusStyles = computed(() => {
    switch (props.status) {
        case 'Default':
            return 'bg-gray-50 text-gray-500 border-gray-300 hover:bg-gray-200 hover:text-gray-700 hover:border-gray-400 active:bg-gray-50 active:border-gray-500';

        case 'Active':
            return 'bg-sky-100 text-sky-700 border-sky-700 hover:bg-sky-200 hover:text-sky-900 hover:border-sky-700 active:bg-sky-100 active:text-sky-700 active:border-sky-900';

        case 'Warning':
            return 'bg-yellow-100 text-yellow-700 border-yellow-700 hover:bg-yellow-200 hover:text-yellow-900 hover:border-yellow-700 active:bg-yellow-100 active:text-yellow-700 active:border-yellow-900';

        case 'Error':
            return 'bg-red-100 text-red-700 border-red-700 hover:bg-red-200 hover:text-red-900 hover:border-red-700 active:bg-red-100 active:text-red-700 active:border-red-900';
    }
});

const undoBackgroundStyles = computed(() => {
    switch (props.status) {
        case 'Default':
            return undefined;

        case 'Active':
            return 'rounded-md bg-sky-700 text-sky-100';

        case 'Warning':
            return 'rounded-md bg-yellow-700 text-yellow-100';

        case 'Error':
            return 'rounded-md bg-red-700 text-red-100';
    }
});

const focusStatusStyles = computed(() => {
    switch (props.status) {
        case 'Default':
            return 'focus-visible:ring-gray-700 focus-visible:ring-offset-gray-100';

        case 'Active':
            return 'focus-visible:ring-sky-700 focus-visible:ring-offset-sky-100';

        case 'Warning':
            return 'focus-visible:ring-yellow-700 focus-visible:ring-offset-yellow-100';

        case 'Error':
            return 'focus-visible:ring-red-700 focus-visible:ring-offset-red-100';
    }
});

onClickOutside(contentRef, hide, { ignore: [displayRef] });

function keydownTriggerButton(event: KeyboardEvent) {
    switch (event.key) {
        case 'ArrowDown':
        case 'ArrowUp':
            toggleReveal();
            event.preventDefault();
            break;
    }
}

function keydownWrapper(event: KeyboardEvent) {
    switch (event.key) {
        case 'Escape':
            // This workaround is required to not close the drop down if a combobox component within is closed with Esc (so it basically "stops" the "Esc pressed" event)
            if (event.target && (event.target as HTMLElement).dataset.headlessuiState !== 'open') {
                hide();
                event.preventDefault;
            }
            break;
    }
}

async function toggleReveal() {
    if (isExpanded.value) {
        await hide();
    } else {
        await reveal();
    }
}

async function reveal() {
    isExpanded.value = true;

    await nextTick();

    activate();
}

async function hide() {
    isExpanded.value = false;

    await nextTick();

    deactivate();
}

defineExpose({
    hide,
});

const { activate, deactivate } = useFocusTrap(contentRef, {
    immediate: true,
    clickOutsideDeactivates: true,
    returnFocusOnDeactivate: true,
    allowOutsideClick: true,
});
</script>
