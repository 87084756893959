import { TimelineAreaFragment, TimelineFullSiteFragment, TimelineRoomFragment } from '@/generated/graphql';
import { routeForLocation } from '@/location/location';
import { computed, Ref, toValue, watchEffect } from 'vue';
import { getSectionId } from '../timeline';
import { LocationType, LocationTypeCategory } from '../Timeline.types';

export function useTimelineLocations(
    siteRef: Ref<TimelineFullSiteFragment>,
    roomsRef: Ref<TimelineRoomFragment[]>,
    areasRef: Ref<TimelineAreaFragment[]>,
    showSections: Ref<boolean>,
) {
    const rooms = computed(
        () =>
            toValue(roomsRef).map((room) => ({
                id: room.id,
                label: room.nameShort,
                linkTarget: routeForLocation('Room', room.id),
                level: 0,
                type: room.type?.name,
                typeCategory: 'Room' as LocationTypeCategory,
                isLeafLocation: true,
                sectionId: getSectionId((room.type?.name as LocationType) ?? 'no-room-type'),
                nestedLocations: [],
            })) ?? [],
    );
    const areas = computed(
        () =>
            toValue(areasRef).map((area) => ({
                id: area.id,
                label: area.nameShort,
                linkTarget: routeForLocation('Area', area.id),
                level: 0,
                type: area.type?.name,
                typeCategory: 'Area' as LocationTypeCategory,
                isLeafLocation: true,
                sectionId: getSectionId((area.type?.name as LocationType) ?? 'no-area-type'),
                nestedLocations: [],
            })) ?? [],
    );

    const buildings = computed(
        () =>
            toValue(siteRef).buildings.nodes.map((building) => ({
                id: building.id,
                label: building.nameShort,
                level: 0,
                typeCategory: 'Building' as LocationTypeCategory,
                isLeafLocation: false,
                nestedLocations:
                    building.levels.nodes.map((level) => ({
                        id: level.id,
                        label: level.nameShort,
                        level: 1,
                        typeCategory: 'Floor' as LocationTypeCategory,
                        isLeafLocation: false,
                        nestedLocations:
                            level.rooms.nodes.map((room) => ({
                                id: room.id,
                                label: room.nameShort,
                                level: 2,
                                typeCategory: 'Room' as LocationTypeCategory,
                                isLeafLocation: true,
                                nestedLocations: [],
                            })) ?? [],
                    })) ?? [],
            })) ?? [],
    );

    const zones = computed(
        () =>
            toValue(siteRef).zones.nodes.map((zone) => ({
                id: zone.id,
                label: zone.nameShort,
                level: 0,
                typeCategory: 'Zone' as LocationTypeCategory,
                isLeafLocation: false,
                nestedLocations:
                    zone.areas.nodes.map((area) => ({
                        id: area.id,
                        label: area.nameShort,
                        level: 1,
                        typeCategory: 'Area' as LocationTypeCategory,
                        isLeafLocation: false,
                        nestedLocations:
                            area.plots.nodes.map((plot) => ({
                                id: plot.id,
                                label: plot.nameShort,
                                level: 2,
                                typeCategory: 'Plot' as LocationTypeCategory,
                                isLeafLocation: true,
                                nestedLocations: [],
                            })) ?? [],
                    })) ?? [],
            })) ?? [],
    );

    function getLocations() {
        if (toValue(showSections)) {
            return computed(() => toValue(rooms).concat(toValue(areas)));
        }
        return computed(() => toValue(buildings).concat(toValue(zones)));
    }

    watchEffect(() => getLocations());

    return {
        getLocations,
    };
}
