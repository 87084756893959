<template>
    <div
        class="flex flex-row items-center"
        :class="[
            backgroundColorClass,
            opacityClasses,
            borderClasses,
            formClasses,
            phaseConnectorBarClasses,
            allocationHeightClass,
            columnWidthClass,
            { relative: Boolean(props.specialization || props.hasTemporaryChangeMarker) },
        ]"
        :style="[allocationBorderRadiusStyle, allocationPartColorStyle]"
    >
        <div class="absolute" :class="[textColorClass]" :style="iconPositionStyle">
            <!-- Event icons inside -->
            <ClothesHangerIcon v-if="specializationIcon === 'ClothesHanger'" :class="specializationIconSizeClass" />
            <DoorBackIcon v-if="specializationIcon === 'DoorBack'" :class="specializationIconSizeClass" />
            <GroupIcon v-if="specializationIcon === 'Group'" :class="specializationIconSizeClass" />
            <InfoIcon v-if="specializationIcon === 'Info'" :class="specializationIconSizeClass" />
            <PackageIcon v-if="specializationIcon === 'Package'" :class="specializationIconSizeClass" />
            <ParkingIcon v-if="specializationIcon === 'Parking'" :class="specializationIconSizeClass" />
            <RestaurantIcon v-if="specializationIcon === 'Restaurant'" :class="specializationIconSizeClass" />
            <TruckIcon v-if="specializationIcon === 'Truck'" :class="specializationIconSizeClass" />
            <WalkingIcon v-if="specializationIcon === 'Walking'" :class="specializationIconSizeClass" />

            <!-- Event icons outside -->
            <CenterPointIcon v-if="specializationIcon === 'CenterPoint'" :class="specializationIconSizeClass" />
            <DirectionsRunIcon v-if="specializationIcon === 'DirectionsRun'" :class="specializationIconSizeClass" />
            <StoreFrontIcon v-if="specializationIcon === 'StoreFront'" :class="specializationIconSizeClass" />

            <!-- Project icons -->
            <EngineeringIcon v-if="specializationIcon === 'Engineering'" :class="specializationIconSizeClass" />
            <ConstructionIcon v-if="specializationIcon === 'Construction'" :class="specializationIconSizeClass" />
            <DangerousIcon v-if="specializationIcon === 'Dangerous'" :class="specializationIconSizeClass" />
        </div>

        <div
            v-if="props.hasTemporaryChangeMarker"
            class="size-1.5 absolute bg-red-700 rounded-full shadow-[0_0_0.0625rem_0.0625rem] shadow-red-50/50"
            :style="temporaryChangeMarkerPositionStyle"
        />

        <div
            v-if="props.opacityVariant === 'Outer-Semi-Transparent'"
            class="w-full opacity-100"
            :class="[backgroundColorClass, allocationInnerContainerHeightClass]"
            :style="innerContainerStyle"
        />
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import CenterPointIcon from '../../Icon/CenterPointIcon.vue';
import ClothesHangerIcon from '../../Icon/ClothesHangerIcon.vue';
import ConstructionIcon from '../../Icon/ConstructionIcon.vue';
import DangerousIcon from '../../Icon/DangerousIcon.vue';
import DirectionsRunIcon from '../../Icon/DirectionsRunIcon.vue';
import DoorBackIcon from '../../Icon/DoorBackIcon.vue';
import EngineeringIcon from '../../Icon/EngineeringIcon.vue';
import GroupIcon from '../../Icon/GroupIcon.vue';
import InfoIcon from '../../Icon/InfoIcon.vue';
import PackageIcon from '../../Icon/PackageIcon.vue';
import ParkingIcon from '../../Icon/ParkingIcon.vue';
import RestaurantIcon from '../../Icon/RestaurantIcon.vue';
import StoreFrontIcon from '../../Icon/StoreFrontIcon.vue';
import TruckIcon from '../../Icon/TruckIcon.vue';
import WalkingIcon from '../../Icon/WalkingIcon.vue';
import {
    allocationHeightClass,
    allocationHeightInRem,
    allocationInnerContainerHeightClass,
    allocationInnerContainerHeightInRem,
    columnWidthClass,
    columnWidthInRem,
    specializationIconSizeClass,
    specializationIconSizeInRem,
} from '../sharedStyles';
import { PhaseForm, PhaseType } from '../Timeline.types';
import { getStrongBackgroundColorCustomPropertyForType, getTextColorClassForType } from './shared';
import { mapSpecializationsTypesToIcons, SpecializationType } from './SpecializationMappings';

interface AllocationPartProps {
    type: PhaseType;
    form: PhaseForm;
    isPhaseEnd: boolean;
    variant: 'Solid' | 'Deleted' | 'Inserted';
    opacityVariant: 'Solid' | 'Outer-Semi-Transparent' | 'Semi-Transparent';
    specialization: SpecializationType | null;
    hasTemporaryChangeMarker: boolean;
}

const props = defineProps<AllocationPartProps>();

const allocationPartColorStyle = computed(() => {
    return {
        '--color-allocation-part': `var(${getStrongBackgroundColorCustomPropertyForType(props.type)})`,
    };
});

const backgroundColorClass = computed(() => {
    switch (props.variant) {
        case 'Solid':
            return 'bg-(--color-allocation-part)';

        case 'Deleted':
        case 'Inserted':
            return 'bg-(--color-allocation-part)/50';

        default:
            throw new Error(`Unknown variant ${props.variant}`);
    }
});

const textColorClass = computed(() => getTextColorClassForType(props.type));

const opacityClasses = computed(() => {
    switch (props.opacityVariant) {
        case 'Solid':
            return '';

        case 'Semi-Transparent':
        case 'Outer-Semi-Transparent':
            return 'bg-(--color-allocation-part)/50 after:bg-(--color-allocation-part)/50';
    }
});

const borderClasses = computed(() => {
    if (props.variant === 'Deleted') {
        switch (props.form) {
            case 'Start':
                return 'before:rounded-l-full border-inside border-inside-extended-width border-inside-left border-inside-top border-inside-bottom';

            case 'Middle':
                return 'border-inside border-inside-top border-inside-extended-width border-inside-bottom';

            case 'End':
                return 'before:rounded-r-full border-inside border-inside-normal-width border-inside-top border-inside-right border-inside-bottom';

            case 'Single':
                return 'before:rounded-full border-inside border-inside-normal-width border-inside-left border-inside-top border-inside-right border-inside-bottom';

            default:
                throw new Error(`Unknown form: ${props.form}`);
        }
    }
});

const formClasses = computed(() => {
    switch (props.form) {
        case 'Start':
            return 'rounded-l-full';

        case 'Middle':
            return '';

        case 'End':
            return 'rounded-r-full';

        case 'Single':
            return 'rounded-full';

        default:
            throw new Error(`Unknown form: ${props.form}`);
    }
});

const phaseConnectorBarClasses = computed(() => {
    if (!props.isPhaseEnd && props.form !== 'End' && props.form !== 'Single') {
        if (props.opacityVariant === 'Outer-Semi-Transparent') {
            return `after:bg-(--color-allocation-part) phase-connector-bar before:bg-(--color-allocation-part) phase-connector-bar-inner`;
        }

        switch (props.variant) {
            case 'Solid':
                return `after:bg-(--color-allocation-part) phase-connector-bar`;

            case 'Deleted':
            case 'Inserted':
                return `after:bg-(--color-allocation-part)/50 phase-connector-bar`;

            default:
                throw new Error(`Unknown variant ${props.variant}`);
        }
    }
});

const specializationIcon = computed(() => mapSpecializationsTypesToIcons(props.specialization));

const innerContainerStyle = computed(() => {
    if (props.form === 'Single') {
        return `
            margin-left: ${(allocationHeightInRem - allocationInnerContainerHeightInRem) / 2}rem;
            margin-right: ${(allocationHeightInRem - allocationInnerContainerHeightInRem) / 2}rem;
            border-top-left-radius: ${allocationInnerContainerHeightInRem / 2}rem;
            border-bottom-left-radius: ${allocationInnerContainerHeightInRem / 2}rem;
            border-top-right-radius: ${allocationInnerContainerHeightInRem / 2}rem;
            border-bottom-right-radius: ${allocationInnerContainerHeightInRem / 2}rem;
        `;
    }

    if (props.form === 'Start') {
        return `
            margin-left: ${(allocationHeightInRem - allocationInnerContainerHeightInRem) / 2}rem;
            border-top-left-radius: ${allocationInnerContainerHeightInRem / 2}rem;
            border-bottom-left-radius: ${allocationInnerContainerHeightInRem / 2}rem;
        `;
    }

    if (props.form === 'End') {
        return `
            margin-right: ${(allocationHeightInRem - allocationInnerContainerHeightInRem) / 2}rem;
            border-top-right-radius: ${allocationInnerContainerHeightInRem / 2}rem;
            border-bottom-right-radius: ${allocationInnerContainerHeightInRem / 2}rem;
        `;
    }
});

const iconPositionStyle = computed(() => {
    return `
        top: ${(allocationHeightInRem - specializationIconSizeInRem) / 2}rem;
        left: ${(columnWidthInRem - specializationIconSizeInRem) / 2}rem;
    `;
});

const temporaryChangeMarkerPositionStyle = computed(() => {
    // Top: Half height of temporary change marker
    // Right: Top-right corner of the icon box
    return `
        top: 0.1875rem;
        right: ${(columnWidthInRem - specializationIconSizeInRem) / 2 - 0.375 / 2}rem;
    `;
});

const allocationBorderRadiusStyle = computed(() => {
    switch (props.form) {
        case 'Start':
            return 'border-inside border-inside-extended-width border-inside-left border-inside-top border-inside-bottom';

        case 'Middle':
            return 'border-inside border-inside-top border-inside-extended-width border-inside-bottom';

        case 'End':
            return 'before:rounded-r-full border-inside border-inside-normal-width border-inside-top border-inside-right border-inside-bottom';

        case 'Single':
            return 'before:rounded-full border-inside border-inside-normal-width border-inside-left border-inside-top border-inside-right border-inside-bottom';

        default:
            throw new Error(`Unknown form: ${props.form}`);
    }
});

const phaseConnectorBarInnerHeight = computed(() => {
    return `${allocationInnerContainerHeightInRem}rem`;
});

const phaseConnectorBarInnerTop = computed(() => {
    return `${(allocationHeightInRem - allocationInnerContainerHeightInRem) / 2}rem`;
});
</script>

<style scoped>
/**
    Before pseudo element: Inner bar
    After pseudo element: Outer bar
*/
.border-inside,
.phase-connector-bar,
.phase-connector-bar-inner {
    position: relative;
}

.border-inside::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 10;
}

.border-inside-normal-width::before {
    width: 100%;
}

.border-inside-extended-width::before {
    width: calc(100% + 1px);
}

.border-inside-left::before {
    border-left: 1px dashed var(--color-red-700);
}

.border-inside-top::before {
    border-top: 1px dashed var(--color-red-700);
}

.border-inside-right::before {
    border-right: 1px dashed var(--color-red-700);
}

.border-inside-bottom::before {
    border-bottom: 1px dashed var(--color-red-700);
}

.phase-connector-bar::after {
    position: absolute;
    content: '';
    right: -1px;
    width: 1px;
    height: 100%;
    top: 0;
}

.phase-connector-bar-inner::before {
    position: absolute;
    content: '';
    right: -1px;
    width: 1px;
    height: v-bind('phaseConnectorBarInnerHeight');
    top: v-bind('phaseConnectorBarInnerTop');
}
</style>
