import { ComboboxItem } from '@/components/Form/ComboboxField.vue';
import { TVField } from './types';

export const getTVValue = (field: TVField | undefined) => {
    if (!field) return '';
    if (field.nodes.length) {
        return field.nodes[0].value;
    }
    return '';
};

export const getSelectValue = (field: TVField, choices: ComboboxItem[]) => {
    if (field.nodes.length) {
        return choices.find((c) => c.id === field.nodes[0].value)?.name;
    }
    return '';
};
