<template>
    <div ref="contentRef" class="flex flex-col gap-4 min-w-60">
        <ComboboxField :disabled="true" :value="null" :items="[]" label="Phasentyp TODO" />

        <div v-if="props.specializations.length > 0">
            <div
                :aria-labelledby="labelId"
                class="flex flex-col has-focus-visible:ring-2 has-focus-visible:ring-offset-2 has-focus-visible:rounded-xs"
                :class="focusWithinClass"
                role="radiogroup"
                @keydown="onKeyDown"
            >
                <label :id="labelId" class="block text-sm font-medium text-gray-700">
                    {{ $t('specialization-type-label') }}
                </label>

                <div class="flex flex-row gap-1 mt-1">
                    <ChangeTypePopupIcon
                        v-for="specialization of props.specializations"
                        :key="specialization ?? `${id}-No-Specialization`"
                        role="radio"
                        :specialization="specialization"
                        :phase-type="props.phaseType"
                        :is-active="specialization === focusedSpecialization"
                        :aria-checked="specialization === props.selectedSpecialization"
                        :tabindex="specialization === props.selectedSpecialization ? 0 : -1"
                        @click="emit('change-specialization', specialization)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import ComboboxField from '@/components/Form/ComboboxField.vue';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { nanoid } from 'nanoid';
import { computed, ref } from 'vue';
import { getFocusWithinRingColorClassForType } from '../allocation/shared';
import { SpecializationType } from '../allocation/SpecializationMappings';
import { PhaseType } from '../Timeline.types';
import ChangeTypePopupIcon from './ChangeTypePopupIcon.vue';

const id = nanoid();
const labelId = `label-${id}`;

type ChangeTypePopupIconProps = {
    phaseType: PhaseType;
    specializations: (SpecializationType | null)[];
    selectedSpecialization: SpecializationType | null;
};

const props = defineProps<ChangeTypePopupIconProps>();

const emit = defineEmits<{
    (e: 'change-specialization', specialization: SpecializationType | null): void;
    (e: 'close-popup'): void;
}>();

const contentRef = ref(null);
useFocusTrap(contentRef, {
    immediate: props.phaseType === 'Durchführung' || props.phaseType === 'Projekt', // TODO Set this to true once changing the phase type is possible. Otherwise this would lead to an error as the focus-trap contains no focusable elements
    clickOutsideDeactivates: true,
    returnFocusOnDeactivate: true,
    allowOutsideClick: true,
});

const focusWithinClass = computed(() => {
    return getFocusWithinRingColorClassForType(props.phaseType);
});

const focusedSpecialization = ref(props.selectedSpecialization);

function moveFocusLeft() {
    const currentPosition = props.specializations.findIndex(
        (specialization) => specialization === focusedSpecialization.value,
    );

    if (currentPosition === -1) {
        throw new Error(`Unexpected focused specialization: ${focusedSpecialization.value}`);
    }

    if (currentPosition > 0) {
        focusedSpecialization.value = props.specializations[currentPosition - 1];
    }
}

function moveFocusRight() {
    const currentPosition = props.specializations.findIndex(
        (specialization) => specialization === focusedSpecialization.value,
    );

    if (currentPosition === -1) {
        throw new Error(`Unexpected focused specialization: ${focusedSpecialization.value}`);
    }

    if (currentPosition < props.specializations.length - 1) {
        focusedSpecialization.value = props.specializations[currentPosition + 1];
    }
}

function onKeyDown(event: KeyboardEvent) {
    switch (event.key) {
        case 'ArrowLeft':
            moveFocusLeft();
            break;

        case 'ArrowRight':
            moveFocusRight();
            break;

        case ' ':
        case 'Enter':
            emit('change-specialization', focusedSpecialization.value);
            break;

        case 'Escape':
            emit('close-popup');
            break;
    }
}
</script>
