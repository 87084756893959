<!-- style based on https://tailwindui.com/components/application-ui/forms/input-groups#component-04a268fee12196d74c94e82a43106f8d -->
<template>
    <div>
        <Label
            :label="props.label"
            :input-id="inputId"
            :help-button-text="props.helpButtonText"
            :is-required="props.required"
        />
        <div class="mt-1">
            <textarea
                :id="inputId"
                :value="value"
                :disabled="disabled"
                :minLength="minLength"
                :maxLength="maxLength"
                :rows="rows"
                :cols="cols"
                :class="styling"
                :required="required"
                :placeholder="placeholder"
                :aria-invalid="errorMessages && errorMessages.length ? true : undefined"
                :aria-describedby="description ? `${inputId}-error` : undefined"
                :aria-errormessage="errorMessages && errorMessages.length ? `${inputId}-error` : undefined"
                @input="$emit('input', $event)"
                @change="$emit('change', $event)"
                @blur="$emit('blur', $event)"
            />
            <!-- Where do we put the exclamation Icon? -->
            <!-- <div v-if="errorMessage" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
            </div> -->
        </div>
        <p
            v-if="description"
            :id="`${inputId}-info`"
            :class="[props.status === 'overridden' ? 'mt-2 text-sm text-indigo-600' : 'mt-2 text-sm text-gray-500']"
        >
            {{ description }}
        </p>
        <div
            v-if="errorMessages && errorMessages.length"
            :id="`${inputId}-error`"
            role="alert"
            class="mt-2 text-sm text-red-600"
        >
            <p v-for="message in errorMessages" :key="message">{{ message }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid';
import { computed } from 'vue';
import { NumberSchema, StringSchema } from 'yup';
import Label from './Label.vue';

type TextAreaFieldProps = {
    value: string;
    label: string;
    disabled?: boolean;
    description?: string;
    helpButtonText?: string;
    rows?: number;
    cols?: number;
    minLength?: number;
    maxLength?: number;
    required?: boolean;
    validator?: StringSchema | NumberSchema;
    autocomplete?: string;
    errorMessages?: string[];
    placeholder?: string;
    status?: 'overridden';
};
const props = withDefaults(defineProps<TextAreaFieldProps>(), { disabled: false, autocomplete: 'off', rows: 10 });

type TextAreaFieldEmits = {
    (e: 'input', v: Event): void;
    (e: 'change', v: Event): void;
    (e: 'blur', v: Event): void;
};
defineEmits<TextAreaFieldEmits>();

const inputId = nanoid();
const styling = computed(() => {
    return [
        'block',
        'w-full',
        'rounded-md',
        'shadow-xs',
        'sm:text-sm',
        'disabled:cursor-not-allowed',
        'disabled:border-gray-200',
        'disabled:bg-gray-50',
        'disabled:text-gray-500',
        props.errorMessages && props.errorMessages.length
            ? 'focus:outline-hidden border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
            : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
        {
            'focus:outline-hidden border-blue-600 text-blue-600 focus:ring-blue-600 focus:border-blue-600':
                props.status === 'overridden',
        },
    ];
});
</script>
