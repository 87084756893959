<template>
    <div
        class="pb-1 pl-2 text-base text-left font-bold uppercase text-gray-600 whitespace-nowrap tracking-wider leading-none header-month-marker-gray-300"
        :style="gridColumn"
    >
        <div class="overflow-x-clip">
            {{ props.label }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type HeaderMonthProps = {
    daysCount: number;
    label: string;
    startColumnIndex: number;
};

const props = defineProps<HeaderMonthProps>();

const gridColumn = computed(() => `grid-column: ${props.startColumnIndex} / span ${props.daysCount}`);
</script>

<style scoped>
.header-month-marker-gray-300 {
    position: relative;
}

.header-month-marker-gray-300::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2.375rem;
    bottom: -1.125rem;
    left: -0.09375rem; /* 1.5px */
    border-left: 2px solid var(--color-gray-300);
    z-index: 20;
    pointer-events: none;
}
</style>
