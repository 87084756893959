import { ActiveCard } from '@/components/Card/types';
import {
    GetEventVariantsQuery,
    GetEventVariantsQueryVariables,
    GetProjectVariantsQuery,
    GetProjectVariantsQueryVariables,
    SetCurrentEventVariantMutation,
    SetCurrentEventVariantMutationVariables,
    SetCurrentProjectVariantMutation,
} from '@/generated/graphql';
import { VariantCardVariant, VariantStatus } from '@/variant/types';
import { TypedDocumentNode } from '@apollo/client';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { computed, Ref, ref, toValue } from 'vue';
import { VariantCardExpose } from '../components/VariantCard.vue';

export type ProjectVariantsQuery = TypedDocumentNode<GetProjectVariantsQuery, GetProjectVariantsQueryVariables>;
export type EventVariantsQuery = TypedDocumentNode<GetEventVariantsQuery, GetEventVariantsQueryVariables>;
export type ProjectSetCurrentVariantMutation = TypedDocumentNode<
    SetCurrentProjectVariantMutation,
    SetCurrentEventVariantMutationVariables
>;
export type EventSetCurrentVariantMutation = TypedDocumentNode<
    SetCurrentEventVariantMutation,
    SetCurrentEventVariantMutationVariables
>;

export function useVariants(
    objectId: Ref<string>,
    variantCardCompositionRef: Ref<VariantCardExpose[]>,
    getVariantsQuery: ProjectVariantsQuery | EventVariantsQuery,
    setCurrentVariantMutationDocument: ProjectSetCurrentVariantMutation | EventSetCurrentVariantMutation,
) {
    const variantsRef: Ref<VariantCardVariant[]> = ref([]);
    const activeCard: Ref<ActiveCard> = ref({ id: '', label: '', status: 'View' });
    const expandedCards: Ref<string[]> = ref([]);

    const setCurrentVariantMutation = useMutation(setCurrentVariantMutationDocument);

    const { loading, error, onResult } = useQuery(
        getVariantsQuery,
        computed(() => ({ id: toValue(objectId) })),
    );

    onResult((result) => {
        if (result.error) {
            throw new Error('Error on receiving project variants and comments');
        }
        const data = result.data;
        if (data) {
            const variants = data.variants?.nodes;
            if (variants) {
                variantsRef.value = variants.map((variant) => ({
                    ...variant,
                    status: variant.status as VariantStatus,
                }));
            }
        }
    });

    const currentVariant = computed(() => {
        if (variantsRef.value) {
            const variant = toValue(variantsRef).find((v) => v.isCurrent);
            if (!variant) throw new Error('There must be at least one current variant!');
            return variant;
        }
    });

    function setVariantsRef(value: VariantCardVariant[]) {
        variantsRef.value = value;
    }

    function setActiveCard(value: ActiveCard) {
        activeCard.value = value;
    }

    function setExpandedCards(value: string[]) {
        expandedCards.value = value;
    }

    async function setCurrentVariant(variantId: string) {
        const newVariant = variantsRef.value.find((v) => v.id === variantId);
        if (!newVariant) throw new Error('New current variant does not exist');

        if (currentVariant.value) {
            const result = await setCurrentVariantMutation.mutate({
                oldCurrentId: currentVariant.value.id,
                newCurrentId: newVariant.id,
            });

            if (result?.errors) throw new Error('Could not set variant to current variant');

            const oldVariantResult = result?.data?.updateOld?.variant;
            const newVariantResult = result?.data?.updateNew?.variant;

            if (oldVariantResult && newVariantResult) {
                setVariantsRef(
                    toValue(variantsRef).map((variant) => {
                        if (variant.id === oldVariantResult.id) {
                            return {
                                ...oldVariantResult,
                                status: oldVariantResult.status as VariantStatus,
                            };
                        }
                        if (variant.id === newVariant.id) {
                            return {
                                ...newVariantResult,
                                status: newVariantResult.status as VariantStatus,
                            };
                        }
                        return variant;
                    }),
                );
                // call child functions of the VariantCards that are changed
                const exposeOldVariant = variantCardCompositionRef.value.find((c) => c.id === oldVariantResult.id);
                exposeOldVariant?.createNewMainVariantEntry(
                    oldVariantResult.id,
                    oldVariantResult.index,
                    newVariantResult.id,
                    newVariantResult.index,
                );
                const exposeNewVariant = variantCardCompositionRef.value.find((c) => c.id === newVariantResult.id);
                exposeNewVariant?.createNewMainVariantEntry(
                    oldVariantResult.id,
                    oldVariantResult.index,
                    newVariantResult.id,
                    newVariantResult.index,
                );
            }
        }
    }

    return {
        variantsRef,
        currentVariant,
        activeCard,
        expandedCards,
        loading,
        error,
        setVariantsRef,
        setActiveCard,
        setExpandedCards,
        setCurrentVariant,
    };
}
