<template>
    <button
        type="button"
        class="w-full h-[1.625rem]"
        :class="[backgroundClass, borderClasses, textColorClass]"
        @click="$emit('toggle-section-expanded')"
    >
        <span class="sr-only">
            {{ props.label }}
        </span>
    </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { SectionStatus } from '../TimelineView.types';

type CellPlaceholderProps = {
    label: string;
    daysCount: number;
    isHovered: boolean;
    status: SectionStatus;
};

const props = defineProps<CellPlaceholderProps>();

defineEmits<{
    (e: 'toggle-section-expanded'): void;
}>();

const backgroundClass = computed(() => {
    if (props.isHovered) {
        if (props.status === 'has-conflicts') {
            return 'bg-red-600';
        }

        return 'bg-gray-600';
    }

    if (props.status === 'has-conflicts') {
        return 'bg-red-100';
    }

    return 'bg-gray-200';
});

const borderClasses = computed(() => {
    if (props.isHovered) {
        if (props.status === 'has-conflicts') {
            return 'cell-placeholder-border-top cell-placeholder-border-top-red-800 cell-placeholder-border-top-z-30 cell-placeholder-border-bottom cell-placeholder-border-bottom-red-800 cell-placeholder-border-bottom-z-30';
        }

        return 'cell-placeholder-border-top cell-placeholder-border-top-gray-600 cell-placeholder-border-top-z-30 cell-placeholder-border-bottom cell-placeholder-border-bottom-gray-600 cell-placeholder-border-bottom-z-30';
    }

    if (props.status === 'has-conflicts') {
        return 'cell-placeholder-border-top cell-placeholder-border-top-red-200 cell-placeholder-border-top-z-30 cell-placeholder-border-bottom cell-placeholder-border-bottom-red-200 cell-placeholder-border-bottom-z-30';
    }

    return 'cell-placeholder-border-top cell-placeholder-border-top-gray-300 cell-placeholder-border-top-z-10 cell-placeholder-border-bottom cell-placeholder-border-bottom-gray-300 cell-placeholder-border-bottom-z-10';
});

const textColorClass = computed(() => {
    if (props.isHovered) {
        if (props.status === 'has-conflicts') {
            return 'text-white';
        }

        return 'text-gray-50';
    }

    return 'text-gray-900';
});
</script>

<style scoped>
.cell-placeholder-border-top,
.cell-placeholder-border-bottom {
    position: relative;
}

.cell-placeholder-border-top::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
}

.cell-placeholder-border-top-z-10::before {
    z-index: 10;
}

.cell-placeholder-border-top-z-30::before {
    z-index: 30;
}

.cell-placeholder-border-top-gray-300::before {
    background-color: var(--color-gray-300);
}

.cell-placeholder-border-top-gray-600::before {
    background-color: var(--color-gray-600);
}

.cell-placeholder-border-top-red-200::before {
    background-color: var(--color-red-200);
}

.cell-placeholder-border-top-red-800::before {
    background-color: var(--color-red-800);
}

.cell-placeholder-border-bottom::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
}

.cell-placeholder-border-bottom-z-10::after {
    z-index: 10;
}

.cell-placeholder-border-bottom-z-30::after {
    z-index: 30;
}

.cell-placeholder-border-bottom-gray-300::after {
    background-color: var(--color-gray-300);
}

.cell-placeholder-border-bottom-gray-600::after {
    background-color: var(--color-gray-600);
}

.cell-placeholder-border-bottom-red-200::after {
    background-color: var(--color-red-200);
}

.cell-placeholder-border-bottom-red-800::after {
    background-color: var(--color-red-800);
}
</style>
