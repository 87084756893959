<template>
    <div class="w-[15.5rem]">
        <TwoLineDropDown
            ref="variantDropDown"
            :status="dropDownStatus.status"
            :label="$t('allocation-variants')"
            @click="onDeleteFilter"
        >
            <template #display>
                <div v-if="dropDownStatus.display === 'all-visible'">
                    {{ `(${$t('all-active')})` }}
                </div>
                <div
                    v-if="dropDownStatus.display === 'all-hidden'"
                    class="grid grid-cols-[min-content_1fr] items-center gap-1.5"
                >
                    <WarningIcon />
                    {{ $t('all-hidden') }}
                </div>

                <div
                    v-if="dropDownStatus.status === 'Warning'"
                    class="grid grid-cols-[min-content_1fr] items-center gap-1.5"
                >
                    <WarningIcon />

                    <div class="flex flex-col items-start text-sm leading-tight gap-px font-light text-gray-500">
                        <i18n path="variant-dropdown-active-visible">
                            <template #numVisibleVariants>
                                <span class="font-medium text-gray-700">{{ visibleVariants.length }}</span>
                            </template>
                            <template #numAllVariants>{{ variantsRef.length }}</template>
                        </i18n>

                        <i18n
                            v-if="highlightedVariant"
                            path="variant-dropdown-active-highlighted"
                            class="flex flex-row items-center"
                        >
                            <template #index>
                                <span>
                                    BV<span class="font-medium text-gray-700"
                                        >{{ String(highlightedVariant.index).padStart(3, '0') }}
                                    </span>
                                </span>
                            </template>
                        </i18n>
                    </div>
                </div>

                <div
                    v-if="dropDownStatus.display === 'highlighted'"
                    class="flex flex-col items-start text-sm leading-tight gap-px font-light text-gray-500"
                >
                    <i18n path="variant-dropdown-active-visible">
                        <template #numVisibleVariants>
                            <span class="font-medium text-gray-700">{{ visibleVariants.length }}</span>
                        </template>
                        <template #numAllVariants>{{ variantsRef.length }}</template>
                    </i18n>

                    <i18n
                        v-if="highlightedVariant"
                        path="variant-dropdown-active-highlighted"
                        class="flex flex-row items-center"
                    >
                        <template #index>
                            <span>
                                BV<span class="font-medium text-gray-700"
                                    >{{ String(highlightedVariant.index).padStart(3, '0') }}
                                </span>
                            </span>
                        </template>
                    </i18n>
                </div>
            </template>
            <template #panel>
                <AllocationVariantSelectionPanel
                    :object-id="objectId"
                    :allocation-type="allocationType"
                    :active-id="highlightedVariantId"
                    name="variant-selection"
                    :entries="selectionPanelVariants"
                    :current-variant="mappedCurrentVariant"
                    :new-variant-index="newVariantIndex"
                    @create-new-variant="onCreateNewVariant"
                    @change-highlight="setHighlightedVariantId"
                    @change-visibility="onChangeVisibility"
                    @close="onDropDownClose"
                    @copy-allocations="onCopyAllocations"
                />
            </template>
        </TwoLineDropDown>
    </div>
</template>

<script setup lang="ts">
import AllocationVariantSelectionPanel from '@/components/AllocationVariantSelectionPanel/AllocationVariantSelectionPanel.vue';
import { useCopyAllocations } from '@/components/AllocationVariantSelectionPanel/composables/useCopyAllocations';
import { useCreateNewVariant } from '@/components/AllocationVariantSelectionPanel/composables/useCreateNewVariant';
import { SelectionPanelEntries } from '@/components/AllocationVariantSelectionPanel/types';
import WarningIcon from '@/components/Icon/WarningIcon.vue';
import { TimelineVariant } from '@/components/Timeline/allocation/types';
import { AllocationType } from '@/components/Timeline/Timeline.types';
import TwoLineDropDown from '@/components/TwoLineDropDown/TwoLineDropDown.vue';
import { DropDownStatus } from '@/components/TwoLineDropDown/types';
import { computed, ref } from 'vue';

const variantDropDown = ref();

type VariantSelectionDropDownProps = {
    dropDownStatus: { status: DropDownStatus; display: string };
    variantsRef: TimelineVariant[];
    visibleVariants: string[];
    selectionPanelVariants: SelectionPanelEntries;
    highlightedVariant?: TimelineVariant | undefined;
    highlightedVariantId: string;
    mappedCurrentVariant: { id: string; name: string; index: number };
    allocationType: AllocationType;
    objectId: string;
    setVariantsRef: (value: TimelineVariant[]) => void;
    onChangeVisibility: (params: { allocationVariantId: string; isVisible: boolean }) => void;
    setHighlightedVariantId: (value: string | undefined) => void;
    onDeleteFilter: () => void;
};

const props = defineProps<VariantSelectionDropDownProps>();

const variantsRef = computed(() => props.variantsRef);
const visibleVariants = computed(() => props.visibleVariants);
const selectionPanelVariants = computed(() => props.selectionPanelVariants);
const mappedCurrentVariant = computed(() => props.mappedCurrentVariant);
const objectId = computed(() => props.objectId);

const { newVariantIndex, onCreateNewVariant } = useCreateNewVariant(
    variantsRef,
    props.setVariantsRef,
    props.onChangeVisibility,
    props.allocationType,
    objectId,
);
const { onCopyAllocations } = useCopyAllocations(variantsRef, props.setVariantsRef, props.allocationType);

function onDropDownClose() {
    const dropDown = variantDropDown.value;
    if (dropDown) {
        dropDown.hide();
    }
}
</script>
