<template>
    <BaseForm>
        <template #fields>
            <TextField
                :value="fields.companyGroup"
                :label="$t('company-group')"
                :error-messages="errors.companyGroup"
                @input="$emit('onInput', { event: $event, fieldName: 'companyGroup' })"
            />
            <TextField
                :value="fields.businessDomain"
                :label="$t('business-domain')"
                :error-messages="errors.businessDomain"
                @input="$emit('onInput', { event: $event, fieldName: 'businessDomain' })"
            />
            <TextField
                :value="fields.department"
                :label="$t('department')"
                :error-messages="errors.department"
                @input="$emit('onInput', { event: $event, fieldName: 'department' })"
            />
            <TextField
                :value="fields.responsible"
                :label="$t('responsible')"
                :error-messages="errors.responsible"
                @input="$emit('onInput', { event: $event, fieldName: 'responsible' })"
            />
            <Feed>
                <div class="flex gap-1">
                    <label :for="feedInputId" class="block text-sm font-medium text-gray-700">{{
                        $t('organisational-changes')
                    }}</label>
                </div>
                <AddDateCommentEntry
                    :id="feedInputId"
                    @input="(event: Event) => handleAddDateCommentEntryInput((event.target as HTMLInputElement).value)"
                    @create-comment="(text: string) => onCreateComment(text)"
                />
                <template v-for="entry in feedEntries" :key="entry.id">
                    <DateCommentEntry
                        v-if="entry.type === 'user-text'"
                        :text="entry.text"
                        :image-url="getImageUrl(entry.createdBy?.icon?.original)"
                        :name="entry.createdByName"
                        :date-time="DateTime.fromISO(entry.createdAt)"
                        :is-editable="entry.isEditable"
                        :has-unsaved-changes="entry.hasUnsavedChanges"
                        @delete-comment="onDeleteComment(entry.id)"
                        @update-comment="(text: string) => onUpdateComment(entry.id, text)"
                        @undo-deleting-comment="onUndoDeletingComment(entry.id)"
                    />
                </template>
            </Feed>
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import AddDateCommentEntry from '@/components/Feed/AddDateCommentEntry.vue';
import DateCommentEntry from '@/components/Feed/DateCommentEntry.vue';
import Feed from '@/components/Feed/Feed.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import TextField from '@/components/Form/TextField.vue';
import { orgFeedData } from '@/scenario/keys';
import { ScenarioGroupErrors, ScenarioGroupFields } from '@/scenario/types';
import { getImageUrl } from '@/utils/s3';
import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';
import { computed, inject, ref } from 'vue';

// TODO wrap the feed field into a separate component

defineProps<{ fields: ScenarioGroupFields; errors: ScenarioGroupErrors }>();
defineEmits<{
    (e: 'onInput', v: { event: Event; fieldName: string }): void;
}>();

const orgFeedInject = inject(orgFeedData);
const feedEntries = computed(() => orgFeedInject?.feedEntries.value ?? []);
const feedInputId = nanoid();

const newEntryText = ref('');

function handleAddDateCommentEntryInput(text: string) {
    newEntryText.value = text;
}

function onDeleteComment(id: string) {
    orgFeedInject?.setUnsavedChanges(id, 'Deleted');
}

function onUpdateComment(id: string, text: string) {
    orgFeedInject?.setText(id, text);
}

function onCreateComment(text: string) {
    orgFeedInject?.createUnsavedFeedEntry(text);
}

function onUndoDeletingComment(id: string) {
    orgFeedInject?.setUnsavedChanges(id, 'None');
}
</script>
