<template>
    <side-panel :title="$t('users')">
        <template #actions>
            <base-button :to="{ name: 'user-create' }" icon="add" :enabled="false"></base-button>
        </template>
        <search-field v-model="searchPerson"></search-field>
        <!-- TODO add GraphQL loading stuff again (see GQLLoadingList) -->
        <side-panel-list>
            <list-item
                v-for="user in users"
                :key="user.id"
                class="py-1"
                :subtitle="user.email"
                :to="{ name: 'user-details', params: { userId: user.id } }"
            >
                <template #title>
                    <div>{{ user.name }}<span v-if="user.isAdmin" class="text-xs ml-2">👑</span></div>
                </template>
                <template #leading>
                    <profile-picture class="shrink-0" :size="40" :user="user"></profile-picture>
                </template>
            </list-item>
        </side-panel-list>
    </side-panel>
</template>

<script setup lang="ts">
import BaseButton from '@/components/BaseButton.vue';
import ListItem from '@/components/ListItem.vue';
import SearchField from '@/components/SearchField.vue';
import SidePanel from '@/components/SidePanel.vue';
import SidePanelList from '@/components/SidePanelList.vue';
import { GetUsersDocument } from '@/generated/graphql';
import { useHeadSafe } from '@unhead/vue';
import { useQuery } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed, ref } from 'vue';
import ProfilePicture from '../components/ProfilePicture.vue';

const fluent = useFluent();
const usersQuery = useQuery(GetUsersDocument);
const searchPerson = ref('');
const users = computed(() => {
    const loadedUsers = usersQuery.result.value?.users?.nodes ?? [];
    if (searchPerson.value) {
        return loadedUsers.filter((u) => u.name.toLocaleLowerCase().includes(searchPerson.value.toLocaleLowerCase()));
    }
    return loadedUsers;
});

useHeadSafe({ title: () => fluent.$t('users') });
</script>
