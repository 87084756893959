<template>
    <div class="rounded-sm overflow-hidden bg-gray-50">
        <div class="bg-gray-100 flex flex-row justify-between items-center gap-x-3 p-3">
            <Button
                size="Small"
                variant="Success-Strong"
                :disabled-explanation="
                    createAllocationVariantStatus != 'Hidden' ? $t('already-creating-new-variant') : undefined
                "
                :aria-pressed="createAllocationVariantStatus === 'Visible'"
                @click="createAllocationVariantStatus = 'Visible'"
            >
                <template #icon>
                    <PlusIcon class="w-4 h-4" />
                </template>

                {{ $t('new-allocation-variant-button') }}
            </Button>

            <Button
                class="text-gray-700"
                size="Small"
                variant="Transparent-Light"
                :icon-description="$t('close-allocation-variant-selection-panel')"
                @click="emit('close')"
            >
                <template #icon>
                    <CloseIcon class="w-5 h-5" />
                </template>
            </Button>
        </div>

        <NewAllocationVariant
            v-if="createAllocationVariantStatus !== 'Hidden'"
            class="mb-2"
            :variant-index="newVariantIndex"
            :status-label="getEventVariantStatusLabel('active-1')"
            :status-variant="eventVariantStatusToBadgeVariant('active-1')"
            :preselected-variant="
                preselectedVariant ? { id: preselectedVariant.id, name: preselectedVariant.name } : null
            "
            :all-variants="allVariants"
            :create-allocation-variant-status="createAllocationVariantStatus"
            :label-errors="labelErrors"
            @create="createAllocationVariant"
            @abort="createAllocationVariantStatus = 'Hidden'"
        />

        <RadioButtonGroup
            :active-id="activeIdRef"
            :name="nameRef"
            :label="'Hervorgehobene Variante'"
            @change="changeHighlight"
        >
            <template v-for="(entry, entryIndex) in enrichedEntries" :key="entryIndex">
                <RadioButtonEntry
                    :id="entry.type === 'No-Highlight' ? 'No-Highlight-Id' : entry.id"
                    :name="props.name"
                    :input-element-tooltip-text="inputElementTooltipText"
                    :invisible-label="$t('no-highlighted-allocation-variant-label')"
                    :has-highlight="
                        entry.type === 'Existing-Allocation-Variant' && !entry.hasAllocations ? 'Yellow' : undefined
                    "
                >
                    <p v-if="entry.type === 'No-Highlight'" class="text-gray-500 font-light py-3">
                        {{ $t('no-highlighted-allocation-variant') }}
                    </p>

                    <AllocationVariantEntry
                        v-if="entry.type === 'Existing-Allocation-Variant'"
                        :is-visible="entry.isVisible"
                        :is-highlighted="entry.id === activeId"
                        :copy-allocations-button-disabled-explanation="entry.copyAllocationsButtonDisabledExplanation"
                        :status-label="entry.statusLabel"
                        :status-variant="entry.statusVariant"
                        :is-main-variant="entry.isMainVariant"
                        :variant-id="entry.id"
                        :variant-index="entry.index"
                        :variant-name="entry.variantName"
                        :preselected-variant="entry.preselectedVariant"
                        :all-variants="entry.allVariants"
                        :copying-allocations-status="entry.copyAllocationsStatus"
                        :link-target="entry.linkTarget"
                        :has-allocations="entry.hasAllocations"
                        @toggle-visibility="() => toggleVisibility(entry)"
                        @copy-allocations="(copyFrom) => copyAllocations(entry, copyFrom)"
                        @start-copying-allocations="() => startCopyingAllocations(entry.id)"
                        @abort-copying-allocations="() => abortCopyingAllocations(entry.id)"
                    />
                </RadioButtonEntry>

                <Separator v-if="entryIndex < entries.length - 1" />
            </template>
        </RadioButtonGroup>
    </div>
</template>

<script setup lang="ts">
import { eventVariantStatusToBadgeVariant, getEventVariantStatusLabel } from '@/event/event';
import { SearchExistingEventVariantDocument, SearchExistingProjectVariantDocument } from '@/generated/graphql';
import { $t } from '@/plugins/fluent';
import { useApolloClient } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { DocumentNode } from 'graphql';
import { Ref, computed, ref, toRef, watch, watchEffect } from 'vue';
import Button from '../Button/Button.vue';
import CloseIcon from '../Icon/CloseIcon.vue';
import PlusIcon from '../Icon/PlusIcon.vue';
import { AllocationType } from '../Timeline/Timeline.types';
import AllocationVariantEntry from './AllocationVariantEntry.vue';
import NewAllocationVariant from './NewAllocationVariant.vue';
import RadioButtonGroup from './RadioButtonGroup.vue';
import RadioButtonEntry from './RadioButtonGroupEntry.vue';
import Separator from './Separator.vue';
import { ExistingAllocationVariantEntry, NoHighlightEntry, SelectionPanelEntries } from './types';

type AllocationVariantSelectionPanelProps = {
    objectId: string;
    allocationType: AllocationType;
    activeId: string;
    name: string;
    entries: SelectionPanelEntries;
    currentVariant: {
        id: string;
        index: number;
        name: string;
    };
    newVariantIndex: number;
};

type AllocationVariantSelectionPanelEmits = {
    (e: 'changeHighlight', idHighlightedVariant: string | undefined): void;
    (
        e: 'changeVisibility',
        { allocationVariantId, isVisible }: { allocationVariantId: string; isVisible: boolean },
    ): void;
    (
        e: 'createNewVariant',
        {
            allocationVariantLabel,
            copyAllocationsFrom,
            onDone,
        }: { allocationVariantLabel: string; copyAllocationsFrom: string | null; onDone: (isSuccess: boolean) => void },
    ): void;
    (
        e: 'copyAllocations',
        {
            idTargetVariant,
            idSourceVariant,
            onDone,
        }: { idTargetVariant: string; idSourceVariant: string; onDone: (isSuccess: boolean) => void },
    ): void;
    (e: 'close'): void;
};

const props = defineProps<AllocationVariantSelectionPanelProps>();
const emit = defineEmits<AllocationVariantSelectionPanelEmits>();
const fluent = useFluent();
const client = useApolloClient().client;

const objectId = computed(() => props.objectId);
const activeIdRef = toRef(props, 'activeId');
const entriesRef = toRef(props, 'entries');
const nameRef = toRef(props, 'name');
const createAllocationVariantStatus = ref<'Hidden' | 'Visible' | 'Loading' | 'Error'>('Hidden');
const labelErrors: Ref<string[]> = ref([]);
const newVariantLabel = ref('');

const allVariants = computed(() =>
    props.entries
        .filter((entry): entry is ExistingAllocationVariantEntry => entry.type === 'Existing-Allocation-Variant')
        .map((entry) => {
            return {
                id: entry.id,
                name: formatId(entry.index, entry.variantName),
                hasAllocations: entry.hasAllocations,
            };
        }),
);

type EnrichedEntry = Omit<ExistingAllocationVariantEntry, 'status'> & {
    copyAllocationsButtonDisabledExplanation: string | undefined;
    statusLabel: string;
    statusVariant: 'Green' | 'Yellow' | 'Red';
    copyAllocationsStatus: 'Hidden' | 'Visible' | 'Loading' | 'Error';
    preselectedVariant: { id: string; name: string } | null;
    allVariants: { id: string; name: string }[];
    invisibleRadioButtonLabel: string;
};

const enrichedEntries = ref<(NoHighlightEntry | EnrichedEntry)[]>([]);

const preselectedVariant = computed(() => ({
    id: props.currentVariant.id,
    name: formatId(props.currentVariant.index, props.currentVariant.name),
}));

watchEffect(() => {
    enrichedEntries.value = props.entries.map((entry) => {
        if (entry.type === 'Existing-Allocation-Variant') {
            return {
                ...entry,
                copyAllocationsButtonDisabledExplanation: undefined,
                statusLabel: getEventVariantStatusLabel(entry.status),
                statusVariant: eventVariantStatusToBadgeVariant(entry.status),
                copyAllocationsStatus: 'Hidden',
                preselectedVariant: entry.isMainVariant ? null : preselectedVariant.value,
                allVariants: allVariants.value,
                invisibleRadioButtonLabel: formatId(entry.index, entry.variantName),
            };
        }

        return entry;
    });
});

const inputElementTooltipText = $t('select-to-highlight-variant');

function toggleVisibility(entry: EnrichedEntry) {
    entry.isVisible = !entry.isVisible;
    changeVisibility(entry.id, entry.isVisible);
}

function copyAllocations(entry: EnrichedEntry, sourceId: string) {
    function onDone(isSuccess: boolean) {
        entry.copyAllocationsStatus = isSuccess ? 'Hidden' : 'Error';
    }

    entry.copyAllocationsStatus = 'Loading';

    emit('copyAllocations', {
        idTargetVariant: entry.id,
        idSourceVariant: sourceId,
        onDone,
    });
}

function startCopyingAllocations(id: string) {
    enrichedEntries.value = enrichedEntries.value.map((entry) => {
        if (entry.type === 'Existing-Allocation-Variant' && entry.id === id) {
            return {
                ...entry,
                copyAllocationsStatus: 'Visible',
                copyAllocationsButtonDisabledExplanation: undefined,
            };
        }

        return {
            ...entry,
            copyAllocationsButtonDisabledExplanation: $t(
                'only-allocations-of-a-single-allocation-variant-can-be-copied',
            ),
        };
    });
}

function abortCopyingAllocations(id: string) {
    enrichedEntries.value = enrichedEntries.value.map((entry) => {
        if (entry.type === 'Existing-Allocation-Variant') {
            if (entry.id === id) {
                return {
                    ...entry,
                    copyAllocationsStatus: 'Hidden',
                    copyAllocationsButtonDisabledExplanation: undefined,
                };
            }

            return {
                ...entry,
                copyAllocationsButtonDisabledExplanation: undefined,
            };
        }

        return entry;
    });
}

const queries = {
    Event: SearchExistingEventVariantDocument,
    Project: SearchExistingProjectVariantDocument,
    None: undefined,
} satisfies Record<AllocationType, DocumentNode | undefined>;

async function createAllocationVariant({
    label,
    selectedSource,
}: {
    label: string;
    selectedSource: { id: string; name: string } | null;
}) {
    const trimmedLabel = label.trim();
    newVariantLabel.value = trimmedLabel;

    const checkQuery = queries[props.allocationType];

    if (checkQuery) {
        const otherVariant = await client.query({
            query: checkQuery,
            variables: { name: newVariantLabel.value, objectId: objectId.value },
        });
        if (otherVariant.data) {
            if (otherVariant.data.variants?.nodes && otherVariant.data.variants.nodes.length > 0) {
                labelErrors.value = [fluent.$t('event-variant-name-already-exists')];
                createAllocationVariantStatus.value = 'Error';
                return;
            } else {
                createAllocationVariantStatus.value = 'Visible';
            }
        }
    }

    function onDone(isSuccess: boolean) {
        if (isSuccess) {
            createAllocationVariantStatus.value = 'Hidden';
            newVariantLabel.value = '';
        } else {
            createAllocationVariantStatus.value = 'Error';
        }
    }

    createAllocationVariantStatus.value = 'Loading' as const;

    emit('createNewVariant', {
        allocationVariantLabel: trimmedLabel,
        copyAllocationsFrom: selectedSource?.id ?? null,
        onDone,
    });
}

function formatId(index: number, variantName: string): string {
    return `BV${String(index).padStart(3, '0')} (${variantName})`;
}

watch([activeIdRef, entriesRef], ([newId, newEntries]) => {
    const activeExistingAllocationVariantEntry = newEntries
        .filter((entry): entry is ExistingAllocationVariantEntry => entry.type === 'Existing-Allocation-Variant')
        .find((entry) => entry.id === newId);

    if (activeExistingAllocationVariantEntry && !activeExistingAllocationVariantEntry.isVisible) {
        changeVisibility(newId, true);
    }
});

function changeHighlight(activeId: string | undefined) {
    emit('changeHighlight', activeId);
}

function changeVisibility(allocationVariantId: string, isVisible: boolean) {
    emit('changeVisibility', {
        allocationVariantId,
        isVisible,
    });
}
</script>
