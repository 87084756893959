import { EventVariantStatus } from '@/event/types';
import { RouteLocationRaw } from 'vue-router';

export const DEFAULT_HIGHLIGHT_VALUE = 'No-Highlight-Id';
export const ACTIVE_STATES = ['active-1', 'active-2'];

export type Variant = {
    id: string;
    name: string;
    hasAllocations: boolean;
};
export type NoHighlightEntry = {
    type: 'No-Highlight';
};

export type ExistingAllocationVariantEntry = {
    type: 'Existing-Allocation-Variant';
    id: string;
    index: number;
    variantName: string;
    isVisible: boolean;
    isMainVariant: boolean;
    status: EventVariantStatus;
    linkTarget: RouteLocationRaw;
    hasAllocations: boolean;
};

export type SelectionPanelEntries = Array<NoHighlightEntry | ExistingAllocationVariantEntry>;
