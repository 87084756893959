<template>
    <slot v-if="props.isDisabled" name="trigger" v-bind="$attrs" />

    <div
        v-if="!props.isDisabled"
        v-bind="$attrs"
        ref="triggerRef"
        class="inline-flex w-max"
        @mouseover="onMouseOver"
        @mouseleave="onMouseLeave"
        @focusin="onFocusIn"
        @focusout="onFocusOut"
        @keydown="onKeyDown"
    >
        <slot name="trigger" v-bind="triggerProps" />

        <div
            v-if="isOpenVia !== 'closed' && !props.isTeleported"
            :id="tooltipId"
            ref="contentRef"
            class="flex flex-col rounded-md bg-gray-50 text-center px-2 py-1 drop-shadow-[0_0_2px_rgba(0,0,0,0.25)] pointer-events-none z-50 min-w-max"
            :style="floatingStyles"
            role="tooltip"
        >
            <strong
                v-if="$slots.header"
                class="block text-gray-500 uppercase text-xxs font-semibold whitespace-nowrap"
                :class="{ 'my-0.5': $slots.header }"
            >
                <slot name="header" />
            </strong>

            <div class="text-gray-900 text-sm font-light w-full min-w-max">
                <slot name="text" />
            </div>

            <svg
                ref="arrowRef"
                class="fill-gray-50 pointer-events-none"
                :style="arrowPositionStyles"
                aria-hidden="true"
                width="14"
                height="14"
                viewBox="0 0 14 14"
            >
                <path stroke="none" d="M0,0 H14 L7,7 Q7,7 7,7 Z" />
            </svg>
        </div>
    </div>

    <Teleport v-if="props.isTeleported && !props.isDisabled" v-bind="$attrs" to="body">
        <div
            :id="tooltipId"
            ref="contentRef"
            class="flex flex-col rounded-md bg-gray-50 text-center px-2 py-1 drop-shadow-[0_0_2px_rgba(0,0,0,0.25)] pointer-events-none z-50 min-w-max"
            :style="floatingStyles"
            role="tooltip"
        >
            <strong
                v-if="$slots.header"
                class="block text-gray-500 uppercase text-xxs font-semibold whitespace-nowrap"
                :class="{ 'my-0.5': $slots.header }"
            >
                <slot name="header" />
            </strong>

            <div class="text-gray-900 text-sm font-light w-full min-w-max">
                <slot name="text" />
            </div>

            <svg
                ref="arrowRef"
                class="fill-gray-50 pointer-events-none"
                :style="arrowPositionStyles"
                aria-hidden="true"
                width="14"
                height="14"
                viewBox="0 0 14 14"
            >
                <path stroke="none" d="M0,0 H14 L7,7 Q7,7 7,7 Z" />
            </svg>
        </div>
    </Teleport>
</template>

<script setup lang="ts">
import { arrow, autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue';
import { nanoid } from 'nanoid';
import { computed, defineComponent, inject, ref, useSlots, watch } from 'vue';
import { triggerHideTooltipKey } from './injectionKeys';

defineComponent({
    inheritAttrs: false,
});

type TooltipProps = {
    isTeleported?: boolean;
    isDisabled?: boolean;
    placement?: 'top' | 'bottom';
};
const props = defineProps<TooltipProps>();

const isOpenVia = ref<'hover' | 'focus' | 'closed'>('closed');
const tooltipId = nanoid();

const slots = useSlots();

watch(
    () => [slots['trigger'], slots['header'], slots['text'], props.isTeleported, props.isDisabled, props.placement],
    () => {
        isOpenVia.value = 'closed';
    },
    { deep: true },
);

const triggerProps = {
    'aria-labelledby': tooltipId,
    popovertarget: undefined, // TODO This should be set correctly once we've fixed the tooltip issue in Firefox
};

function onKeyDown(event: KeyboardEvent) {
    switch (event.key) {
        case 'Escape':
            isOpenVia.value = 'closed';
            event.preventDefault(); // Prevent bubbling which might close other things accidentally
            break;
    }
}

function onMouseOver() {
    // Mouse over shouldn't overwrite the more "explicit" focus
    if (isOpenVia.value === 'closed') {
        isOpenVia.value = 'hover';
    }
}

function onMouseLeave() {
    if (isOpenVia.value === 'hover') {
        isOpenVia.value = 'closed';
    }
}

function onFocusIn() {
    isOpenVia.value = 'focus';
}

function onFocusOut() {
    isOpenVia.value = 'closed';
}

const triggerCounter = inject(triggerHideTooltipKey, ref(-1));
watch(triggerCounter, () => {
    isOpenVia.value = 'closed';
});

const ARROW_HEIGHT = 7;
const GAP = 2;
const triggerRef = ref<HTMLElement | null>(null);
const contentRef = ref<HTMLElement | null>(null);
const arrowRef = ref(null);
const { floatingStyles, placement, middlewareData } = useFloating(triggerRef, contentRef, {
    placement: props.placement ?? 'top',
    whileElementsMounted: autoUpdate,
    middleware: [
        offset(ARROW_HEIGHT + GAP), // number = Shorthand for main-axis
        flip({
            fallbackAxisSideDirection: 'start', // Which side to try first if neither the preferred option nor the fallback option fits
            crossAxis: false, // Don't check overflow on cross axis ==> Preserving the original axis as best as possible
        }),
        shift({ padding: 4 }), // padding = Minimal distance from tooltip to viewport edge
        arrow({
            element: arrowRef,
            padding: 8, // Prevent weird looking arrow positioning due to used border-radius
        }),
    ],
});

const arrowPositionStyles = computed(() => {
    // 14 === height of arrow
    if (placement.value === 'bottom') {
        return `
            position: absolute;
            left: ${middlewareData.value.arrow?.x}px;
            top: -14px;
            transform: rotate(180deg);
        `;
    }

    return `
        position: absolute;
        left: ${middlewareData.value.arrow?.x}px;
        bottom: -14px;
    `;
});
</script>
