<template>
    <Tooltip :is-disabled="!props.isDisabled">
        <template #trigger="tooltipProps">
            <button
                class="text-xs flex flex-col items-center w-8 whitespace-nowrap outline-hidden focus-visible:ring-inset focus-visible:ring-offset-0 focus-visible:ring-2"
                :class="[
                    props.isDisabled
                        ? 'bg-gray-300 text-gray-400  focus-visible:ring-gray-600 cursor-not-allowed'
                        : 'bg-gray-300 text-gray-600 hover:bg-gray-600 hover:text-gray-50 focus-visible:ring-gray-700 active:ring-inset active:ring-offset-1 active:ring-gray-800 active:text-gray-50 active:ring-2 cursor-pointer',
                ]"
                :aria-disabled="props.isDisabled"
                v-bind="tooltipProps"
                @click="props.isDisabled ? undefined : $emit('click')"
            >
                <div class="sticky top-2 mt-2">
                    <DoubleArrowLeftIcon :class="{ 'rotate-180 pr-px': props.iconDirection === 'right' }" />

                    <span class="mt-2" :style="`writing-mode: vertical-lr; transform: rotate(-180deg)`">
                        {{ props.label }}
                    </span>
                </div>
            </button>
        </template>

        <template #text>
            {{ $t('expanding-timeline-range-disabled') }}
        </template>
    </Tooltip>
</template>

<script setup lang="ts">
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import DoubleArrowLeftIcon from '../../Icon/DoubleArrowLeftIcon.vue';

type PreviousNextPeriodColumnProps = {
    iconDirection: 'left' | 'right';
    label: string;
    isDisabled: boolean;
};

const props = defineProps<PreviousNextPeriodColumnProps>();

defineEmits<{
    (e: 'click'): void;
}>();
</script>
