import { CreateScenarioDocument } from '@/generated/graphql';
import { useMyUser } from '@/user/composables/useMyUser';
import { useMutation } from '@vue/apollo-composable';
import { toValue } from 'vue';

export function useCreateScenario() {
    const createScenarioMutation = useMutation(CreateScenarioDocument);
    const { currentUser } = useMyUser();

    async function createScenario(scenarioGroupId: string, nameShort: string, nameLong: string, year: number) {
        const response = await createScenarioMutation.mutate({
            scenarioGroupId,
            nameLong,
            nameShort,
            year,
            createdById: toValue(currentUser).id,
            createdByName: toValue(currentUser).name,
        });
        if (response?.errors) {
            throw new Error(`Could not create Scenario: ${response.errors}`);
        }
        if (!response?.data?.createScenario?.scenario) {
            throw new Error('Create scenario did not return data');
        }
        return response.data.createScenario.scenario;
    }

    return {
        createScenario,
    };
}
