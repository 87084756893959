<template>
    <div ref="wrapperRef" class="py-3 pr-3 pl-6 bg-sky-50 border-l-4 border-l-sky-700 focus:outline-hidden" tabindex="-1">
        <div class="grid grid-cols-[min-content_min-content_1fr] flex-row items-center gap-3">
            <AllocationVariantIndex :index="props.variantIndex" />

            <Badge size="Small" :variant="props.statusVariant" :tooltip-text="$t('change-allocation-variant-in-tab')">
                {{ props.statusLabel }}
            </Badge>

            <div />
        </div>

        <div class="flex flex-col gap-4 mt-2">
            <TextField
                :label="$t('allocation-variant-label')"
                :value="allocationVariantLabel"
                :required="true"
                :error-messages="props.labelErrors"
                @input="(event) => (allocationVariantLabel = (event.target as HTMLInputElement).value)"
            />

            <ComboboxField
                :label="$t('copy-allocations-from-label')"
                :value="selectedSourceAllocationVariant"
                :items="props.allVariants"
                @change="(selectedItem) => (selectedSourceAllocationVariant = selectedItem)"
            />

            <div class="flex flex-row gap-2 justify-end items-center">
                <Button
                    class="text-gray-700"
                    size="Base"
                    :variant="hasChanges ? 'Danger-Light' : 'Info-Light'"
                    @click="closeArea"
                >
                    <template #icon>
                        <UndoIcon class="w-5 h-5" />
                    </template>

                    {{
                        hasChanges ? $t('button-label-discard-create-variant') : $t('button-label-abort-create-variant')
                    }}
                </Button>

                <Button
                    class="text-gray-700"
                    :disabled-explanation="
                        allocationVariantLabel === '' ? $t('allocation-variant-label-is-required') : undefined
                    "
                    size="Base"
                    :variant="props.createAllocationVariantStatus === 'Error' ? 'Danger-Light' : 'Success-Light'"
                    @click="
                        emit('create', {
                            label: allocationVariantLabel,
                            selectedSource: selectedSourceAllocationVariant,
                        })
                    "
                >
                    <template #icon>
                        <AllDoneIcon
                            v-if="
                                props.createAllocationVariantStatus === 'Visible' ||
                                props.createAllocationVariantStatus === 'Loading'
                            "
                            class="w-5 h-5"
                        />

                        <RefreshIcon v-if="props.createAllocationVariantStatus === 'Error'" class="w-5 h-5" />
                    </template>

                    <template v-if="props.createAllocationVariantStatus === 'Visible'">
                        {{
                            selectedSourceAllocationVariant
                                ? $t('button-label-create-variant-with-allocations')
                                : $t('button-label-create-variant')
                        }}
                    </template>

                    <template v-if="props.createAllocationVariantStatus === 'Loading'">
                        {{
                            selectedSourceAllocationVariant
                                ? $t('button-label-create-variant-pending-with-allocations')
                                : $t('button-label-create-variant-pending')
                        }}
                    </template>

                    <template v-if="props.createAllocationVariantStatus === 'Error'">
                        {{ $t('button-label-create-variant-failed') }}
                    </template>
                </Button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { computed, nextTick, onMounted, ref } from 'vue';
import AllocationVariantIndex from '../AllocationVariantIndex/AllocationVariantIndex.vue';
import Badge from '../Badge/Badge.vue';
import Button from '../Button/Button.vue';
import ComboboxField from '../Form/ComboboxField.vue';
import TextField from '../Form/TextField.vue';
import AllDoneIcon from '../Icon/AllDoneIcon.vue';
import RefreshIcon from '../Icon/RefreshIcon.vue';
import UndoIcon from '../Icon/UndoIcon.vue';

type Variant = {
    id: string;
    name: string;
};

type NewAllocationVariantProps = {
    statusLabel: string;
    statusVariant: 'Green' | 'Yellow' | 'Red';
    variantIndex: number;
    preselectedVariant: Variant | null;
    allVariants: Variant[];
    createAllocationVariantStatus: 'Visible' | 'Loading' | 'Error';
    labelErrors: string[];
};

type NewAllocationVariantPropsEmits = {
    (e: 'abort'): void;
    (e: 'create', v: { label: string; selectedSource: Variant | null }): void;
};

const props = defineProps<NewAllocationVariantProps>();
const emit = defineEmits<NewAllocationVariantPropsEmits>();

const allocationVariantLabel = ref('');
const selectedSourceAllocationVariant = ref<Variant | null>(props.preselectedVariant);

const hasChanges = computed(
    () => allocationVariantLabel.value !== '' || selectedSourceAllocationVariant.value !== null,
);

const wrapperRef = ref();
onMounted(() => {
    wrapperRef.value.focus();
});

const { deactivate } = useFocusTrap(wrapperRef, {
    preventScroll: false,
    clickOutsideDeactivates: true,
    immediate: true,
    returnFocusOnDeactivate: true,
    allowOutsideClick: true,
});

async function closeArea() {
    emit('abort');
    await nextTick();
    deactivate();
}
</script>
