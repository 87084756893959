<template>
    <div class="p-6 flex justify-between content-center print:p-1 print:block">
        <digikap-logo class="text-primary" small></digikap-logo>
        <router-link :to="{ name: 'locations' }" class="text-sky-700 print:hidden">{{ $t('back') }}</router-link>
    </div>

    <div class="p-6 print:p-2 print:mt-10">
        <h1 class="text-2xl font-bold print:hidden">{{ $t('quality-assurance-locations') }}</h1>
        <div class="flex gap-4 items-end mt-4">
            <ComboboxField
                :value="selectedType"
                :label="$t('space-type')"
                :items="types"
                class="print:hidden w-full"
                @change="onTypeChange"
            />
            <OptionSwitch
                name="report-attributes-switch"
                :active-index="activeIndex"
                :label="$t('attributes')"
                class="print:hidden"
                @change-option="(index) => setActiveIndex(index)"
            >
                <OptionSwitchEntry
                    v-for="entry of optionSwitchEntries"
                    :key="entry.id"
                    :index="entry.index"
                    :label="entry.label"
                />
            </OptionSwitch>
        </div>
        <h2 class="text-lg font-bold mt-8 print:mt-0">
            {{ $t('space-type') }}: {{ selectedType?.name }} <span v-if="spaces">({{ spaces.length }})</span>
        </h2>
        <ContactsTable
            v-if="activeId === 'contacts'"
            :spaces="spaces"
            :empty-list-info-message="emptyListInfoMessage"
        />
        <AreasTable v-if="activeId === 'areas'" :spaces="spaces" :empty-list-info-message="emptyListInfoMessage" />
        <LabelsTable v-if="activeId === 'labels'" :spaces="spaces" :empty-list-info-message="emptyListInfoMessage" />
    </div>
</template>

<script setup lang="ts">
import ComboboxField from '@/components/Form/ComboboxField.vue';
import { useOptionSwitch } from '@/components/OptionSwitch/composables/useOptionSwitch';
import OptionSwitch from '@/components/OptionSwitch/OptionSwitch.vue';
import OptionSwitchEntry from '@/components/OptionSwitch/OptionSwitchEntry.vue';
import { $t } from '@/plugins/fluent';
import DigikapLogo from '@/utils/components/DigikapLogo.vue';
import { useHeadSafe } from '@unhead/vue';
import { useRouteQuery } from '@vueuse/router';
import { useFluent } from 'fluent-vue';
import { watchEffect } from 'vue';
import AreasTable from '../components/AreasTable.vue';
import ContactsTable from '../components/ContactsTable.vue';
import LabelsTable from '../components/LabelsTable.vue';
import { useSpaces } from '../composables/useSpaces';

const fluent = useFluent();

const DEFAULT_OPTION_SWITCH_ID = 'contacts';

type SpacesOptionSwitchId = 'contacts' | 'areas' | 'labels';

type SpacesOptionSwitchEntry = {
    index: number;
    id: SpacesOptionSwitchId;
    label: string;
};

// route params
const spacesOptionIndex = useRouteQuery<string>('spaces-attributes', '');
const spacesType = useRouteQuery<string>('type', '');
const spacesTypeName = useRouteQuery<string>('typename', '');

const optionSwitchEntries: SpacesOptionSwitchEntry[] = [
    {
        index: 0,
        id: DEFAULT_OPTION_SWITCH_ID,
        label: $t('attributes-switch-contacts'),
    },
    {
        index: 1,
        id: 'areas',
        label: $t('attributes-switch-areas'),
    },
    {
        index: 2,
        id: 'labels',
        label: $t('attributes-switch-labels'),
    },
];

const { activeIndex, activeId, setActiveIndex } = useOptionSwitch(spacesOptionIndex, optionSwitchEntries);
const { spaces, types, selectedType, emptyListInfoMessage, onTypeChange } = useSpaces(spacesType, spacesTypeName);

watchEffect(() => {
    if (selectedType.value?.name) {
        useHeadSafe({ title: () => `${fluent.$t('space-type')}: ${selectedType.value?.name}` });
    } else {
        useHeadSafe({ title: () => fluent.$t('quality-assurance-locations') });
    }
});
</script>
