<template>
    <div :style="style" ref="root" class="flex items-stretch shrink-0">
        <div class="h-screen overflow-y-auto flex-auto overflow-x-hidden">
            <slot />
        </div>
        <div class="w-1 cursor-ew-resize" draggable="true" @dragstart="_dragstart" @dragend="_dragend" />
    </div>
</template>

<script setup lang="ts">
import { removeDragImage } from '@/utils/drag';
import { StyleValue } from '@vue/runtime-dom';
import { computed, onMounted, onUnmounted, ref } from 'vue';

type Props = {
    defaultWidth: number;
    minWidth: number;
    maxWidth: number;
    storageKey?: string;
};

const root = ref<Element>();
const props = defineProps<Props>();
const isDragging = ref(false);

const _width = ref();
const width = computed<number>({
    get: () => {
        let stored;
        if (props.storageKey) {
            const store = localStorage.getItem('horizontal_resize_' + props.storageKey);
            stored = store ? Number.parseInt(store) : undefined;
        }

        return _clampWidth(_width.value ?? stored ?? props.defaultWidth);
    },
    set: (x) => {
        const clamped = _clampWidth(x);
        if (props.storageKey) {
            localStorage.setItem('horizontal_resize_' + props.storageKey, clamped.toString());
        }

        _width.value = clamped;
    },
});

function _clampWidth(width: number) {
    return Math.max(Math.min(width, props.maxWidth), props.minWidth);
}

const style = computed<StyleValue>(() => ({
    height: '100vh',
    'overflow-y': 'auto',
    width: width.value + 'px',
    position: 'relative',
}));

function _dragstart(event: DragEvent) {
    isDragging.value = true;
    removeDragImage(event);
}

function _drag(event: DragEvent) {
    // we may receive other drag-events we're not interested in. Ignore them.
    if (!isDragging.value) return;

    const ownPos = root.value!.getBoundingClientRect();
    width.value = event.clientX - ownPos.left;
    event.preventDefault();
    event.stopPropagation();
}

function _dragend() {
    isDragging.value = false;
}

onMounted(() => {
    root.value?.parentElement?.addEventListener('dragover', _drag);
});

onUnmounted(() => {
    root.value?.parentElement?.removeEventListener('dragover', _drag);
});
</script>
