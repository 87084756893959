<template>
    <div class="rounded-full text-white font-semibold text-center" :style="classes">
        {{ initials }}
    </div>
</template>
<script setup lang="ts">
import hashString from '@/utils/hash';
import { computed } from 'vue';

const props = defineProps<{ name: string }>();

const nameParts = computed(() => props.name.split(' '));
const initials = computed(
    () => `${nameParts.value[0].charAt(0)}${nameParts.value[nameParts.value.length - 1].charAt(0).toUpperCase()}`,
);

const classes = computed(() => {
    const name = props.name;
    const color = colors[hashString(name) % colors.length];
    return { 'background-color': color };
});

// 500 variants of all tailwind colors https://tailwindcss.com/docs/customizing-colors
const colors = [
    '#64748b',
    '#6b7280',
    '#71717a',
    '#737373',
    '#78716c',
    '#ef4444',
    '#f97316',
    '#f59e0b',
    '#eab308',
    '#84cc16',
    '#22c55e',
    '#10b981',
    '#14b8a6',
    '#06b6d4',
    '#0ea5e9',
    '#3b82f6',
    '#6366f1',
    '#8b5cf6',
    '#a855f7',
    '#d946ef',
    '#ec4899',
    '#f43f5e',
];
</script>
