import { ActiveCard } from '@/components/Card/types';
import { REQUIRED_STRING } from '@/components/Form/schema';
import {
    SearchExistingEventVariantDocument,
    SearchExistingProjectVariantDocument,
    UpdateEventVariantDocument,
    UpdateProjectVariantDocument,
} from '@/generated/graphql';
import { useApolloClient, useMutation } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed, ref, Ref, toValue, watch } from 'vue';
import { VariantCardVariant, VariantStatus, VariantType } from '../types';

export function useVariantCardForm(
    objectId: Ref<string>,
    variantRef: Ref<VariantCardVariant>,
    variantType: VariantType,
    variantsRef: Ref<VariantCardVariant[]>,
    setVariantsRef: (variants: VariantCardVariant[]) => void,
    activeCardRef: Ref<ActiveCard>,
    setActiveCard: (value: ActiveCard) => void,
    feedHasUnsavedChanges: Ref<boolean>,
) {
    const fluent = useFluent();
    const client = useApolloClient().client;
    const variantFormData = ref({
        name: variantRef.value.name,
        status: variantRef.value.status,
    });
    const variantFormErrors: Ref<{ name: string[] }> = ref({
        name: [],
    });
    const newCommentText = ref('');

    const mutations = {
        Event: useMutation(UpdateEventVariantDocument),
        Project: useMutation(UpdateProjectVariantDocument),
    };
    const queryDocument = {
        Event: SearchExistingEventVariantDocument,
        Project: SearchExistingProjectVariantDocument,
    };

    const formChanged = computed(() => {
        if (
            feedHasUnsavedChanges.value ||
            variantFormData.value.name !== toValue(variantRef).name ||
            newCommentText.value !== ''
        ) {
            return true;
        }
        return false;
    });

    const saveButtonDisabledExplanation = computed(() => {
        if (!formChanged.value) return fluent.$t('no-form-changes-to-save');
        if (variantFormErrors.value.name.length > 0) return fluent.$t('unresolved-form-errors');
        if (newCommentText.value !== '') return fluent.$t('variant-unsaved-comment');
        return '';
    });

    watch(formChanged, (changed) => {
        const activeCard = toValue(activeCardRef);
        if (changed && activeCard.status !== 'View' && activeCard.id === toValue(variantRef).id) {
            setActiveCard({ ...activeCard, status: 'Edit-Touched' });
        } else {
            setActiveCard({ ...activeCard, status: 'Edit-Untouched' });
        }
    });

    async function resetVariantForm() {
        variantFormData.value = { name: toValue(variantRef).name, status: toValue(variantRef).status };
        variantFormErrors.value = { name: [] };
    }

    async function checkForExistingName(name: string) {
        const getVariantQueryDocument = queryDocument[variantType];
        const result = await client.query({
            query: getVariantQueryDocument,
            variables: { name: name.trim(), objectId: toValue(objectId) },
        });
        if (result.errors) throw new Error('Failed to check for existing variant name');

        if (result.data.variants?.nodes && result.data.variants.nodes.length > 0) {
            variantFormErrors.value = {
                ...variantFormErrors,
                name: [fluent.$t('event-variant-name-already-exists')],
            };
            return true;
        }
        return false;
    }

    async function handleNameFieldChange(value: string) {
        return await checkForExistingName(value);
    }

    async function handleNameFieldInput(value: string) {
        variantFormErrors.value = { ...variantFormErrors, name: [] };
        let newName = value;
        const parse = REQUIRED_STRING.safeParse(value);
        if (!parse.success) {
            const errors = parse.error.format()._errors ?? [];
            variantFormErrors.value = { ...variantFormErrors, name: errors };
        } else {
            newName = parse.data;
        }
        variantFormData.value = { ...variantFormData.value, name: newName };
    }

    function handleAddDateCommentEntryInput(comment: string) {
        newCommentText.value = comment;
    }

    async function onSaveCardForm() {
        const { name, status } = variantFormData.value;
        const id = toValue(variantRef).id;

        if (name !== toValue(variantRef).name) {
            if ((await checkForExistingName(name)) === true) return;
        }

        const updateMutation = mutations[variantType];

        const result = await updateMutation.mutate({
            id,
            feedId: toValue(variantRef).changesId,
            name: name,
            status: status,
        });

        if (result?.errors) throw new Error('Could not save variant and comments');

        const updatedVariant = result?.data?.update?.variant;

        if (updatedVariant) {
            setVariantsRef(
                toValue(variantsRef).map((variant) =>
                    variant.id === id
                        ? {
                              ...updatedVariant,
                              status: updatedVariant.status as VariantStatus,
                          }
                        : variant,
                ),
            );
        }

        resetVariantForm();
        setActiveCard({ id: '', label: '', status: 'View' });
    }

    return {
        variantFormData,
        variantFormErrors,
        saveButtonDisabledExplanation,
        formChanged,
        handleNameFieldInput,
        handleNameFieldChange,
        handleAddDateCommentEntryInput,
        resetVariantForm,
        onSaveCardForm,
    };
}
