<template>
    <div class="flex flex-col items-center z-40 pointer-events-none" :style="widthStyle">
        <div class="relative drop-shadow-[0_0_0.0625rem_rgba(0,0,0,0.3)] flex flex-row pointer-events-auto">
            <Tooltip v-if="props.displayDirectionIcons && props.direction === 'Left'">
                <template #trigger="tooltipProps">
                    <div
                        class="flex items-center justify-center size-[1.125rem] rounded-l-sm"
                        :class="[backgroundColorClass, textColorClass]"
                        v-bind="tooltipProps"
                        tabindex="0"
                    >
                        <InsertLeftIcon class="size-3" />
                    </div>
                </template>

                <template #text>
                    <template v-if="props.isMainPhase">
                        {{ $t('change-phase-main-left') }}
                    </template>

                    <template v-else>
                        {{ $t('change-phase-before-main') }}
                    </template>
                </template>
            </Tooltip>

            <!-- Left -1 addition for 1-day main phases, direction === 'Both' is only set for 1-day main phases -->
            <div v-if="props.direction === 'Both' && props.cellSpanBefore > 0" class="flex flex-row items-center">
                <div
                    class="size-[1.125rem] flex place-content-center gap-1 rounded-xs p-[0.0625rem] pointer-events-auto absolute"
                    :class="[backgroundColorClass, iconColorClass]"
                    :style="[leftMinusOneStyle]"
                >
                    <Tooltip>
                        <template #trigger="tooltipProps">
                            <button
                                v-bind="tooltipProps"
                                :aria-disabled="props.isDecreaseButtonDisabled"
                                type="button"
                                class="text-xs size-4 flex place-content-center text-center rounded-[0.0625rem] focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-gray-700"
                                :class="[
                                    props.isDecreaseButtonDisabled
                                        ? buttonDisabledClasses
                                        : props.isDecreasingDeleting
                                          ? deleteButtonClasses
                                          : buttonClasses,
                                ]"
                                @mouseover="send('Focus additional left minus button')"
                                @mouseleave="send('Blur left button')"
                                @focus="send('Focus additional left minus button')"
                                @blur="send('Blur left button')"
                                @click="
                                    props.cellSpanBefore === 1
                                        ? send('Remove temporary phase left')
                                        : send('Click left button')
                                "
                            >
                                {{ $t('minus-one') }}
                            </button>
                        </template>

                        <template #text>
                            <div class="w-full min-w-max text-center">
                                {{ $t('phase-reduce-length-left') }}
                            </div>
                        </template>

                        <template #header>
                            <div class="w-full min-w-max text-center">
                                {{ props.phaseType }}
                            </div>
                        </template>
                    </Tooltip>
                </div>

                <div
                    class="h-[0.1875rem] absolute"
                    :class="[backgroundColorClass]"
                    :style="[leftMinusOneConnectionBarStyle]"
                />
            </div>

            <div
                class="flex flex-row items-center gap-[0.0625rem] p-[0.0625rem] pointer-events-auto"
                :class="[
                    backgroundColorClass,
                    {
                        'rounded-l-xs':
                            (props.displayDirectionIcons && props.direction === 'Right') ||
                            !props.displayDirectionIcons,
                        'rounded-r-xs':
                            (props.displayDirectionIcons && props.direction === 'Left') || !props.displayDirectionIcons,
                    },
                ]"
            >
                <Tooltip>
                    <template #trigger="tooltipProps">
                        <button
                            v-bind="tooltipProps"
                            :aria-disabled="props.isDecreaseButtonDisabled"
                            type="button"
                            class="text-xs size-4 flex items-center justify-center text-center rounded-[0.0625rem] focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-gray-700"
                            :class="[
                                props.isDecreaseButtonDisabled
                                    ? buttonDisabledClasses
                                    : props.isDecreasingDeleting
                                      ? deleteButtonClasses
                                      : buttonClasses,
                            ]"
                            @mouseover="send('Focus left button')"
                            @mouseleave="send('Blur left button')"
                            @focus="send('Focus left button')"
                            @blur="send('Blur left button')"
                            @click="props.isDecreaseButtonDisabled ? undefined : send('Click left button')"
                        >
                            <DeleteForeverIcon v-if="props.isDecreasingDeleting" class="size-3" />
                            <template v-else-if="props.direction === 'Both'">{{ $t('plus-one') }}</template>
                            <template v-else>{{ $t('minus-one') }}</template>
                        </button>
                    </template>

                    <template #text>
                        <div v-if="props.isDecreaseButtonDisabled" class="w-full min-w-max text-center">
                            {{ $t('phase-already-marked-for-deletion') }}
                        </div>

                        <div v-else-if="props.isDecreasingDeleting" class="w-full min-w-max text-center">
                            {{ $t('phase-mark-for-deletion') }}
                        </div>

                        <div v-else-if="props.direction === 'Both'" class="w-full min-w-max text-center">
                            {{ $t('phase-increase-length-left') }}
                        </div>

                        <div v-else class="w-full min-w-max text-center">
                            {{ $t('phase-reduce-length') }}
                        </div>
                    </template>

                    <template #header>
                        <div class="w-full text-center">{{ props.phaseType }}</div>
                    </template>
                </Tooltip>

                <Tooltip>
                    <template #trigger="tooltipProps">
                        <button
                            v-bind="tooltipProps"
                            type="button"
                            class="text-xs size-4 text-center rounded-[0.0625rem] focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-gray-700"
                            :class="[buttonClasses]"
                            @mouseover="send('Focus right button')"
                            @mouseleave="send('Blur right button')"
                            @focus="send('Focus right button')"
                            @blur="send('Blur right button')"
                            @click="send('Click right button')"
                        >
                            {{ $t('plus-one') }}
                        </button>
                    </template>

                    <template #text>
                        <div v-if="props.direction === 'Both'" class="w-full min-w-max text-center">
                            {{ $t('phase-increase-length-right') }}
                        </div>

                        <div v-else class="w-full min-w-max text-center">
                            {{ $t('phase-increase-length') }}
                        </div>
                    </template>

                    <template #header>
                        <div class="w-full min-w-max text-center">
                            {{ props.phaseType }}
                        </div>
                    </template>
                </Tooltip>
            </div>

            <!-- Right -1 addition for 1-day main phases, direction === 'Both' is only set for 1-day main phases  -->
            <div v-if="props.direction === 'Both' && props.cellSpanAfter > 0" class="flex flex-row items-center">
                <div
                    class="h-[0.1875rem] absolute"
                    :class="[backgroundColorClass]"
                    :style="[rightMinusOneConnectionBarStyle]"
                />

                <div
                    class="h-[1.125rem] w-[1.125rem] flex place-content-center gap-1 rounded-xs p-[0.0625rem] pointer-events-auto absolute"
                    :class="[backgroundColorClass, iconColorClass]"
                    :style="[rightMinusOneStyle]"
                >
                    <Tooltip>
                        <template #trigger="tooltipProps">
                            <button
                                v-bind="tooltipProps"
                                :aria-disabled="props.isDecreaseButtonDisabled"
                                type="button"
                                class="text-xs size-4 flex place-content-center text-center rounded-[0.0625rem] focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-gray-700"
                                :class="[
                                    props.isDecreaseButtonDisabled
                                        ? buttonDisabledClasses
                                        : props.isDecreasingDeleting
                                          ? deleteButtonClasses
                                          : buttonClasses,
                                ]"
                                @mouseover="send('Focus additional right minus button')"
                                @mouseleave="send('Blur right button')"
                                @focus="send('Focus additional right minus button')"
                                @blur="send('Blur right button')"
                                @click="
                                    props.cellSpanAfter === 1
                                        ? send('Remove temporary phase right')
                                        : send('Click right button')
                                "
                            >
                                {{ $t('minus-one') }}
                            </button>
                        </template>

                        <template #text>
                            <div class="w-full min-w-max text-center">
                                {{ $t('phase-reduce-length-right') }}
                            </div>
                        </template>

                        <template #header>
                            <div class="w-full min-w-max text-center">
                                {{ props.phaseType }}
                            </div>
                        </template>
                    </Tooltip>
                </div>
            </div>

            <Tooltip v-if="props.displayDirectionIcons && props.direction === 'Right'">
                <template #trigger="tooltipProps">
                    <div
                        class="flex items-center justify-center size-[1.125rem] rounded-r-sm pointer-events-auto"
                        :class="[backgroundColorClass, textColorClass]"
                        v-bind="tooltipProps"
                        :aria-labelledby="tooltipProps['aria-labelledby']"
                        tabindex="0"
                    >
                        <InsertRightIcon class="size-3" />
                    </div>
                </template>

                <template #text>
                    <template v-if="props.isMainPhase">
                        {{ $t('change-phase-main-right') }}
                    </template>

                    <template v-else>
                        {{ $t('change-phase-after-main') }}
                    </template>
                </template>
            </Tooltip>
        </div>

        <div class="w-[0.1875rem] h-[0.3125rem]" :class="backgroundColorClass" />

        <div
            v-if="showPreviewIcon"
            class="relative -m-0.5 flex place-content-center gap-1 rounded-xs p-0.5 drop-shadow-[0_0_0.0625rem_rgba(0,0,0,0.3)] motion-preset-pop motion-duration-300"
            :class="[props.direction === 'Both' ? 'w-[2.0625rem]' : 'size-4', backgroundColorClass, iconColorClass]"
        >
            <InsertLeftIcon
                v-if="
                    state.matches('Left direction.Default') ||
                    state.matches('Both directions.Default') ||
                    state.matches('Both directions.+1 right focused') ||
                    state.matches('Both directions.-1 right focused')
                "
                class="size-3"
            />
            <InsertLeftFilledIcon
                v-if="state.matches('Left direction.+1 focused') || state.matches('Both directions.+1 left focused')"
                class="size-3"
            />
            <InsertLeftRemovedIcon
                v-if="state.matches('Left direction.-1 focused') || state.matches('Both directions.-1 left focused')"
                class="size-3"
            />

            <DeleteForeverIcon
                v-if="state.matches('Left direction.Delete focused') || state.matches('Right direction.Delete focused')"
                class="size-3"
            />

            <InsertRightIcon
                v-if="
                    state.matches('Right direction.Default') ||
                    state.matches('Both directions.Default') ||
                    state.matches('Both directions.+1 left focused') ||
                    state.matches('Both directions.-1 left focused')
                "
                class="size-3"
            />
            <InsertRightFilledIcon
                v-if="state.matches('Right direction.+1 focused') || state.matches('Both directions.+1 right focused')"
                class="size-3"
            />
            <InsertRightRemovedIcon
                v-if="state.matches('Right direction.-1 focused') || state.matches('Both directions.-1 right focused')"
                class="size-3"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useMachine } from '@xstate/vue';
import { computed, provide, ref, watchEffect } from 'vue';
import DeleteForeverIcon from '../../Icon/DeleteForeverIcon.vue';
import InsertLeftFilledIcon from '../../Icon/InsertLeftFilledIcon.vue';
import InsertLeftIcon from '../../Icon/InsertLeftIcon.vue';
import InsertLeftRemovedIcon from '../../Icon/InsertLeftRemovedIcon.vue';
import InsertRightFilledIcon from '../../Icon/InsertRightFilledIcon.vue';
import InsertRightIcon from '../../Icon/InsertRightIcon.vue';
import InsertRightRemovedIcon from '../../Icon/InsertRightRemovedIcon.vue';
import Tooltip from '../../Tooltip/Tooltip.vue';
import { triggerHideTooltipKey } from '../../Tooltip/injectionKeys';
import { PhaseType } from '../Timeline.types';
import { SpecializationType } from '../allocation/SpecializationMappings';
import {
    getActiveBackgroundColorClassForType,
    getHoverStrongBackgroundColorClassForType,
    getHoverTextColorClassForType,
    getLightBackgroundColorClassForType,
    getStrongBackgroundColorClassForType,
    getTextColorClassForType,
} from '../allocation/shared';
import { columnWidthInRem } from '../sharedStyles';
import { createStateMachine } from './EditPhaseWidget.state';

type EditPhaseWidgetProps = {
    phaseType: PhaseType;
    direction: 'Left' | 'Both' | 'Right';
    isMainPhase: boolean;
    isDecreasingDeleting: boolean;
    isDecreaseButtonDisabled: boolean;
    cellSpanBefore: number;
    cellSpanAfter: number;
    changePhaseStatus: 'Open-Popup' | 'Close-Popup' | 'Dont-Show';
    allowedSpecializations: (SpecializationType | null)[];
    hasTemporaryChangeMarker: boolean;
    displayDirectionIcons: boolean;
};

const props = defineProps<EditPhaseWidgetProps>();

const emit = defineEmits<{
    (e: 'decreasePhaseLength'): void;
    (e: 'increasePhaseLength', direction: 'Left' | 'Right'): void;
    (e: 'toggle-change-phase-type'): void;
    (e: 'change-specialization', specialization: SpecializationType | null): void;
}>();

const stateMachine = ref();
watchEffect(() => {
    stateMachine.value = createStateMachine({
        direction: props.direction,
        isDecreasingDeleting: props.isDecreasingDeleting,
        onDeletePhase: () => {
            triggerHideTooltip();
            emit('decreasePhaseLength');
        },
        onDecreasePhaseLength: () => {
            triggerHideTooltip();
            emit('decreasePhaseLength');
        },
        onIncreasePhaseLength: (direction) => {
            triggerHideTooltip();
            emit('increasePhaseLength', direction);
        },
    });
});
const { state, send } = useMachine(stateMachine.value);

const backgroundColorClass = computed(() => getStrongBackgroundColorClassForType(props.phaseType));
const textColorClass = computed(() => getTextColorClassForType(props.phaseType));

const buttonClasses = computed(() => {
    const lightBackgroundColor = getLightBackgroundColorClassForType(props.phaseType);
    const hoverStrongBackgroundColor = getHoverStrongBackgroundColorClassForType(props.phaseType);
    const hoverTextColorClass = getHoverTextColorClassForType(props.phaseType);
    const activeBackgroundColor = getActiveBackgroundColorClassForType(props.phaseType);

    return `text-gray-700 active:text-gray-50 ${hoverTextColorClass} ${lightBackgroundColor} ${hoverStrongBackgroundColor} ${activeBackgroundColor}`;
});

const buttonDisabledClasses = computed(() => {
    return `
            bg-gray-100 text-gray-500 cursor-not-allowed
        `;
});

const deleteButtonClasses = computed(() => {
    return `
        bg-red-700 text-red-50
        hover:bg-red-800 hover:text-red-50
        active:bg-red-900 active:text-red-50
        focus-visible:outline-red-700
    `;
});

const iconColorClass = computed(() => {
    if (props.phaseType === 'Durchführung' || props.phaseType === 'Projekt') {
        return 'text-gray-50';
    }

    return 'text-gray-700';
});

// This is used to force close the Tooltip after clicking any button
const triggerHideTooltipKeyCounter = ref(0);
provide(triggerHideTooltipKey, triggerHideTooltipKeyCounter);

function triggerHideTooltip() {
    triggerHideTooltipKeyCounter.value = triggerHideTooltipKeyCounter.value + 1;
}

const showPreviewIcon = computed(() => {
    return (
        state.value.matches('Left direction.+1 focused') ||
        state.value.matches('Both directions.+1 left focused') ||
        state.value.matches('Left direction.-1 focused') ||
        state.value.matches('Both directions.-1 left focused') ||
        state.value.matches('Left direction.Delete focused') ||
        state.value.matches('Right direction.Delete focused') ||
        state.value.matches('Right direction.+1 focused') ||
        state.value.matches('Both directions.+1 right focused') ||
        state.value.matches('Right direction.-1 focused') ||
        state.value.matches('Both directions.-1 right focused')
    );
});

const widthStyle = computed(() => {
    if (props.displayDirectionIcons) {
        return {
            width: `${columnWidthInRem + 1.125}rem`,
        };
    }

    return { width: `${columnWidthInRem}rem` };
});

const distanceBetweenColumnEdgeAndMinusOneButton = computed(() => {
    const widthButtonInRem = 1.125;

    return (columnWidthInRem - widthButtonInRem) / 2;
});

const leftMinusOneConnectionBarStyle = computed(() => {
    return {
        left: `-${distanceBetweenColumnEdgeAndMinusOneButton.value}rem`,
        width: `${distanceBetweenColumnEdgeAndMinusOneButton.value}rem`,
    };
});

const leftMinusOneStyle = computed(() => {
    const widthButtonInRem = 1.125;

    return {
        left: `-${distanceBetweenColumnEdgeAndMinusOneButton.value + widthButtonInRem}rem`,
    };
});

const rightMinusOneConnectionBarStyle = computed(() => {
    return {
        right: `-${distanceBetweenColumnEdgeAndMinusOneButton.value}rem`,
        width: `${distanceBetweenColumnEdgeAndMinusOneButton.value}rem`,
    };
});

const rightMinusOneStyle = computed(() => {
    const widthButtonInRem = 1.125;

    return {
        right: `-${distanceBetweenColumnEdgeAndMinusOneButton.value + widthButtonInRem}rem`,
    };
});
</script>

<style scoped>
.connect-bar-inverted-border-radius {
    position: relative;
}

.connect-bar-inverted-border-radius::after {
    content: '';
    position: absolute;
    height: 4px;
    bottom: -5px;
    left: 50%;
    transform: translate(-50%);
}
</style>
