<template>
    <div v-if="series">
        <FlexibleCard headline-id="projects" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderStatic>
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('projects') }}
                    </CardHeadline>
                </CardHeaderStatic>
            </template>

            <template #body>
                <CardRowColumns
                    v-for="project in series.projects.nodes"
                    v-if="series.projects?.nodes.length"
                    :key="project.id"
                    :columns-count="2"
                    class="border-b border-b-gray-200 last:border-b-0"
                >
                    <CardRowLinkWithSubtitle :link-text="project.nameShort" :link-target="getProjectLink(project.id)">
                        <template #subtitle>TODO</template>
                    </CardRowLinkWithSubtitle>
                    <Badge
                        v-if="project.status"
                        size="Extra-Small"
                        :variant="projectStatusToBadgeVariant(project.status as ProjectStatus)"
                    >
                        {{ getProjectStatusLabel(project.status as ProjectStatus) }}
                    </Badge>
                </CardRowColumns>
                <Message v-else :headline="$t('no-projects')" status="info">
                    {{ $t('project-series-has-no-projects') }}
                    <div class="mt-2">
                        <Button
                            variant="Info-Strong"
                            size="Small"
                            :disabled-explanation="createProjectDisabledExplanation"
                            @click="goToCreateProject(series?.id ?? '')"
                        >
                            <template #icon>
                                <EditIcon />
                            </template>
                            {{ createEventButtonLabel }}
                        </Button>
                    </div>
                </Message>
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import Badge from '@/components/Badge/Badge.vue';
import Button from '@/components/Button/Button.vue';
import CardHeaderStatic from '@/components/Card/CardHeaderStatic.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowColumns from '@/components/Card/CardRowColumns.vue';
import CardRowLinkWithSubtitle from '@/components/Card/CardRowLinkWithSubtitle.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import EditIcon from '@/components/Icon/EditIcon.vue';
import Message from '@/components/Message/Message.vue';
import { $t } from '@/plugins/fluent';
import { projectSeriesData } from '@/project/keys';
import {
    getProjectLink,
    getProjectStatusLabel,
    goToCreateProject,
    projectStatusToBadgeVariant,
} from '@/project/project';
import { ProjectStatus } from '@/project/types';
import { useHeadSafe } from '@unhead/vue';
import { computed, inject } from 'vue';

const data = inject(projectSeriesData);
const series = data?.series;

useHeadSafe({ title: () => `${$t('overview')} - ${series?.value.nameShort} - ${$t('project-wiki')}` });

const createProjectDisabledExplanation = computed(() => {
    if (!auth.isAdmin) {
        return $t('no-permission-to-create');
    }
    if (series && series.value.singleProject && series.value.projects && series.value.projects.nodes.length >= 1) {
        return $t('single-project-limit-reached');
    }
    return undefined;
});

const createEventButtonLabel = computed(() =>
    series?.value.singleProject ? $t('single-project-create') : $t('project-create'),
);
</script>
