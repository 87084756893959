import { sortByDateAsc } from '@/components/Timeline/composables/useTimelineRange';
import { Allocation } from '@/components/Timeline/Timeline.types';
import { Ref } from 'vue';

export function getAllocationsStart(allocations: Ref<Allocation[]>) {
    if (allocations.value && allocations.value.length > 0) {
        const allocationStartMin = allocations.value.toSorted((a, b) =>
            sortByDateAsc(a.startDateTime.toString(), b.startDateTime.toString()),
        )[0].startDateTime;
        return allocationStartMin;
    }
}
