<template>
    <FlexibleCard
        :headline-id="`v${variant.index}`"
        :expandable-status="getExpandStatus()"
        :disabled-explanation-expandable-button="expandableButtonDisabledMessage()"
        @change-expand="onChangeExpand(variant.id)"
    >
        <template #header="{ headlineId }">
            <CardHeaderEditable
                :status="cardStatus"
                :edit-button-disabled-explanation="editButtonDisabledExplanation()"
                :save-button-disabled-explanation="saveButtonDisabledExplanation"
                @enter-edit-mode="onEnterEditMode(variant)"
                @leave-edit-mode="onLeaveEditMode"
                @discard-changes="onDiscardChanges()"
                @save="onSave()"
            >
                <div class="flex flex-wrap items-center gap-x-3 mr-4 grow">
                    <div class="flex space-x-5 w-[69%] items-center">
                        <CardHeadline :headline-id="headlineId">
                            <AllocationVariantIndex :index="variant.index"></AllocationVariantIndex>
                            <span class="text-gray-400 font-light text-2xl">({{ variant.name }})</span>
                        </CardHeadline>
                        <BadgeDropDown
                            :label="$t('event-variant-status-label')"
                            class="text-base font-normal"
                            :current-value="getVariantStatusItem(variant.status)"
                            :values="getVariantStatusOptionItems(variant.status)"
                            :status="dropDownStatus"
                            @change-value="handleBadgeDropdown($event, variant.id)"
                        />
                        <Tooltip
                            v-if="variant.isCurrent"
                            tabindex="0"
                            class="rounded-sm focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-sky-700"
                        >
                            <template #trigger="tooltipProps">
                                <div
                                    id="staricon"
                                    :class="starIconStyle"
                                    :aria-labelledby="
                                        tooltipProps['aria-labelledby']
                                            ? `${tooltipProps['aria-labelledby']} staricon`
                                            : undefined
                                    "
                                    :popovertarget="
                                        tooltipProps['popovertarget'] ? tooltipProps.popovertarget : undefined
                                    "
                                >
                                    <StarFilledIcon class="w-5 h-5" />
                                </div>
                            </template>
                            <template #text>
                                <div class="h-max">{{ $t('main-variant') }}</div>
                            </template>
                        </Tooltip>
                    </div>
                    <div class="w-[29%]">
                        <span v-if="feedEntries.length > 0" class="text-gray-700">
                            {{ feedEntries.length }}
                            <span class="text-gray-400 font-light">{{
                                $t('comments', { count: feedEntries.length })
                            }}</span>
                        </span>
                    </div>
                </div>
            </CardHeaderEditable>
        </template>

        <template #body>
            <template v-if="cardStatus !== 'View'">
                <BaseForm class="p-4">
                    <template #fields>
                        <TextField
                            :value="variantFormData.name"
                            :label="$t('allocation-variant-label')"
                            :error-messages="variantFormErrors.name"
                            @input="(event: Event) => handleNameFieldInput((event.target as HTMLInputElement).value)"
                            @change="(event: Event) => handleNameFieldChange((event.target as HTMLInputElement).value)"
                        />
                        <div>
                            <div class="flex gap-1">
                                <label class="block text-sm font-medium text-gray-700">{{ $t('main-variant') }}</label>
                            </div>
                            <div class="relative mt-1">
                                <div v-if="variant.isCurrent" class="text-gray-400 text-sm font-light">
                                    {{ $t('already-main-variant') }}
                                </div>
                                <Button
                                    v-else
                                    size="Small"
                                    variant="Neutral-Strong"
                                    :disabled-explanation="setCurrentVariantDisabledExplanation"
                                    @click="onSetCurrentVariant(variant.id)"
                                >
                                    <template #icon>
                                        <StarFilledIcon />
                                    </template>
                                    {{ $t('change-to-main-variant') }}
                                </Button>
                            </div>
                        </div>
                    </template>
                </BaseForm>
            </template>
            <template v-else>
                <CardRowText :label="$t('allocation-variant-label')" :text="variant.name" :has-border-bottom="true" />
                <CardRowText
                    v-if="variant.isCurrent"
                    :label="$t('main-variant')"
                    :text="$t('already-main-variant')"
                    text-color="text-gray-400 font-light"
                    :has-border-bottom="isCommentSectionVisible(feedEntries.length)"
                />
                <CardLabelContent
                    v-else
                    :label="$t('main-variant')"
                    :has-border-bottom="isCommentSectionVisible(feedEntries.length)"
                    :button-label="$t('change-to-main-variant')"
                >
                    <Button
                        size="Small"
                        variant="Neutral-Strong"
                        :disabled-explanation="setCurrentVariantDisabledExplanation"
                        @click="onSetCurrentVariant(variant.id)"
                    >
                        <template #icon>
                            <StarFilledIcon />
                        </template>
                        {{ $t('change-to-main-variant') }}
                    </Button>
                </CardLabelContent>
            </template>
            <div v-if="isCommentSectionVisible(feedEntries.length)" class="bg-gray-50 p-4 rounded-lg">
                <Feed>
                    <AddDateCommentEntry
                        v-if="cardStatus != 'View'"
                        @input="
                            (event: Event) => handleAddDateCommentEntryInput((event.target as HTMLInputElement).value)
                        "
                        @create-comment="(text: string) => createUnsavedFeedEntry(text)"
                    />
                    <template v-for="entry in feedEntries" :key="entry.id">
                        <DateCommentEntry
                            v-if="entry.type === 'user-text'"
                            :text="entry.text"
                            :image-url="getImageUrl(entry.createdBy?.icon?.original)"
                            :name="entry.createdByName"
                            :date-time="DateTime.fromISO(entry.createdAt)"
                            :is-editable="entry.isEditable && cardStatus != 'View'"
                            :has-unsaved-changes="entry.hasUnsavedChanges"
                            @delete-comment="onDelete(entry.id, entry.hasUnsavedChanges)"
                            @update-comment="(text: string) => setText(entry.id, text)"
                            @undo-deleting-comment="undoDelete(entry.id, entry.hasUnsavedChanges)"
                        />
                        <NewMainVariantEntry
                            v-if="
                                entry.type === 'new-main-variant' &&
                                entry.oldMainVariantIndex &&
                                entry.newMainVariantIndex
                            "
                            :user-name="entry.createdByName"
                            :previous-main-variant-name="getUnstyledAllocationVariantIndex(entry.oldMainVariantIndex)"
                            :previous-main-variant-link-target="getVariantRoute(objectId, entry.oldMainVariantIndex)"
                            :new-main-variant-name="getUnstyledAllocationVariantIndex(entry.newMainVariantIndex)"
                            :new-main-variant-link-target="getVariantRoute(objectId, entry.newMainVariantIndex)"
                            :date-time="DateTime.fromISO(entry.createdAt)"
                        />
                        <StatusChangeEntry
                            v-if="entry.type === 'new-status'"
                            :new-status="getVariantStatusLabel(entry.newStatus)"
                            :previous-status="getVariantStatusLabel(entry.oldStatus)"
                            :date-time="DateTime.fromISO(entry.createdAt)"
                            :user-name="entry.createdByName"
                        />
                        <CreationDateEntry
                            v-if="entry.type === 'created-at'"
                            :user-name="entry.createdByName"
                            :date-time="DateTime.fromISO(entry.objectCreatedAt ?? '')"
                        />
                    </template>
                </Feed>
            </div>
        </template>
    </FlexibleCard>
</template>
<script setup lang="ts">
import auth from '@/auth/auth';
import AllocationVariantIndex from '@/components/AllocationVariantIndex/AllocationVariantIndex.vue';
import { getUnstyledAllocationVariantIndex } from '@/components/AllocationVariantIndex/getFullAllocationVariantIndex';
import BadgeDropDown from '@/components/BadgeDropDown/BadgeDropDown.vue';
import Button from '@/components/Button/Button.vue';
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardLabelContent from '@/components/Card/CardLabelContent.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { ActiveCard } from '@/components/Card/types';
import AddDateCommentEntry from '@/components/Feed/AddDateCommentEntry.vue';
import CreationDateEntry from '@/components/Feed/CreationDateEntry.vue';
import DateCommentEntry from '@/components/Feed/DateCommentEntry.vue';
import Feed from '@/components/Feed/Feed.vue';
import NewMainVariantEntry from '@/components/Feed/NewMainVariantEntry.vue';
import StatusChangeEntry from '@/components/Feed/StatusChangeEntry.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import TextField from '@/components/Form/TextField.vue';
import StarFilledIcon from '@/components/Icon/StarFilledIcon.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { useFeed } from '@/feed/composables/useFeed';
import { getImageUrl } from '@/utils/s3';
import { useFluent } from 'fluent-vue';
import { DateTime } from 'luxon';
import { computed, ref } from 'vue';
import { useVariantCard } from '../composables/useVariantCard';
import { useVariantCardForm } from '../composables/useVariantCardForm';
import { useVariantStatusDropdown } from '../composables/useVariantStatusDropdown';
import { VariantCardVariant, VariantType } from '../types';

type VariantCardProps = {
    objectId: string;
    variant: VariantCardVariant;
    variantType: VariantType;
    variantsRef: VariantCardVariant[];
    activeCard: ActiveCard;
    expandedCards: string[];
    setVariantsRef: (value: VariantCardVariant[]) => void;
    setActiveCard: (value: ActiveCard) => void;
    setExpandedCards: (value: string[]) => void;
};

export type VariantCardExpose = {
    id: string;
    createNewMainVariantEntry: (oldId: string, oldIndex: number, newId: string, newIndex: number) => void;
};

const props = defineProps<VariantCardProps>();
const emit = defineEmits<{ (e: 'setCurrentVariant', v: string): void }>();
const fluent = useFluent();

const objectId = computed(() => props.objectId);

const variantType = computed(() => props.variantType);
const variantsRef = computed(() => props.variantsRef);
const variant = computed(() => props.variant);
const variantId = computed(() => variant.value.id);
const activeCard = computed(() => props.activeCard);
const expandedCards = computed(() => props.expandedCards);
const feedId = computed(() => variant.value.changesId);

const newEntry = ref('');

function handleAddDateCommentEntryInput(value: string) {
    newEntry.value = value;
}

const {
    feedEntries,
    hasUnsavedChanges: feedHasUnsavedChanges,
    createUnsavedFeedEntry,
    createNewMainVariantEntry,
    createStatusChangeEntry,
    setText,
    onSaveFeed,
    onDelete,
    undoDelete,
    onDiscardFeedChanges,
} = useFeed(feedId);

defineExpose({
    id: props.variant.id,
    createNewMainVariantEntry: createNewMainVariantEntry,
});

const isAdmin = auth.isAdmin();
const setCurrentVariantDisabledExplanation = computed(() => (isAdmin ? '' : fluent.$t('no-permission-to-edit')));

const cardStatus = computed(() => (activeCard.value.id === variantId.value ? activeCard.value.status : 'View'));

const {
    variantFormData,
    variantFormErrors,
    saveButtonDisabledExplanation,
    formChanged,
    handleNameFieldInput,
    handleNameFieldChange,
    resetVariantForm,
    onSaveCardForm,
} = useVariantCardForm(
    objectId,
    variant,
    variantType.value,
    variantsRef,
    props.setVariantsRef,
    activeCard,
    props.setActiveCard,
    feedHasUnsavedChanges,
);

const {
    getExpandStatus,
    expandableButtonDisabledMessage,
    editButtonDisabledExplanation,
    isCommentSectionVisible,
    onChangeExpand,
    onEnterEditMode,
    onLeaveEditMode,
    onDiscardChanges: onDiscardChangesVariantCard,
} = useVariantCard(variantId, activeCard, formChanged, expandedCards, props.setActiveCard, props.setExpandedCards);

const {
    dropDownStatus,
    getVariantStatusItem,
    getVariantStatusLabel,
    getVariantStatusOptionItems,
    handleBadgeDropdown,
    getVariantRoute,
} = useVariantStatusDropdown(variantType, variant, variantsRef, props.setVariantsRef, createStatusChangeEntry);

function onSetCurrentVariant(variantId: string) {
    emit('setCurrentVariant', variantId);
}

// FIXME how to update both feed entries for differnt variant cards?

const starIconStyle = `rounded w-10 h-10 m-auto text-base p-[0.5625rem]
    border border-sky-700 bg-sky-700 text-sky-50
    hover:border-sky-800 hover:bg-sky-800 hover:text-sky-50`;

function onSave() {
    onSaveCardForm();
    onSaveFeed();
}

function onDiscardChanges() {
    resetVariantForm();
    onDiscardFeedChanges();
    onDiscardChangesVariantCard();
}
</script>
