<template>
    <Tooltip :is-disabled="!props.tooltipText && !props.disabledExplanation">
        <template #trigger="tooltipProps">
            <label
                class="w-min flex flex-row items-center pl-1.5 rounded-full text-gray-700 font-light cursor-pointer"
                :class="[props.isActive ? 'bg-sky-100' : 'bg-gray-100', { ' hover:cursor-not-allowed': isDisabled }]"
                :aria-describedby="tooltipProps['aria-labelledby']"
                :popovertarget="tooltipProps.popovertarget"
            >
                <button
                    type="button"
                    class="inline-flex h-5 w-9 shrink-0 rounded-full border-2 border-transparent focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-sky-700 focus-visible:ring-offset-2"
                    :class="[
                        isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
                        {
                            'bg-sky-700': props.isActive,
                            'bg-gray-200': !props.isActive && !isDisabled,
                            'bg-gray-300': !props.isActive && isDisabled,
                            'active:bg-gray-700  transition-colors duration-200 ease-in-out': !isDisabled,
                        },
                    ]"
                    role="switch"
                    :aria-checked="props.isActive"
                    :aria-disabled="isDisabled"
                    @click="(event: Event) => (isDisabled ? undefined : emit('toggle', event))"
                >
                    <span
                        aria-hidden="true"
                        class="pointer-events-none inline-block h-4 w-4 transform rounded-full shadow-sm ring-0 transition duration-200 ease-in-out"
                        :class="[
                            props.isActive ? 'translate-x-4' : 'translate-x-0',
                            isDisabled ? 'bg-gray-400' : 'bg-white',
                        ]"
                    />
                </button>

                <span class="text-right flex-1 py-1 pl-2 pr-2.5">
                    <slot />
                </span>
            </label>
        </template>

        <template #text>
            <template v-if="props.disabledExplanation">{{ props.disabledExplanation }}</template>
            <template v-if="!props.disabledExplanation">{{ props.tooltipText }}</template>
        </template>
    </Tooltip>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import Tooltip from '../Tooltip/Tooltip.vue';

type ActivatedToggleProps = {
    isActive: boolean;
    tooltipText?: string;
    disabledExplanation?: string;
};

type ActivatedToggleEmits = {
    (e: 'toggle', event: Event): void;
};

const props = defineProps<ActivatedToggleProps>();
const emit = defineEmits<ActivatedToggleEmits>();

const isDisabled = computed(() => Boolean(props.disabledExplanation));
</script>
