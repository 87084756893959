import { computed, Ref, ref, toValue } from 'vue';
import { OptionSwitchEntryType } from '../types';

function getIdFromIndex(entries: OptionSwitchEntryType[], index: number) {
    return entries.find((e) => e.index === index)?.id;
}

function getIndexFromId(entries: OptionSwitchEntryType[], id: string) {
    return entries.find((e) => e.id === id)?.index;
}

export function useOptionSwitch(currentIndex: Ref<string>, entries: OptionSwitchEntryType[]) {
    const activeIndex = ref(getIndexFromId(entries, toValue(currentIndex)) ?? 0);
    const activeId = computed(() => getIdFromIndex(entries, activeIndex.value));

    function setActiveIndex(value: number) {
        const id = getIdFromIndex(entries, value);
        activeIndex.value = id !== undefined ? value : 0;
        currentIndex.value = id ?? '';
    }

    function setActiveId(id: string) {
        const index = getIndexFromId(entries, id);
        activeIndex.value = index ?? 0;
        currentIndex.value = index !== undefined ? id : '';
    }

    return {
        activeIndex,
        activeId,
        setActiveIndex,
        setActiveId,
    };
}
