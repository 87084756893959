<template>
    <HeaderCardsLayout v-if="series" :logo-url="iconUrl ?? ''" :logo-alt="`Logo ${series.nameShort}`" variant="Logo">
        <template #headline>{{ series.nameShort }}</template>
        <template #headline-extra>
            <BadgeDropDown
                :label="$t('event-series-status-label')"
                class="text-base font-normal"
                :current-value="getEventSeriesStatusItem(series.status)"
                :values="getEventSeriesStatusItems(series.status)"
                :status="statusDropDownStatus"
                @change-value="(status: string) => onChangeStatus(status)"
            />
        </template>
        <template #breadcrumb>
            <Breadcrumb :headline="$t('breadcrumb')" :current-page="series.nameShort">
                <template #currentIcon>
                    <SingleEventIcon v-if="series.singleEvent" class="inline-block align-sub" />
                </template>
                <BreadcrumbLink :target="{ name: 'events' }">
                    <BallotIcon class="mr-1" />
                    {{ $t('event-wiki') }}
                </BreadcrumbLink>
            </Breadcrumb>
        </template>

        <template v-if="series.singleEvent" #icon><SingleEventIcon class="h-12 w-12" /></template>

        <template #buttons>
            <Button
                variant="Info-Light"
                size="Base"
                :disabled-explanation="convertDisabledExplanation"
                @click="toggleSingleEvent"
            >
                <template #icon>
                    <SingleEventIcon v-if="!series.singleEvent" />
                </template>
                {{ convertButtonLabel }}
            </Button>
            <Button
                variant="Danger-Strong"
                size="Base"
                :disabled-explanation="deleteDisabledExplanation"
                @click="deleteSeries"
            >
                <template #icon>
                    <DeleteForeverIcon />
                </template>
                {{ $t('delete') }}
            </Button>
            <Button
                variant="Info-Strong"
                size="Base"
                :disabled-explanation="createEventDisabledExplanation"
                @click="goToCreateEvent(seriesId)"
            >
                <template #icon><EditIcon /></template>
                {{ createEventButtonLabel }}
            </Button>
        </template>
        <template #tab-navigation>
            <TabNavigation
                :headline="$t('tab')"
                :items="tabNavigationItems"
                tab-panel-id="eventSeriesTabs"
                :tabs-disabled-text="tabsDisabledText"
                @focus-tab-panel="focusTabPanel"
            />
        </template>
        <template #body>
            <TabPanel :id="tabPanelId" ref="tabPanelRef" :headline="activeTabHeadline">
                <RouterView v-slot="{ Component }">
                    <component
                        :is="Component"
                        :active-card="activeCard"
                        :series="series"
                        :errors="errors"
                        :multilinks="multilinks"
                        @enter-edit-mode="onEnterEditMode"
                        @leave-edit-mode="onLeaveEditMode"
                        @discard-changes="onDiscardChanges"
                        @save="onSaveForm"
                        @handle-form-field="handleFormField"
                        @handle-checkbox-input="handleCheckboxInput"
                        @handle-select-change="handleSelectChange"
                        @upload-icon="uploadIcon"
                        @delete-icon="deleteIcon"
                        @handle-multilink="handleMultilink"
                        @delete-link="deleteMultilinkLink"
                    ></component>
                </RouterView>
            </TabPanel>
        </template>
    </HeaderCardsLayout>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import BadgeDropDown from '@/components/BadgeDropDown/BadgeDropDown.vue';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import BreadcrumbLink from '@/components/Breadcrumb/BreadcrumbLink.vue';
import Button from '@/components/Button/Button.vue';
import BallotIcon from '@/components/Icon/BallotIcon.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import EditIcon from '@/components/Icon/EditIcon.vue';
import SingleEventIcon from '@/components/Icon/SingleEventIcon.vue';
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import TabNavigation from '@/components/TabNavigation/TabNavigation.vue';
import TabPanel from '@/components/TabNavigation/TabPanel.vue';
import { useEventSeriesForm } from '@/event/composables/forms';
import { fileUploadLoadingKey, iconKey } from '@/event/keys';
import { useHeadSafe } from '@unhead/vue';
import { useFluent } from 'fluent-vue';
import { nanoid } from 'nanoid';
import { computed, provide, ref } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { getEventSeriesStatusItem, getEventSeriesStatusItems, goToCreateEvent } from '../event';
import { EventSeriesStatus } from '../types';

const props = defineProps<{ seriesId: string }>();
const fluent = useFluent();

const tabPanelRef = ref();
const seriesId = computed(() => props.seriesId);
const isAdmin = auth.isAdmin();

const {
    series,
    errors,
    multilinks,
    deleteSeries,
    deleteDisabledExplanation,
    convertDisabledExplanation,
    createEventDisabledExplanation,
    getDisabledExplanation,
    activeCard,
    onEnterEditMode,
    onLeaveEditMode,
    onDiscardChanges,
    onSaveForm,
    handleFormField,
    handleCheckboxInput,
    handleSelectChange,
    uploadIcon,
    deleteIcon,
    fileUploadLoading,
    iconUrl,
    toggleSingleEvent,
    handleMultilink,
    deleteMultilinkLink,
    updateStatus,
    updateStatusError,
    updateStatusLoading,
} = useEventSeriesForm(seriesId);

useHeadSafe({ title: () => `${series.value.nameShort} - ${fluent.$t('event-wiki')}` });

provide(fileUploadLoadingKey, fileUploadLoading);
provide(iconKey, ref({ url: iconUrl, fromParent: false }));

const changeStatusDisabledExplanation = computed(() => {
    return isAdmin ? undefined : fluent.$t('no-permission-to-edit');
});

const statusDropDownStatus = computed(() => {
    if (changeStatusDisabledExplanation.value) return 'Disabled';
    if (updateStatusLoading.value) return 'Loading';
    if (updateStatusError.value) return 'Retry';
    return 'Default';
});

onBeforeRouteLeave(() => {
    if (activeCard.value.id && activeCard.value.status === 'Edit-Touched') {
        if (!confirm(fluent.$t('leave-page-warning'))) return false;
    }
});
const tabsDisabledText = computed(() => fluent.$t('tabs-disabled', { name: activeCard.value.label }));
const tabPanelId = nanoid();

const isOverviewTabDisabled = computed(() => Boolean(getDisabledExplanation('event-series-overview').value));
const isDetailsTabDisabled = computed(() => Boolean(getDisabledExplanation('event-series-details').value));
const isOrganisationalStructureTabDisabled = computed(() => Boolean(getDisabledExplanation('event-series-org').value));
const isAppointmentTabDisabled = computed(() => Boolean(getDisabledExplanation('event-series-appointment').value));
const isLocationRequirementsTabDisabled = computed(() =>
    Boolean(getDisabledExplanation('event-series-location').value),
);
const isLocationLinksTabDisabled = computed(() => Boolean(getDisabledExplanation('event-series-links').value));

const tabNavigationItems = ref([
    {
        target: { name: 'event-series-overview' },
        routeName: 'event-series-overview',
        label: fluent.$t('overview'),
        isDisabled: isOverviewTabDisabled,
    },
    {
        target: { name: 'event-series-details' },
        routeName: 'event-series-details',
        label: fluent.$t('master-data'),
        isDisabled: isDetailsTabDisabled,
    },
    {
        target: { name: 'event-series-org' },
        routeName: 'event-series-org',
        label: fluent.$t('organisational-structure'),
        isDisabled: isOrganisationalStructureTabDisabled,
    },
    {
        target: { name: 'event-series-appointment' },
        routeName: 'event-series-appointment',
        label: fluent.$t('appointment'),
        isDisabled: isAppointmentTabDisabled,
    },
    {
        target: { name: 'event-series-location' },
        routeName: 'event-series-location',
        label: fluent.$t('location-requirements'),
        isDisabled: isLocationRequirementsTabDisabled,
    },
    {
        target: { name: 'event-series-links' },
        routeName: 'event-series-links',
        label: fluent.$t('links'),
        isDisabled: isLocationLinksTabDisabled,
    },
]);

const route = useRoute();
const activeTabHeadline = computed(() => {
    return fluent.$t('tab-panel', {
        activeTabName: tabNavigationItems.value.find((item) => item.routeName === route.name)?.label ?? '',
    });
});

function focusTabPanel() {
    tabPanelRef.value.focus();
}

const createEventButtonLabel = computed(() =>
    series.value.singleEvent ? fluent.$t('single-event-create') : fluent.$t('event-create'),
);

const convertButtonLabel = computed(() =>
    series.value.singleEvent ? fluent.$t('allow-multiple-events') : fluent.$t('limit-to-single-event'),
);

async function onChangeStatus(status: string) {
    await updateStatus(status as EventSeriesStatus);
}
</script>
