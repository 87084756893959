<template>
    <div class="h-8 grid gap-3 items-center grid-cols-[1rem_1fr]">
        <LinkIcon v-if="props.variant === 'Link-External' || props.variant === 'Link-Internal'" class="text-gray-500" />

        <div v-if="props.variant === 'Link-External'" className="grid gap-3 items-center grid-cols-[1fr_1rem]">
            <div class="flex flex-row items-center gap-1">
                <a
                    class="leading-tight text-sm font-semibold border-b border-b-sky-800 hover:border-b-sky-900 active:border-b-sky-950 text-sky-800 hover:text-sky-900 active:text-sky-950 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-sky-700"
                    :href="props.linkTarget"
                    target="_blank"
                >
                    {{ props.label }}
                </a>

                <HelpButton v-if="props.helpButtonText" class="text-gray-500" :text="props.helpButtonText" />
            </div>
            <Tooltip>
                <template #trigger="tooltipProps">
                    <ExternalLinkIcon
                        v-if="props.variant === 'Link-External'"
                        class="text-gray-500"
                        aria-hidden="true"
                        v-bind="tooltipProps"
                    />
                </template>
                <template #text>{{ $t('external-link') }}</template>
            </Tooltip>
        </div>

        <div v-if="props.variant === 'Link-Internal'" class="flex flex-row items-center gap-1">
            <RouterLink
                class="leading-tight text-sm line-clamp-1 font-semibold border-b border-b-sky-800 hover:border-b-sky-900 active:border-b-sky-950 text-sky-800 hover:text-sky-900 active:text-sky-950 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-sky-700"
                :title="props.label"
                :to="props.linkTarget"
                target="_blank"
            >
                {{ props.label }}
            </RouterLink>

            <HelpButton v-if="props.helpButtonText" class="text-gray-500" :text="props.helpButtonText" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { RouterLink } from 'vue-router';
import HelpButton from '../HelpButton/HelpButton.vue';
import ExternalLinkIcon from '../Icon/ExternalLinkIcon.vue';
import LinkIcon from '../Icon/LinkIcon.vue';
import Tooltip from '../Tooltip/Tooltip.vue';

type LinkAttachmentProps = {
    variant: 'Link-External' | 'Link-Internal';
    label: string;
    linkTarget: string;
    helpButtonText?: string;
};

const props = defineProps<LinkAttachmentProps>();
</script>
