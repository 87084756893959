import { FeedEntryDataFragment } from '@/generated/graphql';

export type UnsavedChanges = 'None' | 'New' | 'Edited' | 'Deleted' | 'EditedAndDeleted' | 'NewAndDeleted';

export type EditableFeedEntry = FeedEntryDataFragment & {
    hasUnsavedChanges: UnsavedChanges;
    isEditable: boolean;
    oldText?: string;
};

export const DELETED_STATES = ['Deleted', 'NewAndDeleted', 'EditedAndDeleted'];

export type FeedEntryType = 'user-text' | 'new-main-variant' | 'new-status' | 'created-at';
