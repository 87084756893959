<template>
    <div class="py-1.5 h-8 flex items-center justify-center" :class="[conflictHighlightClasses, columnWidthClass]">
        <Tooltip v-if="props.conflictStatus !== 'no-conflict'">
            <template #trigger="tooltipProps">
                <div ref="elementRef" class="flex items-center">
                    <FlashIcon
                        v-if="props.conflictStatus === 'unresolved-conflict'"
                        class="text-red-600"
                        v-bind="tooltipProps"
                    />

                    <FlashOffIcon
                        v-else-if="props.conflictStatus === 'resolved-conflict'"
                        class="text-gray-500"
                        v-bind="tooltipProps"
                    />
                </div>
            </template>

            <template #text>
                <i18n v-if="props.conflictStatus === 'unresolved-conflict'" path="unresolved-conflicts-day" />
                <i18n v-else-if="props.conflictStatus === 'resolved-conflict'" path="resolved-conflicts-day" />
            </template>

            <template #header>{{ props.formattedDate }}</template>
        </Tooltip>
    </div>
</template>

<script setup lang="ts">
import { computed, inject, nextTick, ref, watch } from 'vue';
import FlashIcon from '../../Icon/FlashIcon.vue';
import FlashOffIcon from '../../Icon/FlashOffIcon.vue';
import Tooltip from '../../Tooltip/Tooltip.vue';
import { useGlobalTooltipPosition } from '../../Tooltip/globalTooltipPosition';
import { ConflictStatus } from '../Timeline.types';
import { triggerTooltipPositioningKey } from '../injectionKeys';
import { columnWidthClass } from '../sharedStyles';

type HeaderConflictDayProps = {
    conflictStatus: ConflictStatus;
    formattedDate: string;
};

const props = defineProps<HeaderConflictDayProps>();
const elementRef = ref(null);

const conflictHighlightClasses = computed(() => {
    if (props.conflictStatus === 'unresolved-conflict') {
        return 'bg-red-100 header-conflict-day-bg-red-100';
    } else if (props.conflictStatus === 'resolved-conflict') {
        return 'bg-gray-100 header-conflict-day-bg-gray-100';
    }

    return 'header-conflict-day-bg-default';
});

const { forceUpdate } = useGlobalTooltipPosition(elementRef);

const triggerCounter = inject(triggerTooltipPositioningKey, ref(-1));
watch(triggerCounter, async () => {
    await nextTick();
    forceUpdate();
});
</script>

<style scoped>
.header-conflict-day-bg-red-100,
.header-conflict-day-bg-gray-100 {
    position: relative;
}

.header-conflict-day-bg-red-100::after,
.header-conflict-day-bg-gray-100::after {
    content: '';
    border-bottom: 0.125rem solid;
    height: 2.5rem;
    width: 100%;
    bottom: -2.375rem;
    left: 0;
    position: absolute;
}

.header-conflict-day-bg-red-100::after {
    background-color: var(--color-red-100);
    border-bottom-color: var(--color-red-600);
}

.header-conflict-day-bg-gray-100::after {
    background-color: var(--color-gray-100);
    border-bottom-color: var(--color-gray-600);
}

.header-conflict-day-bg-default {
    background-color: var(--color-gray-50);
}
</style>
