<template>
    <div ref="timelineHeaderRef">
        <div
            class="grid gap-x-px timeline-header-border-top-single-gray-200 timeline-header-border-bottom-double-gray-300 border-r w-max bg-gray-50"
            :style="gridTemplateColumns"
        >
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { columnWidthInRem } from '../sharedStyles';

type TimelineHeaderProps = {
    daysCount: number;
};

const props = defineProps<TimelineHeaderProps>();

const gridTemplateColumns = computed(() => `grid-template-columns: repeat(${props.daysCount}, ${columnWidthInRem}rem)`);

const timelineHeaderRef = ref<HTMLDivElement | null>(null);

defineExpose({
    ref: timelineHeaderRef,
});
</script>

<style scoped>
.timeline-header-border-top-single-gray-200 {
    position: relative;
}
.timeline-header-border-top-single-gray-200::before {
    content: '';
    position: absolute;
    top: 4.375rem;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-gray-200);
    z-index: 10;
}

.timeline-header-border-bottom-double-gray-300 {
    position: relative;
}
.timeline-header-border-bottom-double-gray-300::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--color-gray-300);
    z-index: 10;
}
</style>
