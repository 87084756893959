<template>
    <Tooltip :is-disabled="!props.disabledExplanation && !props.iconDescription">
        <template #trigger="tooltipProps">
            <button
                :id="buttonId"
                ref="buttonRef"
                :aria-labelledby="
                    tooltipProps['aria-labelledby'] ? `${tooltipProps['aria-labelledby']} ${buttonId}` : undefined
                "
                :popovertarget="tooltipProps['popovertarget'] ? tooltipProps.popovertarget : undefined"
                :aria-disabled="props.disabledExplanation ? true : undefined"
                :type="props.type ?? 'button'"
                class="rounded-sm flex flex-row items-center focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 w-max gap-1"
                :class="[variantClasses, focusClasses, sizeClasses, { group: slots.icon }]"
                @click="handleClick"
            >
                <div v-if="slots.icon" class="flex items-center" :class="[iconVariantClasses]">
                    <slot name="icon" />
                </div>
                <slot />
            </button>
        </template>

        <template #text>
            <div class="w-max">{{ props.disabledExplanation || props.iconDescription }}</div>
        </template>
    </Tooltip>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid';
import { computed, ref, useSlots } from 'vue';
import Tooltip from '../Tooltip/Tooltip.vue';
import { getFocusClasses, getIconVariantClasses, getSizeClasses, getVariantClasses } from './Button.styles';
import { ButtonSize, ButtonVariant } from './Button.types';

type ButtonProps = {
    size: ButtonSize;
    variant: ButtonVariant;
    disabledExplanation?: string;
    iconDescription?: string;
    type?: 'submit' | 'button';
    hasDarkBackground?: boolean;
};

type ButtonEmits = {
    (e: 'click', ev: MouseEvent): void;
};

const props = defineProps<ButtonProps>();
const emit = defineEmits<ButtonEmits>();
const slots = useSlots();
const buttonId = nanoid();

const variantClasses = computed(() => getVariantClasses(props.disabledExplanation, props.variant));
const focusClasses = computed(() => getFocusClasses(props.disabledExplanation, props.variant, props.hasDarkBackground));
const sizeClasses = computed(() => getSizeClasses(props.size, Boolean(slots.icon), Boolean(slots.default)));
const iconVariantClasses = computed(() => getIconVariantClasses(props.disabledExplanation, props.variant));

function handleClick(event: MouseEvent) {
    if (props.disabledExplanation) {
        event.preventDefault();
    } else {
        emit('click', event);
    }
}

const buttonRef = ref();
function focus() {
    buttonRef.value.focus();
}

defineExpose({
    focus,
});
</script>
