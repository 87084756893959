import { ComboboxItem } from '@/components/Form/ComboboxField.vue';
import {
    GetExhibitionSpacesAreasDocument,
    GetExhibitionSpacesRoomsDocument,
    GetTypesDocument,
} from '@/generated/graphql';
import { useApolloClient, useQuery } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed, ref, Ref, toValue, watchEffect } from 'vue';
import { SpaceItem } from '../types';

export function useSpaces(spacesType: Ref<string>, spacesTypeName: Ref<string>) {
    const client = useApolloClient().client;
    const roomTypeId = ref<string>('');
    const areaTypeId = ref<string>('');
    const spaces = ref<SpaceItem[]>([]);
    const fluent = useFluent();

    const getTypes = useQuery(GetTypesDocument);

    type SpacesItem = {
        __typename?: string;
        id: string;
        name: string;
    };

    function composeItemName(i: SpacesItem) {
        if (i.__typename) {
            return `${i.name} (${fluent.$t(i.__typename.toLowerCase())})`;
        }
        return i.name;
    }

    const types = computed(() => {
        const value = getTypes.result.value;
        if (value) {
            const items = [...(value.roomTypes?.nodes ?? []), ...(value.areaTypes?.nodes ?? [])].sort((a, b) =>
                a.name.localeCompare(b.name),
            );
            return items.map((i) => ({
                id: `${i.__typename}:${i.id}`,
                name: composeItemName(i),
            }));
        }
        return [];
    });

    const selectedType = computed(() => {
        if (spacesType.value) {
            return (
                types.value.find((r) => {
                    const [typename, _id] = r.id.split(':');
                    // check also for typename in case of duplicate type names in the future
                    return r.name === spacesType.value && typename === spacesTypeName.value;
                }) ?? null
            );
        }
        return null;
    });

    async function getSpaces() {
        const type = types.value.find((t) => t.name === toValue(spacesType));
        const typeName = toValue(spacesTypeName);
        if (type && typeName) {
            const typeId = type.id.split(':')[1];
            if (typeName === 'RoomType') {
                const response = await client.query({
                    query: GetExhibitionSpacesRoomsDocument,
                    variables: { typeId },
                });
                if (response.errors) {
                    throw new Error(`Could not load space rooms: ${response.errors}`);
                }
                if (response.data?.rooms) {
                    spaces.value = response.data.rooms.nodes;
                }
            }
            if (typeName === 'AreaType') {
                const response = await client.query({
                    query: GetExhibitionSpacesAreasDocument,
                    variables: { typeId },
                });
                if (response.errors) {
                    throw new Error(`Could not load space areas: ${response.errors}`);
                }
                if (response.data?.areas) {
                    spaces.value = response.data.areas.nodes;
                }
            }
        }
    }

    watchEffect(() => getSpaces());

    async function onTypeChange(item: ComboboxItem | null) {
        if (item) {
            const [typeName, id] = item.id.split(':');
            spacesType.value = item.name;
            spacesTypeName.value = typeName;
            if (typeName === 'RoomType') {
                roomTypeId.value = id;
                areaTypeId.value = '';
            }
            if (typeName === 'AreaType') {
                areaTypeId.value = id;
                roomTypeId.value = '';
            }
        } else {
            spacesType.value = '';
            spacesTypeName.value = '';
            areaTypeId.value = '';
            roomTypeId.value = '';
            spaces.value = [];
        }
    }

    const emptyListInfoMessage = computed(() =>
        selectedType.value?.id === '' ? fluent.$t('please-select-type') : fluent.$t('no-spaces-for-type'),
    );

    return {
        spaces,
        types,
        selectedType,
        emptyListInfoMessage,
        onTypeChange,
    };
}
