import { cleanQuarter, cleanYear } from '@/navigation/utils';
import { DateTime, DurationLike, QuarterNumbers } from 'luxon';
import { computed, Ref, toValue } from 'vue';

export const MINIMAL_YEAR = 2000;
export const MAXIMAL_YEAR = 2099;

export function useTimelineIntervalChanger(
    quarter: Ref<string>,
    year: Ref<string>,
    timelineDataLoading: Ref<boolean>,
    allocationsCount: Ref<number>,
    addToStartDateTime: (duration: DurationLike) => DateTime,
    subtractFromStartDateTime: (duration: DurationLike) => DateTime,
) {
    function onChangeQuarter(newQuarter: QuarterNumbers) {
        const quarterDifference = newQuarter - cleanQuarter(quarter.value);
        addToStartDateTime({ quarters: quarterDifference });
    }

    function onChangeYear(newYear: number) {
        const yearDifference = newYear - cleanYear(year.value);
        addToStartDateTime({ years: yearDifference });
    }

    function onClickPreviousQuarter() {
        subtractFromStartDateTime({ quarters: 1 });
    }

    function onClickNextQuarter() {
        addToStartDateTime({ quarters: 1 });
    }

    const warningNoAllocations = computed(() => {
        if (toValue(timelineDataLoading) || toValue(allocationsCount) > 0) {
            return null;
        }

        return {
            buttonJumpToPreviousAllocations: 'Hidden' as const,
            buttonJumpToNextAllocations: 'Hidden' as const,
        };
    });

    return {
        warningNoAllocations,
        onChangeQuarter,
        onChangeYear,
        onClickPreviousQuarter,
        onClickNextQuarter,
    };
}
