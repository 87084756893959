<template>
    <div
        class="pb-0.5 text-xs font-light uppercase text-gray-500 whitespace-nowrap tracking-wider relative text-center"
        :style="gridColumn"
        :class="{
            'header-calendar-week-marker-gray-200': props.daysCount === 7 || props.isLastCalendarWeek,
        }"
    >
        <div class="overflow-x-clip">
            {{ props.label }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type HeaderCalendarWeekProps = {
    daysCount: number;
    isLastCalendarWeek: boolean;
    label: string;
    startColumnIndex: number;
};

const props = defineProps<HeaderCalendarWeekProps>();

const gridColumn = computed(() => `grid-column: ${props.startColumnIndex} / span ${props.daysCount}`);
</script>

<style scoped>
.header-calendar-week-marker-gray-200 {
    position: relative;
}

.header-calendar-week-marker-gray-200::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 1.125rem;
    left: -1px;
    background-color: var(--color-gray-200);
    bottom: 0;
}
</style>
