<!-- style based on https://tailwindui.com/components/application-ui/forms/input-groups#component-04a268fee12196d74c94e82a43106f8d -->
<template>
    <div>
        <Label
            :label="props.label"
            :input-id="inputId"
            :help-button-text="props.helpButtonText"
            :is-required="props.required"
        />
        <div class="relative mt-1">
            <input
                :id="inputId"
                :value="value"
                :type="type"
                :disabled="disabled"
                :autocomplete="autocomplete"
                :min="min"
                :max="max"
                :step="step"
                :minLength="minLength"
                :maxLength="maxLength"
                :inputmode="inputmode"
                :class="styling"
                :required="required"
                :placeholder="placeholder"
                :aria-invalid="errorMessages && errorMessages.length ? true : undefined"
                :aria-describedby="description ? `${inputId}-info` : undefined"
                :aria-errormessage="errorMessages && errorMessages.length ? `${inputId}-error` : undefined"
                @input="$emit('input', $event)"
                @change="$emit('change', $event)"
                @blur="$emit('blur', $event)"
            />
            <div
                v-if="errorMessages && errorMessages.length"
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
            >
                <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
            <div v-if="clearable" class="absolute inset-y-0 right-0 flex items-center pr-3">
                <XIcon class="h-5 w-5 text-gray-400" aria-hidden="true" @click.prevent="$emit('clear')" />
            </div>
        </div>
        <p
            v-if="description"
            :id="`${inputId}-info`"
            :class="[props.status === 'overridden' ? 'mt-2 text-sm text-indigo-600' : 'mt-2 text-sm text-gray-500']"
        >
            {{ description }}
        </p>
        <div
            v-if="errorMessages && errorMessages.length"
            :id="`${inputId}-error`"
            role="alert"
            class="mt-2 text-sm text-red-600"
        >
            <p v-for="message in errorMessages" :key="message">{{ message }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ExclamationCircleIcon, XIcon } from '@heroicons/vue/solid';
import { nanoid } from 'nanoid';
import { computed } from 'vue';
import Label from './Label.vue';

type InputFieldProps = {
    value: string | number | boolean | undefined | null;
    label: string;
    type: string;
    disabled?: boolean;
    description?: string;
    helpButtonText?: string;
    inputmode?: 'search' | 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal';
    min?: number;
    max?: number;
    step?: string;
    minLength?: number;
    maxLength?: number;
    required?: boolean;
    autocomplete?: string;
    errorMessages?: string[];
    placeholder?: string;
    clearable?: boolean;
    status?: 'overridden';
};

const props = withDefaults(defineProps<InputFieldProps>(), {
    disabled: false,
    autocomplete: 'off',
    step: undefined,
    clearable: false,
});

defineEmits<{
    (e: 'input', v: Event): void;
    (e: 'change', v: Event): void;
    (e: 'blur', v: Event): void;
    (e: 'clear'): void;
}>();

const inputId = nanoid();

const styling = computed(() => {
    return [
        'block',
        'py-[0.5625rem]',
        'w-full',
        'rounded-md',
        'shadow-xs',
        'sm:text-sm',
        'disabled:cursor-not-allowed',
        'disabled:border-gray-200',
        'disabled:bg-gray-50',
        'disabled:text-gray-500',
        props.errorMessages && props.errorMessages.length
            ? 'focus:outline-hidden border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
            : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
        {
            'focus:outline-hidden border-blue-600 text-blue-600 focus:ring-blue-600 focus:border-blue-600':
                props.status === 'overridden',
        },
    ];
});
</script>
