<template>
    <div class="bg-sky-800 text-sky-50 flex flex-col w-max justify-between min-h-screen overflow-y-auto">
        <div>
            <!-- Logo -->
            <header class="flex">
                <Tooltip position="bottom" class="grow">
                    <template #trigger="tooltipProps">
                        <RouterLink
                            class="grow text-center h-20 rounded-sm outline-hidden focus-visible:-outline-offset-8 focus-visible:outline-2 focus-visible:outline-sky-50 focus-visible:bg-sky-900 hover:bg-sky-900 hover:underline active:bg-sky-950 text-4xl font-bold poppins"
                            :class="[props.isExpanded ? 'p-5' : 'py-5 px-4', { 'text-center': !props.isExpanded }]"
                            to="/"
                            v-bind="tooltipProps"
                        >
                            {{ props.isExpanded ? 'space.cap' : 'c' }}
                        </RouterLink>
                    </template>

                    <template #header>space.cap</template>

                    <template #text>
                        <div class="w-max">{{ $t('go-to-homepage') }}</div>
                    </template>
                </Tooltip>
            </header>

            <!-- Navigation items -->
            <nav class="p-3" :aria-labelledby="mainNavigationId">
                <h2 :id="mainNavigationId" class="sr-only">{{ props.mainNavigationHeadline }}</h2>

                <ul class="flex flex-col gap-2.5" :class="{ 'items-center': !props.isExpanded }">
                    <slot />
                </ul>
            </nav>
        </div>

        <div class="flex flex-col pb-2.5" :class="{ 'items-center': !props.isExpanded }">
            <!-- User section -->
            <section
                class="pl-1.5 py-1 flex items-center justify-center border-y border-y-sky-900"
                :class="[props.isExpanded ? 'pr-3' : 'pr-1.5']"
                :aria-labelledby="userSectionId"
            >
                <h2 :id="userSectionId" class="sr-only">{{ props.userSectionHeadline }}</h2>

                <Tooltip v-if="!props.isExpanded">
                    <template #trigger="tooltipProps">
                        <RouterLink
                            class="p-1.5 rounded-sm flex flex-row grow items-center gap-3 group hover:bg-sky-900 outline-hidden focus-visible:outline-offset-0 focus-visible:outline-2 focus-visible:outline-sky-50 focus-visible:bg-sky-900 active:bg-sky-950"
                            :to="user.profileLink"
                            :aria-describedby="tooltipProps['aria-labelledby']"
                            :popovertarget="tooltipProps['popovertarget']"
                        >
                            <img
                                v-if="props.user.avatarUrl !== ''"
                                aria-hidden="true"
                                class="w-10 h-10 rounded-[1.25rem] text-gray-50 leading-10 text-center text-sm"
                                :style="props.user.avatarBackgroundColorStyles"
                                :src="props.user.avatarUrl"
                                :alt="props.user.avatarAlt"
                            />

                            <!-- Looks like Safari doesn't allow styling the alt text of images -->
                            <InitialsWithBackground v-else class="size-10 leading-10" :name="user.name" />
                        </RouterLink>
                    </template>

                    <template #text>
                        <div class="w-max">{{ $t('view-profile') }}</div>
                    </template>
                </Tooltip>

                <RouterLink
                    v-if="props.isExpanded"
                    class="p-1.5 mr-1.5 rounded-sm flex flex-row grow items-center gap-3 group hover:bg-sky-900 outline-hidden focus-visible:outline-offset-0 focus-visible:outline-2 focus-visible:outline-sky-50 focus-visible:bg-sky-900 active:bg-sky-950"
                    :to="user.profileLink"
                >
                    <img
                        v-if="props.user.avatarUrl !== ''"
                        aria-hidden="true"
                        class="w-10 h-10 rounded-[1.25rem] text-gray-50 leading-10 text-center text-sm"
                        :style="props.user.avatarBackgroundColorStyles"
                        :src="props.user.avatarUrl"
                        :alt="props.user.avatarAlt"
                    />

                    <!-- Looks like Safari doesn't allow styling the alt text of images -->
                    <InitialsWithBackground v-else class="size-10 leading-10" :name="user.name" />

                    <span class="flex flex-col">
                        <span class="text-sky-50 text-sm font-semibold">{{ props.user.name }}</span>
                        <span class="text-sky-300 text-xs font-light group-hover:underline">
                            {{ $t('view-profile') }}
                        </span>
                    </span>
                </RouterLink>

                <Tooltip v-if="props.isExpanded">
                    <template #trigger="tooltipProps">
                        <button
                            class="w-8 h-8 rounded-sm border border-sky-900 grid place-items-center hover:bg-sky-900 outline-hidden focus-visible:outline-offset-0 focus-visible:outline-2 focus-visible:outline-sky-50 focus-visible:bg-sky-900 active:bg-sky-950 active:border-sky-950"
                            type="button"
                            v-bind="tooltipProps"
                            @click="$emit('logout')"
                        >
                            <LogoutIcon class="text-gray-50" :aria-labelledby="tooltipProps['aria-labelledby']" />
                        </button>
                    </template>

                    <template #text>Abmelden</template>
                </Tooltip>
            </section>

            <!-- Deployment info -->
            <section
                v-if="props.deploymentInfo"
                class="px-3 w-full"
                :class="deploymentInfoBackgroundClass"
                :aria-labelledby="deploymentInfoId"
            >
                <h2 :id="deploymentInfoId" class="sr-only">{{ props.deploymentInfoHeadline }}</h2>

                <div
                    class="text-xxs font-light pb-0.5 whitespace-nowrap flex gap-0.5"
                    :class="[
                        deploymentInfoTextLightClass,
                        { 'text-center': !props.isExpanded },
                        props.isExpanded ? 'max-w-64' : 'max-w-[2.75rem]',
                    ]"
                >
                    <span v-if="props.isExpanded">
                        <i18n v-if="props.deploymentInfo.status === 'Success'" path="deployment-info-version">
                            <template #link>
                                <a
                                    class="underline hover:text-sky-50 outline-hidden focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-sky-50 focus-visible:text-sky-50 active:bg-sky-950"
                                    :href="props.deploymentInfo.commitUrl"
                                >
                                    #{{ props.deploymentInfo.commitShortHash }}
                                </a>
                            </template>
                        </i18n>

                        <template v-if="props.deploymentInfo.status === 'Loading'">
                            {{ $t('deployment-info-loading-long') }}
                        </template>

                        <template v-if="props.deploymentInfo.status === 'Error'">
                            {{ $t('deployment-info-loading-failed') }}
                        </template>
                    </span>

                    <template v-if="showEnvironment && props.isExpanded">|</template>

                    <Tooltip v-if="showEnvironment" class="overflow-hidden">
                        <template #trigger="tooltipProps">
                            <div
                                class="line-clamp-1 focus:outline-hidden focus-visible:outline-offset-0 focus-visible:outline-1 focus-visible:outline-sky-50"
                                tabindex="0"
                                v-bind="tooltipProps"
                            >
                                {{
                                    props.isExpanded
                                        ? props.deploymentInfo.environmentLong
                                        : props.deploymentInfo.environmentShort
                                }}
                            </div>
                        </template>

                        <template #text>{{ props.deploymentInfo.environmentLong }} </template>
                    </Tooltip>
                </div>
                <div
                    class="text-xs font-semibold"
                    :class="[deploymentInfoTextStrongClass, { 'text-center': !props.isExpanded }]"
                >
                    <template v-if="props.deploymentInfo.status === 'Success'">
                        {{
                            props.isExpanded
                                ? props.deploymentInfo.commitTimestampLong
                                : props.deploymentInfo.commitTimestampShort
                        }}
                    </template>

                    <template v-if="props.isExpanded && props.deploymentInfo.status === 'Loading'">
                        {{ props.deploymentInfo.loadingMessage }}
                    </template>

                    <Tooltip v-if="!props.isExpanded && props.deploymentInfo.status === 'Loading'">
                        <template #trigger>
                            {{
                                props.isExpanded
                                    ? $t('deployment-info-loading-long')
                                    : $t('deployment-info-loading-short')
                            }}
                        </template>

                        <template #text>{{ props.deploymentInfo.loadingMessage }} </template>
                    </Tooltip>

                    <template v-if="props.isExpanded && props.deploymentInfo.status === 'Error'">
                        {{ props.deploymentInfo.errorMessage }}
                    </template>

                    <Tooltip v-if="!props.isExpanded && props.deploymentInfo.status === 'Error'">
                        <template #trigger>
                            {{ $t('deployment-info-loading-failed') }}
                        </template>

                        <template #text>{{ props.deploymentInfo.errorMessage }} </template>
                    </Tooltip>
                </div>
            </section>

            <!-- Expand/Collapse button -->
            <Tooltip :is-disabled="props.isExpanded">
                <template #trigger="tooltipProps">
                    <button
                        class="border border-sky-900 hover:bg-sky-900 outline-hidden focus-visible:outline-offset-0 focus-visible:outline-2 focus-visible:outline-sky-50 focus-visible:bg-sky-900 active:bg-sky-950 active:border-sky-950 rounded-sm p-[0.4375rem] mx-3"
                        :class="{
                            'mt-2.5': !props.deploymentInfo,
                            'flex flex-row items-center gap-2 text-base leading-tight': props.isExpanded,
                        }"
                        type="button"
                        v-bind="tooltipProps"
                        @click="$emit('toggleIsExpanded')"
                    >
                        <DoubleArrowLeftIcon class="size-5" :class="{ 'rotate-180': !props.isExpanded }" />
                        <template v-if="props.isExpanded">{{ $t('collapse-sidebar') }}</template>
                    </button>
                </template>

                <template #text>{{ props.isExpanded ? $t('collapse-sidebar') : $t('expand-sidebar') }}</template>
            </Tooltip>
        </div>
    </div>
</template>

<script setup lang="ts">
import InitialsWithBackground from '@/user/components/InitialsWithBackground.vue';
import { nanoid } from 'nanoid';
import { computed, provide, ref, watch } from 'vue';
import DoubleArrowLeftIcon from '../Icon/DoubleArrowLeftIcon.vue';
import LogoutIcon from '../Icon/LogoutIcon.vue';
import Tooltip from '../Tooltip/Tooltip.vue';
import { triggerHideTooltipKey } from '../Tooltip/injectionKeys';

export type DeploymentInfo =
    | {
          status: 'Loading';
          loadingMessage: string;
          environmentLong: string;
          environmentShort: string;
      }
    | {
          status: 'Success';
          commitShortHash: string;
          commitTimestampLong: string;
          commitTimestampShort: string;
          commitUrl: string;
          environmentLong: string;
          environmentShort: string;
      }
    | {
          status: 'Error';
          errorMessage: string;
          environmentLong: string;
          environmentShort: string;
      };

type NavigationSidebarProps = {
    user: {
        name: string;
        avatarUrl: string;
        avatarAlt: string;
        avatarBackgroundColorStyles: string;
        profileLink: string;
    };
    deploymentInfo?: DeploymentInfo;
    showEnvironment: boolean;
    isExpanded: boolean;
    mainNavigationHeadline: string;
    userSectionHeadline: string;
    deploymentInfoHeadline: string;
};

const props = defineProps<NavigationSidebarProps>();

defineEmits<{
    (e: 'toggleIsExpanded'): void;
    (e: 'logout'): void;
}>();

const mainNavigationId = nanoid();
const userSectionId = nanoid();
const deploymentInfoId = nanoid();

const deploymentInfoBackgroundClass = computed(() => {
    switch (props.deploymentInfo?.status) {
        case 'Loading':
            return 'py-2.5';

        case 'Success':
            return 'py-2.5';

        case 'Error':
            return 'py-1.5 my-1 bg-red-700';
    }
});

const deploymentInfoTextStrongClass = computed(() => {
    switch (props.deploymentInfo?.status) {
        case 'Loading':
            return 'text-sky-50';

        case 'Success':
            return 'text-sky-50';

        case 'Error':
            return 'text-red-50';
    }
});

const deploymentInfoTextLightClass = computed(() => {
    switch (props.deploymentInfo?.status) {
        case 'Loading':
            return 'text-sky-300';

        case 'Success':
            return 'text-sky-300';

        case 'Error':
            return 'text-red-300';
    }
});

// Avoid the issue that the tooltip of the "Expand" button is still visible after its trigger is unmounted
watch(
    () => props.isExpanded,
    (isExpanded) => {
        if (isExpanded) {
            triggerHideTooltip();
        }
    },
);

// This is used to force close the Tooltip after expanding/collapsing the navigation sidebar
const triggerHideTooltipKeyCounter = ref(0);
provide(triggerHideTooltipKey, triggerHideTooltipKeyCounter);

function triggerHideTooltip() {
    triggerHideTooltipKeyCounter.value = triggerHideTooltipKeyCounter.value + 1;
}
</script>
