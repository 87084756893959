<template>
    <div
        class="py-2 px-4 items-start grid grid-cols-[10rem_1fr] gap-4"
        :class="{ 'border-b border-b-gray-200': props.hasBorderBottom }"
        :style="gridTemplateColumnsStyle"
    >
        <div class="text-gray-500 flex flex-col">
            <strong class="text-sm font-medium flex flex-row items-center gap-1">
                {{ props.label }}
                <HelpButton v-if="props.helpText" :text="props.helpText" />
            </strong>
            <span class="text-xs font-light">{{ props.labelHint }}</span>
        </div>

        <div
            class="h-10 w-10 shadow-sm overflow-hidden"
            :class="{ 'border-2 border-red-700 bg-red-200  flex justify-center': hasLoadingImageFailed }"
        >
            <HelpButton v-if="hasLoadingImageFailed" status="Error" :text="$t('loading-logo-failed')" />

            <img
                v-if="props.imageUrl && !hasLoadingImageFailed"
                :src="props.imageUrl"
                :alt="props.imageAlt"
                @error="onImageError"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useImageError } from '@/utils/useImageError';
import { computed } from 'vue';
import HelpButton from '../HelpButton/HelpButton.vue';

type CardRowImageProps = {
    label: string;
    labelHint: string;
    labelWidthInRem?: number;
    helpText?: string;
    imageUrl: string;
    imageAlt: string;
    hasBorderBottom: boolean;
};

const props = defineProps<CardRowImageProps>();

const gridTemplateColumnsStyle = computed(() => {
    return `grid-template-columns: ${props.labelWidthInRem ?? 15}rem 1fr;`;
});

const { hasLoadingImageFailed, onImageError } = useImageError();
</script>
