import { CreateScenarioGroupDocument } from '@/generated/graphql';
import { useMyUser } from '@/user/composables/useMyUser';
import { useMutation } from '@vue/apollo-composable';
import { toValue } from 'vue';

export function useCreateScenarioGroup() {
    const createScenarioGroupMutation = useMutation(CreateScenarioGroupDocument);
    const { currentUser } = useMyUser();

    async function createScenarioGroup(nameShort: string, nameLong: string) {
        const response = await createScenarioGroupMutation.mutate({
            nameShort,
            nameLong,
            createdById: toValue(currentUser).id,
            createdByName: toValue(currentUser).name,
        });
        if (response?.errors) {
            throw new Error(`Could not create ScenarioGroup: ${response.errors}`);
        }
        if (!response?.data?.createScenarioGroup?.scenarioGroup) {
            throw new Error('Create ScenarioGroup did not return data.');
        }
        return response.data.createScenarioGroup.scenarioGroup;
    }

    return {
        createScenarioGroup,
    };
}
