<template>
    <div>
        <SwitchGroup as="div" class="flex items-center">
            <Switch
                :id="inputId"
                :model-value="value"
                :disabled="disabled"
                :class="[
                    value && !disabled ? 'bg-sky-800' : 'bg-gray-200',
                    'disabled:cursor-not-allowed',
                    'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-hidden focus:ring-2 focus:ring-sky-800 focus:ring-offset-2',
                ]"
                @update:model-value="$emit('change', $event)"
            >
                <span
                    aria-hidden="true"
                    :class="[
                        value ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-sm ring-0 transition duration-200 ease-in-out',
                    ]"
                />
            </Switch>
            <SwitchLabel as="span" class="ml-3 text-sm">
                <Label
                    :label="props.label"
                    :input-id="inputId"
                    :help-button-text="props.helpButtonText"
                    :is-required="props.required"
                />
            </SwitchLabel>
        </SwitchGroup>
        <div
            v-if="errorMessages && errorMessages.length"
            :id="`${inputId}-error`"
            role="alert"
            class="mt-2 text-sm text-red-600"
        >
            <p v-for="message in errorMessages" :key="message">{{ message }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import { nanoid } from 'nanoid';
import Label from './Label.vue';

const inputId = nanoid();

const props = defineProps<{
    value: boolean;
    label: string;
    helpButtonText?: string;
    required?: boolean;
    errorMessages?: string[];
    disabled?: boolean;
}>();

defineEmits<{ (e: 'change', v: boolean): void }>();
</script>
