import {
    MAXIMAL_YEAR,
    MINIMAL_YEAR,
} from '@/components/TimelineIntervalChanger/composables/useTimelineIntervalChanger';
import { DateTime, DurationLike } from 'luxon';
import { computed, Ref, ref, toValue } from 'vue';

export function sortByDateAsc(a: string, b: string) {
    const startA = DateTime.fromISO(a);
    const startB = DateTime.fromISO(b);
    return startA < startB ? -1 : startA > startB ? 1 : 0;
}

export function useTimelineRange(quarter: Ref<string>, year: Ref<string>) {
    // DateTime selected in the TimelineIntervalChanger
    // Timeline allows to expand the displayed time range by quarters
    const monthsBefore = ref(0); // Quarters before selected start DateTime
    const monthsAfter = ref(0); // Quarters after selected end DateTime

    const selectedStartDateTime = ref(DateTime.now());

    function onExpandToPreviousMonth() {
        monthsBefore.value = toValue(monthsBefore) + 1;
    }

    function onExpandToNextMonth() {
        monthsAfter.value = toValue(monthsAfter) + 1;
    }

    // DateTimes the Timeline actually shows
    const displayedStartDateTime = computed(() => {
        return selectedStartDateTime.value.startOf('quarter').startOf('day').minus({ months: monthsBefore.value });
    });
    const displayedEndDateTime = computed(() => {
        return selectedStartDateTime.value
            .endOf('quarter')
            .startOf('day')
            .plus({ months: monthsAfter.value })
            .plus({ day: 1 }); // Uses half-open interval (see README.md for details)
    });
    const isTimeSpanModified = computed(() => {
        return monthsBefore.value !== 0 || monthsAfter.value !== 0;
    });

    const previousMonthButton = computed(() => {
        const isDisabled =
            displayedStartDateTime.value.year === MINIMAL_YEAR && displayedStartDateTime.value.month === 1;

        return isDisabled ? 'Disabled' : 'Visible';
    });
    const nextMonthButton = computed(() => {
        const isDisabled =
            displayedStartDateTime.value.year === MAXIMAL_YEAR && displayedStartDateTime.value.month === 12;

        return isDisabled ? 'Disabled' : 'Visible';
    });

    function addToStartDateTime(duration: DurationLike) {
        const newDateTime = selectedStartDateTime.value.plus(duration);
        selectedStartDateTime.value = newDateTime;
        monthsBefore.value = 0;
        monthsAfter.value = 0;
        quarter.value = newDateTime.quarter.toString();
        year.value = newDateTime.year.toString();
        return newDateTime;
    }

    function subtractFromStartDateTime(duration: DurationLike) {
        const newDateTime = selectedStartDateTime.value.minus(duration);
        selectedStartDateTime.value = newDateTime;
        monthsBefore.value = 0;
        monthsAfter.value = 0;
        quarter.value = newDateTime.quarter.toString();
        year.value = newDateTime.year.toString();
        return newDateTime;
    }

    function setSelectedStartDateTime(value: DateTime) {
        selectedStartDateTime.value = value;
    }

    return {
        selectedStartDateTime,
        startDateTime: displayedStartDateTime,
        endDateTime: displayedEndDateTime,
        isTimeSpanModified: isTimeSpanModified,
        previousMonthButton,
        nextMonthButton,
        onExpandToPreviousMonth,
        onExpandToNextMonth,
        addToStartDateTime,
        subtractFromStartDateTime,
        setSelectedStartDateTime,
    };
}
