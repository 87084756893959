<template>
    <div v-if="event" class="-mx-4 p-4 bg-gray-50">
        <div class="flex flex-row justify-between items-end">
            <div v-if="!timelineLoading" class="flex flex-row items-end gap-4">
                <TimelineIntervalChanger
                    :current-quarter="selectedStartDateTime.quarter"
                    :current-year="selectedStartDateTime.year"
                    :minimal-year="MINIMAL_YEAR"
                    :maximal-year="MAXIMAL_YEAR"
                    :warning-no-allocations="warningNoAllocations"
                    :is-time-span-modified="isTimeSpanModified"
                    @change-quarter="onChangeQuarter"
                    @change-year="onChangeYear"
                    @click-previous-quarter="onClickPreviousQuarter"
                    @click-next-quarter="onClickNextQuarter"
                />

                <OptionSwitch
                    name="timeline-locations-display"
                    :active-index="activeIndex"
                    :label="$t('timeline-locations-display-label')"
                    @change-option="(index) => setActiveIndex(index)"
                >
                    <OptionSwitchEntry
                        v-for="entry of timelineOptionSwitchEntries"
                        :key="entry.id"
                        :icon="entry.icon"
                        :index="entry.index"
                        :label="entry.label"
                    />
                </OptionSwitch>
            </div>

            <VariantSelectionDropdown
                v-if="!timelineLoading && mappedCurrentVariant"
                :drop-down-status="dropDownStatus"
                :variants-ref="variantsRef"
                :visible-variants="visibleVariants"
                :default-active-variants="defaultActiveVariants"
                :selection-panel-variants="selectionPanelVariants"
                :highlighted-variant="highlightedVariant"
                :highlighted-variant-id="highlightedVariantId"
                :mapped-current-variant="mappedCurrentVariant"
                :allocation-type="allocationType"
                :object-id="eventId"
                :set-variants-ref="setVariantsRef"
                :on-change-visibility="updateVisibility"
                :set-highlighted-variant-id="setHighlightedVariantIdById"
                :on-delete-filter="onDeleteFilter"
            />
        </div>

        <Message v-if="timelineLoading" status="info">
            {{ $t('loading-timeline-data') }}
        </Message>

        <Message v-if="!timelineLoading && timelineError" status="error" :headline="$t('error-fetching-timeline-data')">
            {{ timelineError }}
        </Message>

        <Timeline
            v-if="!timelineLoading && editableVariant && site"
            class="mt-4"
            :site-name="site.nameShort"
            :site-link-target="routeForLocation('Site', site.id)"
            :start-date-time="startDateTime"
            :end-date-time="endDateTime"
            :previous-month-button="previousMonthButton"
            :next-month-button="nextMonthButton"
            :locations="locations"
            :allocations="filteredAllocations"
            :external-events="externalEvents"
            :sections="showSections ? sections : undefined"
            :allocation-variant-id="editableVariant.id"
            :allocation-variant-label="editableVariantLabel"
            :has-create-buttons="Boolean(highlightedVariant)"
            @toggle-section-expanded="toggleSectionExpanded"
            @create-allocation="onCreateAllocation"
            @delete-allocation="onDeleteAllocation"
            @update-phases="onUpdatePhases"
            @expand-to-previous-month="onExpandToPreviousMonth"
            @expand-to-next-month="onExpandToNextMonth"
        >
            <template #site-icon>
                <TerrainIcon class="size-5" :title="$t('site-icon-alt')" />
            </template>
        </Timeline>
    </div>
</template>

<script setup lang="ts">
import { useCreateAllocation } from '@/allocation/composables/useCreateAllocation';
import { useDeleteAllocation } from '@/allocation/composables/useDeleteAllocation';
import { useUpdateAllocations } from '@/allocation/composables/useUpdateAllocations';
import { useVariantSelectionPanel } from '@/components/AllocationVariantSelectionPanel/composables/useVariantSelectionPanel';
import { DEFAULT_HIGHLIGHT_VALUE } from '@/components/AllocationVariantSelectionPanel/types';
import GroupedEntriesIcon from '@/components/Icon/GroupedEntriesIcon.vue';
import NestedEntriesIcon from '@/components/Icon/NestedEntriesIcon.vue';
import TerrainIcon from '@/components/Icon/TerrainIcon.vue';
import Message from '@/components/Message/Message.vue';
import OptionSwitch from '@/components/OptionSwitch/OptionSwitch.vue';
import OptionSwitchEntry from '@/components/OptionSwitch/OptionSwitchEntry.vue';
import { useOptionSwitch } from '@/components/OptionSwitch/composables/useOptionSwitch';
import { OptionSwitchEntryType } from '@/components/OptionSwitch/types';
import { ExternalEvent } from '@/components/Timeline/Timeline.types';
import Timeline from '@/components/Timeline/Timeline.vue';
import { useSections } from '@/components/Timeline/composables/useSections';
import { useTimelineLocations } from '@/components/Timeline/composables/useTimelineLocations';
import { useTimelineRange } from '@/components/Timeline/composables/useTimelineRange';
import TimelineIntervalChanger from '@/components/TimelineIntervalChanger/TimelineIntervalChanger.vue';
import {
    MAXIMAL_YEAR,
    MINIMAL_YEAR,
    useTimelineIntervalChanger,
} from '@/components/TimelineIntervalChanger/composables/useTimelineIntervalChanger';
import { useEventAllocations } from '@/event/composables/useEventAllocations';
import { useEventTimelineData } from '@/event/composables/useEventTimelineData';
import { GetEventVariantForTimelineDocument } from '@/generated/graphql';
import { routeForLocation } from '@/location/location';
import { cleanHighlight, cleanVisible, getDateTimeFromYearAndQuarter } from '@/navigation/utils';
import { $t } from '@/plugins/fluent';
import VariantSelectionDropdown from '@/variant/components/VariantSelectionDropdown.vue';
import { useHeadSafe } from '@unhead/vue';
import { useRouteQuery } from '@vueuse/router';
import { DateTime } from 'luxon';
import { computed, markRaw } from 'vue';

const props = defineProps<{ eventId: string }>();
const eventId = computed(() => props.eventId);
const allocationType = 'Event';
const eventLabel = computed(() => event.value?.nameShort ?? '');
const DEFAULT_OPTION_SWITCH_ID = 'sections';

const timelineOptionSwitchEntries: OptionSwitchEntryType[] = [
    {
        index: 0,
        id: DEFAULT_OPTION_SWITCH_ID,
        label: $t('timeline-locations-display-sections-label'),
        icon: markRaw(GroupedEntriesIcon),
    },
    {
        index: 1,
        id: 'hierarchy',
        label: $t('timeline-locations-display-hierarchy-label'),
        icon: markRaw(NestedEntriesIcon),
    },
];

const {
    variantsRef,
    setVariantsRef,
    loading: timelineLoading,
    error: timelineError,
    onResult: onTimelineResult,
    site,
    event,
    rooms,
    areas,
    usages,
} = useEventTimelineData(eventId);

const executionYear = computed(() => event.value?.executionYear);
const executionDateTime = computed(() =>
    executionYear.value ? DateTime.fromObject({ year: executionYear.value }) : undefined,
);

const visible = useRouteQuery<string>('visible', '');
const highlight = useRouteQuery<string>('highlight', DEFAULT_HIGHLIGHT_VALUE);
const timelineDisplay = useRouteQuery<string>('timeline-display', '');

const {
    dropDownStatus,
    visibleVariants,
    defaultActiveVariants,
    editableVariant,
    editableVariantLabel,
    highlightedVariantId,
    highlightedVariant,
    mappedCurrentVariant,
    selectionPanelVariants,
    setVisibleVariantsByIds,
    setVisibleVariantsByIndexes,
    setHighlightedVariantIdById,
    setHighlightedVariantIdByIndex,
    onDeleteFilter,
    updateVisibility,
} = useVariantSelectionPanel(variantsRef, allocationType, eventId, visible, highlight);

const { filteredAllocations, allocationsStart, allocationsCount } = useEventAllocations(
    variantsRef,
    visibleVariants,
    allocationType,
    eventLabel,
    highlightedVariantId,
    eventId,
);

const quarter = useRouteQuery<string>('quarter', '');
const year = useRouteQuery<string>('year', '');

const {
    selectedStartDateTime,
    startDateTime,
    endDateTime,
    isTimeSpanModified,
    previousMonthButton,
    nextMonthButton,
    onExpandToPreviousMonth,
    onExpandToNextMonth,
    addToStartDateTime,
    subtractFromStartDateTime,
    setSelectedStartDateTime,
} = useTimelineRange(quarter, year);

const dateTimeFromRoute = computed(() => getDateTimeFromYearAndQuarter(year, quarter));

const { activeIndex, activeId, setActiveIndex, setActiveId } = useOptionSwitch(
    timelineDisplay,
    timelineOptionSwitchEntries,
);

const showSections = computed(() => activeId.value === 'sections');

onTimelineResult(() => {
    if (visible.value) {
        setVisibleVariantsByIndexes(cleanVisible(visible.value));
    } else {
        setVisibleVariantsByIds(defaultActiveVariants.value.map((v) => v.id));
    }
    if (highlight.value && highlight.value !== DEFAULT_HIGHLIGHT_VALUE) {
        setHighlightedVariantIdByIndex(cleanHighlight(highlight.value));
    }
    if (dateTimeFromRoute.value) {
        setSelectedStartDateTime(dateTimeFromRoute.value);
    } else if (allocationsStart.value || executionDateTime.value) {
        const defaultStart = allocationsStart.value ?? executionDateTime.value ?? DateTime.now();
        setSelectedStartDateTime(defaultStart);
        year.value = defaultStart.year.toString();
        quarter.value = defaultStart.quarter.toString();
    }
    const displayId = timelineDisplay.value === '' ? DEFAULT_OPTION_SWITCH_ID : timelineDisplay.value;
    setActiveId(displayId);
});

const externalEvents: ExternalEvent[] = [];

const { sections, toggleSectionExpanded } = useSections();
const { onCreateAllocation } = useCreateAllocation(
    variantsRef,
    setVariantsRef,
    editableVariant,
    usages,
    allocationType,
);
const { onDeleteAllocation } = useDeleteAllocation(variantsRef, setVariantsRef, allocationType);
const { onUpdatePhases } = useUpdateAllocations(
    variantsRef,
    setVariantsRef,
    usages,
    GetEventVariantForTimelineDocument,
);

const { warningNoAllocations, onChangeQuarter, onChangeYear, onClickPreviousQuarter, onClickNextQuarter } =
    useTimelineIntervalChanger(
        quarter,
        year,
        timelineLoading,
        allocationsCount,
        addToStartDateTime,
        subtractFromStartDateTime,
    );

const { getLocations } = useTimelineLocations(site, rooms, areas, showSections);
const locations = computed(() => getLocations().value);

useHeadSafe({
    title: () =>
        `${$t('overview')} - ${event.value?.nameShort ?? ''} - ${event.value?.series?.nameShort ?? ''} - ${$t(
            'event-wiki',
        )}`,
});
</script>
