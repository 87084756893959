<template>
    <div v-if="!loading && variantsRef.length > 0" class="space-y-4">
        <VariantCard
            v-for="variant in variantsRef"
            ref="variantCardCompositionRef"
            :key="variant.id"
            :active-card="activeCard"
            :expanded-cards="expandedCards"
            :object-id="projectId"
            :variant="variant"
            :variants-ref="variantsRef"
            :variant-type="variantType"
            :set-variants-ref="setVariantsRef"
            :set-active-card="setActiveCard"
            :set-expanded-cards="setExpandedCards"
            @set-current-variant="(variantId) => onSetCurrentVariant(variantId)"
        ></VariantCard>
    </div>
</template>
<script setup lang="ts">
import { GetProjectVariantsDocument, SetCurrentProjectVariantDocument } from '@/generated/graphql';
import VariantCard, { VariantCardExpose } from '@/variant/components/VariantCard.vue';
import { useVariants } from '@/variant/composables/useVariants';
import { computed, Ref, ref } from 'vue';

const props = defineProps<{ projectId: string }>();
const projectId = computed(() => props.projectId);
const variantType = 'Project';

const variantCardCompositionRef: Ref<VariantCardExpose[]> = ref([]);

const {
    variantsRef,
    activeCard,
    expandedCards,
    loading,
    setVariantsRef,
    setActiveCard,
    setExpandedCards,
    setCurrentVariant,
} = useVariants(projectId, variantCardCompositionRef, GetProjectVariantsDocument, SetCurrentProjectVariantDocument);

async function onSetCurrentVariant(variantId: string) {
    setCurrentVariant(variantId);
}
</script>
