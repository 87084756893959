<template>
    <div
        class="flex flex-row items-stretch px-3 bg-gray-50"
        :class="[
            props.hasHighlight ? 'pl-2' : 'pl-3',
            {
                'bg-linear-to-r from-yellow-100 to-transparent to-30% border-l-4 border-l-yellow-300':
                    props.hasHighlight === 'Yellow',
            },
        ]"
    >
        <Tooltip>
            <template #trigger="tooltipProps">
                <label class="px-6 my-1 rounded-lg hover:bg-sky-100 active:bg-sky-200 cursor-pointer flex items-center">
                    <input
                        :id="props.id"
                        :aria-describedby="tooltipProps['aria-labelledby']"
                        :popovertarget="tooltipProps.popovertarget"
                        :name="props.name"
                        type="radio"
                        :checked="isActive"
                        tabindex="0"
                        class="h-4 w-4 border-gray-300 text-sky-700 focus:ring-sky-700 cursor-pointer active:border-sky-700"
                        @input="() => onChange(props.id)"
                    />

                    <span class="sr-only">
                        {{ props.invisibleLabel }}
                    </span>
                </label>
            </template>

            <template #text>{{ props.inputElementTooltipText }}</template>
        </Tooltip>

        <div class="ml-2 w-full">
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import Tooltip from '../Tooltip/Tooltip.vue';
import { radioButtonGroupKey } from './RadioButtonGroup.injectionKeys';

type RadioButtonEntryProps = {
    id: string;
    name: string;
    invisibleLabel: string;
    inputElementTooltipText: string;
    hasHighlight?: 'Yellow';
};

const props = defineProps<RadioButtonEntryProps>();

const injectedValues = inject(radioButtonGroupKey);
if (!injectedValues) {
    throw new Error('RadioButtonGroupEntry must be used within a RadioButtonGroup component');
}

const { activeId, onChange } = injectedValues;
const isActive = computed(() => activeId.value === props.id);
</script>
